import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { Button, Modal, Icon } from 'semantic-ui-react'
import { PropTypes } from 'prop-types';
import { WaterlinkedLogoControl } from './MapControls'
import Control from 'react-leaflet-control'


const SetMasterPositionControl = inject("masterPositionStore")(observer(class SetMasterPositionControl extends Component {
    handleClick = (e) => {
        // Leaflet gives lat and lng outside +-180
        // Need to adjust
        let lat = this.props.masterPositionStore.lat % 180
        let lng = this.props.masterPositionStore.lng % 90
        this.props.onPositionChange({lat: lat, lng: lng})
    }
    render() {
        const position = this.props.position || "bottomleft"
        const  { lat, lng } = this.props.masterPositionStore
        if (lat === 0 && lng === 0) {
            return null
        }
        return (
            <Control position={position}>
            <div
                style={{
                    padding: '5px',
                    background: '#fff',
                    borderBottomLeftRadius: "2px",
                    borderBottomRightRadius: "2px",
                    border: "2px solid rgba(0,0,0,0.2)",
                }}
            >
                <Button type="button" icon compact>
                    <Icon name="location arrow" title="Load current position from topside housing" onClick={this.handleClick}/>
                </Button>
            </div>
            </Control>
        )
    }
}))

SetMasterPositionControl.propTypes = {
    position: PropTypes.string,
}

const MapPositionSelector = inject("masterPositionStore")(observer(class MapPositionSelector extends Component {
    state = {
        latlng: {
            lat: this.props.lat,
            lng: this.props.lng,
        },
        zoom: 13,
    }

    handleClick = (e) => {
        this.handlePositionChange(e.latlng)
    }

    handlePositionChange = (latlng) => {
        console.log("Set position to", latlng)
        this.setState({
            latlng: latlng,
            zoom: this.refs.map.leafletElement.locate().getZoom(),
        })
        console.log("Onstore")
        this.props.onStorePosition(latlng.lat, latlng.lng)
    }

    render() {
        return (
        <Modal trigger={<Button type='button' icon='map'/>} closeIcon closeOnRootNodeClick={true}>
            <Modal.Header>Click to set static position</Modal.Header>
            <Modal.Content>
            <Modal.Description>
            <Map
                center={this.state.latlng}
                length={4}
                onClick={this.handleClick}
                //onLocationfound={this.handleLocationFound}
                ref="map"
                zoom={this.state.zoom}>
            <TileLayer
                attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <WaterlinkedLogoControl position="topright"/>
            <SetMasterPositionControl position="bottomleft" onPositionChange={this.handlePositionChange} />
            <Marker position={this.state.latlng}>
                <Popup>
                <span>Position to use</span>
                </Popup>
            </Marker>
            </Map>
            </Modal.Description>
            </Modal.Content>
        </Modal>
        )
    }
}))

MapPositionSelector.propTypes = {
    lat: PropTypes.number,
    lng: PropTypes.number,
}

export { MapPositionSelector, SetMasterPositionControl }