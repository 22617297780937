import { extendObservable, computed, observable, action } from "mobx"
import { IsDefinedFinite, GetArrayIndexOrFallback, GetChannelName, NoValue } from '../lib/format'
import { GetIcon, GetColor } from '../lib/markerIcon'


class PositionList {
    constructor() {
        extendObservable(this, {
            positions: observable.array(),
            add: action((data) => {
                this.positions.push({data: data, ts: Date.now()})
                if (this.positions.length > 4*60*5) { // 5 min trail length (4 times a second)
                    this.positions.shift()
                }
            }),
            all: computed(() => this.positions.peek().map(d => d.data)),
            getLatest: (trailLength) => {
                const startts = Date.now() - trailLength*1000
                const values = this.positions.peek()
                return values.filter(d => d.ts > startts)
            },
            getXY: (trailLength) => {
                const latest = this.getLatest(trailLength)
                return latest.map(d => [d.data.x, d.data.y])
            },
            getLatLng: (trailLength) => {
                const latest = this.getLatest(trailLength)
                return latest.map(d => [d.data.lat, d.data.lng])
            },
        })
    }
}

class MasterPositionStore {
    constructor() {
        extendObservable(this, {
            isLoading: false,
            loadingError: "",
            lat: 0,
            lng: 0,
            cog: -1,
            hdop: -1,
            numsats: -1,
            sog: -1,
            orientation: 0,
            latlng: computed(() => [this.lat, this.lng]),
            positions: new PositionList(),
            setFromWs: action((data) => {
                // Set data but guard against missing values
                // WARNING: data.lon rewritten to this.lng
                if (IsDefinedFinite(data, "lat")) { this.lat = data.lat }
                if (IsDefinedFinite(data, "lon")) { this.lng = data.lon }
                if (IsDefinedFinite(data, "cog")) { this.cog = data.cog }
                if (IsDefinedFinite(data, "hdop")) { this.hdop = data.hdop }
                if (IsDefinedFinite(data, "numsats")) { this.numsats = data.numsats }
                if (IsDefinedFinite(data, "sog")) { this.sog = data.sog }
                if (IsDefinedFinite(data, "orientation")) { this.orientation = data.orientation }

                const validPos = IsDefinedFinite(data, "lat") && IsDefinedFinite(data, "lon")
                if (validPos) {
                    this.positions.add({lat: this.lat, lng: this.lng})
                }
            }),
            setNoValue: action(() => {
                this.lat = NoValue
                this.lng = NoValue
                this.cog = NoValue
                this.hdop = NoValue
                this.numsats = NoValue
                this.sog = NoValue
                this.orientation = NoValue
            }),
        })
    }
}

class GlobalPositionStore {
    constructor() {
        extendObservable(this, {
            isLoading: false,
            loadingError: "",
            lat: 0,
            lng: 0,
            cog: -1,
            hdop: -1,
            numsats: -1,
            sog: -1,
            orientation: 0,
            position_valid: false,
            position_invalid_count: 0,
            update: 0,
            positions_dict: {},
            getShow: (key) => {
                return this.positions_dict[key].show
            },
            setShow: action((key, value) => {
                this.update += 1
                this.positions_dict[key].show = value
            }),
            setFromWsDict: action((data, channel) => {
                this.update += 1
                var chName = GetChannelName(channel)
                if  (!(chName in this.positions_dict)) {
                    this.positions_dict[chName] = {
                        lat: 0,
                        lng: 0,
                        cog: -1,
                        hdop: -1,
                        numsats: -1,
                        sog: -1,
                        orientation: 0,
                        positions: new PositionList(),
                        name: chName,
                        color: GetColor(chName),
                        icon: GetIcon(chName),
                        show: true,
                    }
                }

                // Set data but guard against missing values
                // WARNING: data.lon rewritten to this.lng
                if (IsDefinedFinite(data, "lat")) { this.positions_dict[chName].lat = data.lat }
                if (IsDefinedFinite(data, "lon")) { this.positions_dict[chName].lng = data.lon }
                if (IsDefinedFinite(data, "cog")) { this.positions_dict[chName].cog = data.cog }
                if (IsDefinedFinite(data, "hdop")) { this.positions_dict[chName].hdop = data.hdop }
                if (IsDefinedFinite(data, "numsats")) { this.positions_dict[chName].numsats = data.numsats }
                if (IsDefinedFinite(data, "sog")) { this.positions_dict[chName].sog = data.sog }
                if (IsDefinedFinite(data, "orientation")) { this.positions_dict[chName].orientation = data.orientation }

                if (chName === "filtered"){
                    // Set data but guard against missing values
                    // WARNING: data.lon rewritten to this.lng
                    if (IsDefinedFinite(data, "lat")) { this.lat = data.lat }
                    if (IsDefinedFinite(data, "lon")) { this.lng = data.lon }
                    if (IsDefinedFinite(data, "cog")) { this.cog = data.cog }
                    if (IsDefinedFinite(data, "hdop")) { this.hdop = data.hdop }
                    if (IsDefinedFinite(data, "numsats")) { this.numsats = data.numsats }
                    if (IsDefinedFinite(data, "sog")) { this.sog = data.sog }
                    if (IsDefinedFinite(data, "orientation")) { this.orientation = data.orientation }
                }

                this.position_valid = data.position_valid
                if (this.position_valid) {
                    this.position_invalid_count = 0
                } else {
                    this.position_invalid_count += 1
                }
                const validPos = IsDefinedFinite(data, "lat") && IsDefinedFinite(data, "lon")
                if (validPos && this.position_valid) {
                    this.positions_dict[chName].positions.add({lat: this.positions_dict[chName].lat, lng: this.positions_dict[chName].lng})
                }
            }),
            setNoValue: action(() => {
                this.lat = 0
                this.lng = 0
                this.cog = NoValue
                this.hdop = NoValue
                this.numsats = NoValue
                this.sog = NoValue
                this.orientation = NoValue
                this.position_valid = false
                this.position_invalid_count = 10
            }),
        })
    }
}


class AcousticPositionStore {
    constructor() {
        extendObservable(this, {
            isLoading: false,
            loadingError: "",
            x: 0,
            y: 0,
            z: 0,
            std: NoValue,
            position_valid: false,
            position_invalid_count: 0,
            update: 0,

            positions_dict: {},

            receiver_distance: [NoValue, NoValue, NoValue, NoValue],
            receiver_rssi: [NoValue, NoValue, NoValue, NoValue],
            receiver_nsd: [NoValue, NoValue, NoValue, NoValue],
            receiver_valid: [false, false, false, false],
            getDiag: () => {
                return [0, 1, 2, 3].map(d => {
                    // Check for undefined as we are assuming 4 entries here
                    return {
                        id: d,
                        distance: GetArrayIndexOrFallback(this.receiver_distance, d, NoValue),
                        rssi: GetArrayIndexOrFallback(this.receiver_rssi, d, NoValue),
                        nsd: GetArrayIndexOrFallback(this.receiver_nsd, d, NoValue),
                        valid: GetArrayIndexOrFallback(this.receiver_valid, d, false),
                    }
                })
            },
            getShow: (key) => {
                return this.positions_dict[key].show
            },
            setShow: action((key, value) => {
                this.update += 1
                this.positions_dict[key].show = value
            }),
            setNoValue: action(() => {
                this.x = NoValue
                this.y = NoValue
                this.z = NoValue
                this.std = NoValue
                this.position_valid = false
                this.position_invalid_count = 10
                this.receiver_distance = [NoValue, NoValue, NoValue, NoValue]
                this.receiver_rssi = [NoValue, NoValue, NoValue, NoValue]
                this.receiver_nsd = [NoValue, NoValue, NoValue, NoValue]
                this.receiver_valid = [false, false, false, false]

                for (let chName in this.positions_dict) {
                    this.positions_dict[chName].position_valid = false
                    this.positions_dict[chName].position_invalid_count = 10
                }
                this.update += 1
                }),
            setFromWsDict: action((data, channel) => {
                this.update += 1
                var chName = GetChannelName(channel)
                if  (!(chName in this.positions_dict)) {
                    this.positions_dict[chName] = {
                        x: 0,
                        y: 0,
                        z: 0,
                        std: NoValue,
                        position_valid: false,
                        position_invalid_count: 0,
                        positions: new PositionList(),
                        name: chName,
                        color: GetColor(chName),
                        icon: GetIcon(chName),
                        show: true,
                    }

                }

                this.positions_dict[chName].position_valid = (data.position_valid ?  true : false)

                if (chName === "filtered") {
                    const t = data.receivers
                    for (let i=0; i<4; i++) {
                        this.receiver_distance[i] = t[i].recv_distance || 0
                        this.receiver_nsd[i] = t[i].recv_nsd_db ||0
                        this.receiver_rssi[i] = t[i].recv_rssi || 0
                        this.receiver_valid[i] = t[i].recv_valid ? true : false
                    }

                    if (IsDefinedFinite(data, "xPos")) {this.x = data.xPos}
                    if (IsDefinedFinite(data, "yPos")) {this.y = data.yPos}
                    if (IsDefinedFinite(data, "zPos")) {this.z = data.zPos}
                    if (IsDefinedFinite(data, "std")) {this.std = data.std}
                    this.position_valid = (data.position_valid ?  true : false)

                    if (data.position_valid) {
                        // Reset position invalid
                        this.position_invalid_count = 0
                    } else {
                        // Increase invalid count for visualization
                        this.position_invalid_count = this.position_invalid_count+1
                    }
                }
                if (this.positions_dict[chName].position_valid) {
                    // Only add positions to map if it is valid
                    this.positions_dict[chName].position_invalid_count = 0
                    if (IsDefinedFinite(data, "xPos")) {this.positions_dict[chName].x = data.xPos}
                    if (IsDefinedFinite(data, "yPos")) {this.positions_dict[chName].y = data.yPos}
                    if (IsDefinedFinite(data, "zPos")) {this.positions_dict[chName].z = data.zPos}
                    if (IsDefinedFinite(data, "std")) {this.positions_dict[chName].std = data.std}
                    this.positions_dict[chName].positions.add({x: this.positions_dict[chName].x, y: this.positions_dict[chName].y, z: this.positions_dict[chName].z})
                } else {
                    // Increase invalid count for visualization
                    this.positions_dict[chName].position_invalid_count = this.positions_dict[chName].position_invalid_count+1
                }
            }),
        })
    }
}

export { MasterPositionStore, GlobalPositionStore, AcousticPositionStore }
