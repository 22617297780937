import React, { useState } from 'react';
import { Divider, Icon, Table } from 'semantic-ui-react';
import useSWR from 'swr';
import { RoundDecimalPlaces } from '../lib/format';

const fetcher = (...args) => fetch(...args).then((res) => res.json());
const swrOptions = {
  refreshWhenOffline: true,
  refreshWhenHidden: true,
  refreshInterval: 2000,
  dedupingInterval: 500,
};

function AdvancedTableEntry({ id, value }) {
  return (
    <Table.Row>
      <Table.Cell>{id}</Table.Cell>
      <Table.Cell textAlign={'right'}>{RoundDecimalPlaces(value, 2)}</Table.Cell>
    </Table.Row>
  );
}

const AdvancedStatDetail = ({ data }) => {
  const keys = Object.keys(data);
  return (
    <Table collapsing basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Statistic</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {keys.map((d) => (
          <AdvancedTableEntry key={d} id={d} value={data[d]} />
        ))}
      </Table.Body>
    </Table>
  );
};

function AdvancedFetcher() {
  const { data, error } = useSWR('/api/stats', fetcher, swrOptions);
  if (error) {
    return <p>Unable to fetch stats</p>;
  }
  if (!data) {
    return <Icon name="circle notch" loading />;
  }
  return <AdvancedStatDetail data={data} />;
}

function AdvancedStats() {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandIcon = isExpanded ? 'angle double up' : 'angle double down';

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Divider horizontal className="divider-spacing" onClick={toggleExpanded}>
        Advanced stats <Icon name={expandIcon} />
      </Divider>
      {isExpanded && <AdvancedFetcher />}
    </>
  );
}

export { AdvancedStats };
