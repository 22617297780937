function wsUrl() {
    if (process.env.REACT_APP_DEVMODE === "true") {
        // Somehow the websocket proxy in setupProxy.js is not working.
        // This workaround is needed until that is fixed.
        return "ws://127.0.0.1:8080/ws"
    }
    const proto = window.location.protocol === "https:" ? "wss://" : "ws://"
    return proto + window.location.host + "/ws"
}

export default wsUrl
