import { extendObservable, computed } from 'mobx'
import { GenericFormStore } from './FormStore'
import { rules } from '../../lib/validator'

class RangeFormStore extends GenericFormStore {
    constructor() {
        super()
        extendObservable(this, {
            form: {
                fields: {
                    xmin: {
                        value: 100,
                        negated: true,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(0, 100)]
                    },
                    xmax: {
                        value: 100,
                        negated: false,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(0, 100)]
                    },
                    ymin: {
                        value: 100,
                        negated: true,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(0, 100)]
                    },
                    ymax: {
                        value: 100,
                        negated: false,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(0, 100)]
                    },
                    zmax: {
                        value: 100,
                        negated: false,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(0, 100)]
                    },
                    radius: {
                        value: 150,
                        negated: false,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(1, process.env.REACT_APP_UGPS_MAX_RANGE)]
                    },
                    direction: {
                        value: 340,
                        negated: false,
                        convertUnit: false,
                        error: null,
                        rule: [rules.between(0, 360)]
                    },
                    sector: {
                        value: 90,
                        negated: false,
                        convertUnit: false,
                        error: null,
                        rule: [rules.between(0.1, 360)]
                    },
                },
                meta: {
                  isValid: true,
                  error: null,
                  unitName: "meter",
                  unitFactor: 1.0,
                  unitDecimals: 2,
                },
              },
            area: computed(() => this.getArea()),
            getArea: () => {
                let values = this.getFlattenedAbsoluteMetricValues()
                return (values.xmax - values.xmin)*
                    (values.ymax - values.ymin)
            },
            longestSide: () => {
                const values = this.getFlattenedAbsoluteMetricValues()
                const width = (values.ymax - values.ymin)
                const height = (values.xmax - values.xmin)
                return Math.max(width, height)
            }
        })
    }
}

export { RangeFormStore }
