import GeographicLib from 'geographiclib'
import { latLng } from 'leaflet'
import { PropTypes} from 'prop-types'
import { wrap360 } from '../../lib/helpers'

// Convert XY coordinates to polar coordinates (angle, distance)
function convertXYToPolarCoords(polygon) {
    return polygon.map((xy) => convertXYToPolar(xy))
}

convertXYToPolarCoords.propTypes = {
    polygon: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
    })
}

function convertXYToPolar(xy) {
    var an = Math.atan2(xy[1], xy[0])

    return {angle: an * 360 / (2*Math.PI), distance: Math.sqrt(xy[0]*xy[0] + xy[1]*xy[1])}
}

convertXYToPolar.propTypes = {
    xy: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
    })
}

function lefletToPolar(latlng) {
    var an = Math.atan2(latlng.lng, latlng.lat)
    an = an * 360 / (2*Math.PI)

    an = wrap360(an)

    return {angle: an, distance: Math.sqrt(latlng.lat*latlng.lat + latlng.lng*latlng.lng)}
}

lefletToPolar.propTypes = {
    latlng: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
    })
}

function polarToXY(angle, radius) {
    var rad = (angle* (2*Math.PI) / 360)
    var x = radius * Math.cos(rad)
    var y = radius * Math.sin(rad)
    return {x: x, y: y}
}

polarToXY.propTypes = {
        angle: PropTypes.number.isRequired,
        radius: PropTypes.number.isRequired
}

function polarToLeflet(angle, radius) {
    var rad = (angle* (2*Math.PI) / 360)
    var x = radius * Math.cos(rad)
    var y = radius * Math.sin(rad)
    return latLng(x, y)
}


// Add a distance in meter at the given angle to a latlon and return corresponding latlon
function offsetLatLonByDistanceAngle(center, angle, distance) {
    const geod = GeographicLib.Geodesic.WGS84;

    let an = wrap360(angle)

    let r = geod.Direct(center.lat, center.lng, an, distance);
    return {lat: r.lat2, lng: r.lon2}
}

offsetLatLonByDistanceAngle.propTypes = {
    center: PropTypes.number.isRequired,
    angle: PropTypes.number.isRequired,
    distance: PropTypes.number.isRequired,
}

// Convert XY values to Leaflet map CRS coordinate system coordinates
function xyToLeflet(x, y) {
    return latLng(x, y)
}

// Leaflet map CRS coordinate system coordinates to XY
function leafletToXY(latlng) {
    return [latlng.lat, latlng.lng]
}


export { convertXYToPolar, convertXYToPolarCoords, offsetLatLonByDistanceAngle, xyToLeflet, leafletToXY, polarToXY, polarToLeflet, lefletToPolar }
