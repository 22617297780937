import React, { Component } from 'react';
import { Segment, Icon, Grid, Table, Responsive } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import { ShowUnit } from '../ShowUnit'
import { formatDecimals } from '../../lib/format'

const DirectionAndValueCell = ({direction, icon, value, error, unitName}) => (
    <Table.Row>
        <Table.Cell>
            <Icon name={icon}/><Responsive as="span" minWidth={1200}>&nbsp;{direction}</Responsive>
        </Table.Cell>

        <Table.Cell className={error ? "error" : ""}>
            {formatDecimals(value, 1)}
        </Table.Cell>

        <Table.Cell >
            <ShowUnit unit={unitName}/>
        </Table.Cell>
    </Table.Row>
)

const CircularRangeConfigView = observer(class CircularRangeConfigView extends Component {
    render() {
        const { form, unitName } = this.props
        return (
            <Segment attached>
            <h5>Search range</h5>
            <Table basic="very" singleLine unstackable>
                <Table.Body>
                    <DirectionAndValueCell
                        direction="Direction"
                        icon="compass outline"
                        value={form.fields.direction.value}
                        error={form.fields.direction.error}
                        unitName="degrees"
                    />
                    <DirectionAndValueCell
                        direction="Sector"
                        icon="chevron down"
                        value={form.fields.sector.value}
                        error={form.fields.sector.error}
                        unitName="degrees"
                    />
                    <DirectionAndValueCell
                        direction="Radius"
                        icon="resize horizontal"
                        value={form.fields.radius.value}
                        error={form.fields.radius.error}
                        unitName={unitName}
                    />
                </Table.Body>
            </Table>
            </Segment>
        )
    }
})


const ReceiverView = observer(class ReceiverView extends Component {
    render() {
        const { form, getAbsolute, unitName } = this.props
        const x = getAbsolute("x")
        const y = getAbsolute("y")
        return (
            <Segment attached>
            <h5>Receiver {this.props.id+1}</h5>

            <Table basic="very" singleLine unstackable>
                <Table.Body>
                    <DirectionAndValueCell
                        direction={x > 0 ? "Forward" : "Backward"}
                        icon={x > 0 ? "arrow up" : "arrow down"}
                        value={form.fields.x.value}
                        error={form.fields.x.error}
                        unitName={unitName}
                    />
                    <DirectionAndValueCell
                        direction={y > 0 ? "Right" : "Left"}
                        icon={y > 0 ? "arrow right" : "arrow left"}
                        value={form.fields.y.value}
                        error={form.fields.y.error}
                        unitName={unitName}
                    />
                    <DirectionAndValueCell
                        direction={"Depth"}
                        icon={"level down"}
                        value={form.fields.z.value}
                        error={form.fields.z.error}
                        unitName={unitName}
                    />
                </Table.Body>
            </Table>
            </Segment>
        )
    }
})


const CircularShowFullSummary = ({rangeFormStore, rxCollectionFormStore}) => (
    <Grid stackable>
    <Grid.Row columns={2}>
        <Grid.Column width={4}>
            <CircularRangeConfigView form={rangeFormStore.form} unitName={rangeFormStore.unitName}/>
        </Grid.Column>
        <Grid.Column width={12}>
            <Grid stackable divided>
            <Grid.Row columns={rxCollectionFormStore.count}>
            {rxCollectionFormStore.all.map(d => (
            <Grid.Column key={d.id}>
            <ReceiverView
                id={d.id}
                form={d.form}
                getAbsolute={d.getAbsolute}
                unitName={d.unitName}
            />
            </Grid.Column>
            ))}
            </Grid.Row>
            </Grid>
        </Grid.Column>
    </Grid.Row>
</Grid>
)

const CircularShowSmallSummary = ({rangeFormStore, rxCollectionFormStore}) => (
    <Segment>
        <CircularRangeConfigView form={rangeFormStore.form} unitName={rangeFormStore.unitName}/>
        {rxCollectionFormStore.all.map(d => (
            <ReceiverView
                key={d.id}
                id={d.id}
                form={d.form}
                getAbsolute={d.getAbsolute}
                unitName={d.unitName}
            />
        ))}
    </Segment>
)

const CircularSummary = ({form, unitName}) => (
    <Grid stackable>
        <Grid.Column width={4}>
            <CircularRangeConfigView form={form} unitName={unitName}/>
        </Grid.Column>
    </Grid>
)

export { CircularShowFullSummary, CircularShowSmallSummary, CircularSummary }
