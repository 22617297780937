import { extendObservable, computed } from 'mobx'
import { GenericFormStore } from './FormStore'
import { rules } from '../../lib/validator'

class PointOfInterestItemFormStore extends GenericFormStore {
    constructor(id, lat, lng, depth, name, icon) {
        super()
        extendObservable(this, {
            id: id,
            form: {
                fields: {
                    lat: {
                        value: lat,
                        error: null,
                        rule: [rules.between(-90, 90)],
                    },
                    lng: {
                        value:lng,
                        error: null,
                        rule: [rules.between(-180, 180)],
                    },
                    depth: {
                        value: depth,
                        error: null,
                        rule: [rules.between(0, 100)],
                    },
                    name: {
                        value: name,
                        error: null,
                        rule: [rules.string()],
                    },
                    icon: {
                        value: icon,
                        error: null,
                        rule: [rules.string()],
                    },
                    visible: {
                        value: true,
                        error: null,
                        rule: [],
                    },
                },
                meta: {
                  isValid: true,
                  error: null,
                  unitName: "meter",
                  unitFactor: 1.0,
                  unitDecimals: 2,
                },
            },
            latlng: computed(() => {
                const lat = this.getAbsolute("lat")
                const lng = this.getAbsolute("lng")
                return {lat: isNaN(lat) ? 0 : lat, lng: isNaN(lng) ? 0 : lng}
            }),
            json: computed(() => {
                return {
                    "name": this.form.fields.name.value,
                    "lat": this.getAbsolute("lat"),
                    "lng": this.getAbsolute("lng"),
                    "depth": this.getAbsoluteMetric("depth"),
                    "icon": this.form.fields.icon.value,
                    "visible": this.form.fields.visible.value,
                }
            }),
            isEditing: false,
            isLoading: false,
            errorUpdate: "",
            errorDelete: "",
        })
    }
}

export { PointOfInterestItemFormStore }

