import React, { useState } from 'react';
import { Icon, Popup, Button, Table, Segment, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import useSWR from 'swr'

const fetcher = (...args) => fetch(...args).then(res => res.json())
const swrOptions = {
    refreshWhenOffline: true,
    refreshWhenHidden: true,
    refreshInterval: 1000,
    dedupingInterval: 500,
}

function StatusGroupDetail({status_group, status, message, help, error_ids}) {
    const [isExpanded, setIsExpanded] = useState(false)
    const expandIcon = isExpanded ? "chevron up" : "chevron down"

    // Hack to show link to IMU calibration if heading is missing
    // TODO: Find a better way of doing this
    const err_id = error_ids || []  // Make sure we always have an array
    const linkToIMU = err_id.findIndex(d => d === "imu:onboard:missingheading") >= 0

    const icon = status === "ok" ? "check" : "close"
    const color = status === "ok" ? "green" : "red"

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <Table.Row>
        <Table.Cell style={{textTransform: 'capitalize'}}>{status_group}</Table.Cell>
        <Table.Cell><Icon name={icon} color={color}/></Table.Cell>
        <Table.Cell>
            <p style={{whiteSpace: "pre-line"}}>{message} {linkToIMU && (<p><Link to={"/imu"}>Click to set heading</Link></p>)}</p>
            {help && <Icon onClick={toggleExpanded} name={expandIcon}/>}
            {isExpanded && <div>{help}</div>}
        </Table.Cell>
        </Table.Row>
    )
}

const StatusReportDetail = ({sorted}) => {
    return (
        <Table collapsing basic="very">
        <Table.Body>
            {sorted.map(d => (
                <StatusGroupDetail key={d.status_group+d.message} {...d}/>
            ))}
        </Table.Body>
        </Table>
    )
}

function severityToNumber(severity) {
    if (severity === "error") { return 2 }
    if (severity === "warning") { return 1 }
    return 0

}


function StatusReport() {
    const [isOpen, setIsOpen] = useState(true)

    const { data, error } = useSWR('/api/v1/status_report/', fetcher, swrOptions)
    const statuses = error ? [] : (data ? data : [])
    const severity = Math.max(...statuses.map(d => severityToNumber(d.status)))
    const isOK = error ? false : severity < 2
    return (
        <div>
            <Popup
                on="click"
                trigger={
                    <Button icon labelPosition="right" color={isOK ? "black" : "red"}>System status
                        <Icon name={isOK ? "check" : "close"} color={isOK ? "green": "grey"}/>
                    </Button>
                }
                wide="very"
                open={isOpen}
                onOpen={() => setIsOpen(true)}
                onClose={() => setIsOpen(false)}
            >
            <Popup.Content>
                <Segment>
                {error && (
                    <Message icon>
                    <Icon name="exclamation" color="red"/>
                    <Message.Content>
                        <Message.Header>
                            No connection to Underwater GPS topside
                        </Message.Header>
                        Please check network connection and power.
                    </Message.Content>
                    </Message>
                )}
                <StatusReportDetail sorted={statuses}/>
{/*
                <Checkbox slider label="Auto open on error" defaultChecked/>
*/}
                </Segment>
            </Popup.Content>
            </Popup>
        </div>
    )
}


export { StatusReport }
