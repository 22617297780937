import React, { useState } from 'react';
import { Segment, Divider, Button, Header, Form, Message } from 'semantic-ui-react'
import fetch from 'unfetch'

async function ForceExternalDepth(depth, timeout) {
    const data = {
        depth: Number(depth),
        temp: 0
    }
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }
    const url = "/api/v1/external/depth"
    const extra = timeout > 0 ? "?t=" + timeout : ""
    return fetch(url + extra, options)
}

function ExternalDepth() {
    const [submitMessage, setSubmitMessage] = useState("")
    const [depth, setDepth] = useState(0.1)
    const setExternalDepth = async (depth, persistent) => {
        setSubmitMessage("")
        console.log("force", depth, persistent)
        const response = await ForceExternalDepth(depth, persistent)
        if (!response.ok) {
            const msg = await response.text()
            setSubmitMessage(`ERR ${response.status} ${msg}`)
        } else {
            const msg = await response.text()
            setSubmitMessage(`Set depth: ${msg}`)
        }
    }
    return (
        <div>

        <Form>
            <Form.Field>
                <Form.Input type="number" value={depth} onChange={(e) => setDepth(e.target.value)}/>
            </Form.Field>
        </Form>
        <br/>
        <Button onClick={() => setExternalDepth(depth, 0)}>Set (10s timeout)</Button>
        <Button onClick={() => setExternalDepth(depth, 5*60)}>Set (5min timeout)</Button>
        <Button onClick={() => setExternalDepth(depth, 60*60)}>Set (1hour timeout)</Button>
        <div>{submitMessage}</div>

        </div>
    )
}


function Debug() {
    return (
        <Segment attached>

        <Divider horizontal>Debug</Divider>

        <Message
            header="Careful"
            content="This section is for testing the API. We recommend not clicking on anything here."
        />

        <Header>External depth</Header>
        <ExternalDepth/>

      </Segment>
    )
}

export default Debug
