import React, { Component } from 'react';
import { Marker, Popup, FeatureGroup, LayerGroup, Polyline, Circle } from 'react-leaflet'
import RotatedMarker from 'react-leaflet-rotatedmarker'
import { observer, inject } from 'mobx-react'
import { PropTypes } from 'prop-types';
import { xyToLeflet } from './geohelpers';
import { origoIcon, rovIcon, receiverIcon, unknownPositionIcon } from '../../lib/markerIcon'

const MarkerPopup = ({children}) => (
    <Popup>
    <span>
        {children}
    </span>
    </Popup>
)


const MarkerAndTrail = ({position, trail, color, icon, children, orientation, offsetOrientation}) => (
    <FeatureGroup>
    {orientation >= 0 ?
        <RotatedMarker icon={rovIcon} position={position} rotationAngle={offsetOrientation} rotationOrigin={"16px 16px"}>
            {children && (<MarkerPopup>{children}</MarkerPopup>)}
        </RotatedMarker>
        :
        <Marker position={position} icon={icon}>
        {children && (<MarkerPopup>{children}</MarkerPopup>)}
       </Marker>
    }
    {trail && (
        <Polyline positions={trail} color={color}/>
    )}
    </FeatureGroup>
)

MarkerAndTrail.propTypes = {
    position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    trail: PropTypes.array,
    color: PropTypes.string,
    orientation: PropTypes.number,
}

const UnknownPositionAndTrail = ({position, trail, color, children}) => (
    <FeatureGroup>
        <Marker icon={unknownPositionIcon} position={position}>
        {children && (<MarkerPopup>{children}</MarkerPopup>)}
       </Marker>
    {trail && (
        <Polyline positions={trail} color={color}/>
    )}
    </FeatureGroup>
)

UnknownPositionAndTrail.propTypes = {
    position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    trail: PropTypes.array,
    color: PropTypes.string,
}

const MarkerAndTrailWithOrientation = inject("locatorOrientationStore")(observer(class MarkerAndTrailWithOrientation extends Component {
    render() {
        const { color, position, trail, icon, children, position_valid, std, chName } = this.props
        // Set orientation to -1 not to show arrow
        var orientation = -1
        var offsetOrientation = this.props.offsetOrientation
        // Acoustic view might not have north up in the screen
        // "orientation" is the compass value from the ROV
        // "offsetOrientation" is the amount to adjust the compass value to compensate for the acoustic view not pointing north

        if (chName === "filtered") {
            orientation = this.props.locatorOrientationStore.orientation
            offsetOrientation = offsetOrientation + orientation
            // Check if we have overflowed 360 deg, if so subtract 360
            if (offsetOrientation > 359) {
                offsetOrientation = offsetOrientation - 360
            }
        }

        if ((!position_valid) || (std < 0)) {
            // No acoustic position, show an a question mark
            return <UnknownPositionAndTrail
                position={position}
                trail={trail}
                color={color}
                children={children}
                />
        }
        return <MarkerAndTrail
            position={position}
            trail={trail}
            color={color}
            icon={icon}
            children={children}
            orientation={orientation}
            offsetOrientation={offsetOrientation}
            />
    }
}))

MarkerAndTrailWithOrientation.propTypes = {
    position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    trail: PropTypes.array,
    color: PropTypes.string,
    orientation: PropTypes.number,
}

const AccuracyVisualization = (observer(({ color, position, std, position_invalid_count} ) => {
    var col = color || "#20571a"
    var radius = std

    if (position_invalid_count > 3) {
        // We have a fetch error. Shown in a different color and a defined size
        //maxRadius = 50
        //radius = (3.0 + 0.5 * acousticPositionStore.position_invalid_count)
        // Clamp to maxRadius
        //radius = radius > maxRadius ? maxRadius : radius
        col = "#d22828";
    } else {
        // Accuracy visualization removed for 2.5.0
        return null
    }

    return (
        <Circle
            center={position}
            radius={radius}
            color={col}
            opacity={0.5}
            fillOpacity={0.1}
        />
    )
}))

AccuracyVisualization.propTypes = {
    position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    color: PropTypes.string,
}

const LocatorMarkerAndTrail = inject("globalPositionStore")(observer(({ globalPositionStore, trailLength, color, children, ...props }) => {
    const markerAndTrails = []

    for (var chName in globalPositionStore.positions_dict) {
        const positionStore = globalPositionStore.positions_dict[chName]
        if (positionStore.show) {
            const trail = positionStore.positions.getLatLng(trailLength)
            const position = [positionStore.lat, positionStore.lng]
            markerAndTrails.push(<MarkerAndTrailWithOrientation color={positionStore.color}
                                                                position={position}
                                                                trail={trail}
                                                                offsetOrientation={0}
                                                                icon={positionStore.icon}
                                                                std={positionStore.std}
                                                                position_valid={globalPositionStore.position_valid}
                                                                chName={chName}
                                                                key={chName + "marker"}>
                                    {positionStore.name}
                                </MarkerAndTrailWithOrientation>)
            markerAndTrails.push(
                <AccuracyVisualization
                    position={position}
                    std={positionStore.std}
                    key={chName + "viz"}
                />)
        }

    }
    return (
        <LayerGroup>
            {markerAndTrails}
        </LayerGroup>
    )
}))

LocatorMarkerAndTrail.propTypes = {
    position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    trail: PropTypes.array,
    trailLength: PropTypes.number,
    color: PropTypes.string,
}

const AcousticLocatorMarkerAndTrail = inject("acousticPositionStore")(inject("masterPositionStore")(observer(({ acousticPositionStore, fusionPositionStore, masterPositionStore, trailLength, color, children, ...props} ) => {
    //// Compansate for offset in orientation in acoustic view
    const offsetOrientation = 360 - masterPositionStore.orientation
    const markerAndTrails = []

    for (var chName in acousticPositionStore.positions_dict) {
        const positionStore = acousticPositionStore.positions_dict[chName]
        if (positionStore.show) {
            const trail = positionStore.positions.getXY(trailLength).map(d => xyToLeflet(...d))
            const position = xyToLeflet(positionStore.x, positionStore.y)
            markerAndTrails.push(<MarkerAndTrailWithOrientation color={positionStore.color}
                                                                position={position}
                                                                trail={trail}
                                                                offsetOrientation={offsetOrientation}
                                                                icon={positionStore.icon}
                                                                std={positionStore.std}
                                                                position_valid={positionStore.position_valid}
                                                                chName={chName}
                                                                key={chName + "marker"}>
                                    {positionStore.name}
                                </MarkerAndTrailWithOrientation>)
            markerAndTrails.push(<AccuracyVisualization
                                    position={position}
                                    std={positionStore.std}
                                    position_invalid_count={positionStore.position_invalid_count}
                                    key={chName + "viz"}
                                />)
        }

    }

    return (
        <FeatureGroup>
            {markerAndTrails}
        </FeatureGroup>
    )
})))

AcousticLocatorMarkerAndTrail.propTypes = {
    position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    trail: PropTypes.array,
    trailLength: PropTypes.number,
    color: PropTypes.string,
}

const MasterMarkerAndTrail = inject("masterPositionStore")(observer(({ masterPositionStore, trailLength, color, ...props} ) => {
    const trail = masterPositionStore.positions.getLatLng(trailLength)
    return (
        <LayerGroup>
        <RotatedMarker
            icon={origoIcon}
            position={[masterPositionStore.lat, masterPositionStore.lng]}
            rotationAngle={masterPositionStore.orientation}
            rotationOrigin={"25px 48px"}
        >
            <Popup><span>Master electronic position</span></Popup>
        </RotatedMarker>

        {trail.length > 0 && (
            <Polyline positions={trail} color={color}/>
        )}

        </LayerGroup>
    )
}))

MasterMarkerAndTrail.propTypes = {
    position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    trail: PropTypes.array,
    trailLength: PropTypes.number,
    rotationAngle: PropTypes.number,
    color: PropTypes.string,
}

const OrigoMarker = ({position, description}) => (
    <Marker position={position} icon={origoIcon}><Popup><span>{description}</span></Popup></Marker>
)

OrigoMarker.propTypes = {
    position: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    description: PropTypes.string.isRequired,
}


export { MasterMarkerAndTrail, LocatorMarkerAndTrail, AcousticLocatorMarkerAndTrail, MarkerAndTrailWithOrientation, MarkerAndTrail, OrigoMarker, receiverIcon }
