import React, { Component } from 'react';
import { Icon, Responsive } from 'semantic-ui-react'
import { observer, inject } from 'mobx-react'
import { PropTypes } from 'prop-types';

const ColorStatusIcon = ({icon, status, content, title}) => {
    var color = "red"
    var quality = "poor"
    var icon_name = "close"
    if (status >= 1) {
        color = "yellow"
        quality = "fair"
    }
    if (status > 2) {
        color = "green"
        quality = "good"
        icon_name = icon;
    }
    let help = title + " " + quality;
    return <span title={help}>{content} <Icon name={icon_name} color={color}/></span>
}

const BatteryIcon = ({percent}) => {
    var icon = "battery zero"
    var color = "green"
    if (percent >= 80) {
        icon = "battery four"
    } else if (percent >= 60) {
        icon = "battery three"
    } else if (percent >= 40) {
        icon = "battery two"
    } else if (percent >= 20) {
        icon = "battery one"
        color = "yellow"
    } else {
        icon = "battery zero"
        color = "red"
    }
    return (<span title={"Locator battery at " + percent + "%"}>U1 <Icon name={icon} color={color}/></span>)
}

const StatusBar = inject("gpsimuStore")(observer(class StatusBar extends Component {
    render() {
        const {gps, imu, battery} = this.props.gpsimuStore
        return (
            <Responsive minWidth={this.props.minWidth}>
            <ColorStatusIcon key={"gps"+gps} title={"GPS reception"} icon="signal" status={gps} content="GPS"/>
            <ColorStatusIcon key={"imu"+imu} title={"IMU calibration"} icon="compass outline" status={imu} content="IMU"/>
            {battery >= 0 && (<BatteryIcon key={battery} percent={battery}/>)}
            </Responsive>
        )
    }
}))

StatusBar.propTypes = {
    minWidth: PropTypes.number.isRequired,
}


const ShowProductNameAndVersion = inject("aboutInfoStore")(observer(({aboutInfoStore, minWidth}) => (
    <Responsive minWidth={minWidth}>
        {isNaN(process.env.REACT_APP_UGPS_MAX_RANGE) ? (
            <strong style={{color: "red"}}>DON'T SHIP IT, range is not a number!</strong>
            ):(
            <div>
                <strong style={{color: "#fff"}}>Underwater GPS G2</strong>
                {aboutInfoStore.hasVersionShort && (<span style={{paddingLeft: "0.7em", fontSize: "0.7em"}}>({aboutInfoStore.versionShort} R{process.env.REACT_APP_UGPS_MAX_RANGE})</span>)}
            </div>
            )
        }
  </Responsive>
)))

ShowProductNameAndVersion.propTypes = {
    minWidth: PropTypes.number.isRequired,
}

export { StatusBar, ColorStatusIcon, BatteryIcon, ShowProductNameAndVersion }
