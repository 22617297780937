import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
//import { PropTypes } from 'mobx-react';

class ButtonSelector extends Component {
    onChange = (e) => {
        this.props.onChange(e.target.name, e.target.value);
        e.preventDefault();
    }
    render() {
        return (
            <Button.Group>
            {this.props.options.map(item => (
                <Button type="button" onClick={this.onChange} name={this.props.name} key={item.value} active={item.value === this.props.value} value={item.value}>{item.description}</Button>
            ))}
            </Button.Group>
        )
    }
}

/*
ButtonSelector.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        description: PropTypes.string,
    })).isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
}
*/

export default ButtonSelector;
