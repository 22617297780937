import { extendObservable, action } from 'mobx';

class WSStatusStore {
    constructor() {
        extendObservable(this, {
            connected: false,
            error:  "",
            setConnected: action((is_connected) => {
                this.connected = is_connected
            }),
        })
    }
}

export default WSStatusStore
