import { extendObservable, action, computed } from 'mobx'

class MapStateStore {
    constructor() {
        extendObservable(this, {
            showMap: false,
            setShowMap: action((enabled) => {
                this.showMap = enabled
            }),

            trailMax: 5*60, // 5 minutes
            trailLength: 60,
            setTrailLength: action((val) => {
                this.trailLength = val
            }),
            trailValue: computed(() => {
                let val = parseFloat(this.trailLength) || 1
                return Math.min(this.trailMax, Math.max(1, val))
            }),
            trailValueError: computed(() => {
                let val = parseFloat(this.trailLength)
                return isNaN(val) || (val < 1) || (val > this.trailMax)
            }),
            poiExpanded: false,
            setPoiExpanded: action((value) => {
                this.poiExpanded = value
            }),
        })
    }
}

export default MapStateStore