import React from 'react';
import { Icon } from 'semantic-ui-react'

class ExpandableSettings extends React.Component {
    state = { expanded: false }

    toggleExpanded = (e) => {
        this.setState({expanded: !this.state.expanded})
    }

    render() {
        const icon = (this.state.expanded ? "angle double up" : "angle double down")
        return (
            <div>
                <h3 onClick={this.toggleExpanded}> Advanced settings <Icon name={icon} /></h3>
                {this.state.expanded && (this.props.children)}
            </div>
        )
    }
}

export { ExpandableSettings }
