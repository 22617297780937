import { ReceiverStore } from './Receivers'
import { AntennaStore } from './Antenna'
import { GenericConfigStore } from './GenericConfig'
import { MasterPositionStore, GlobalPositionStore, AcousticPositionStore } from './Positions'
import { GPSIMUStatusStore } from './Status'
import { newPreferenceStore } from './Preferences'
import { AboutInfoStore } from './Version'
import { LocatorOrientation } from './LocatorOrientation'
import PointOfInterestStore from './PointOfInterest'
import NetworkConfigStore from './NetworkConfig'
import WifiConfigStore from './WifiConfig'
import TemperatureStore from './Temperature'
import WSStatusStore from './WSStatusStore'
import ReconnectingWebSocket from 'reconnecting-websocket';
import wsUrl from '../lib/ws'

export default function initStores() {
    const genericConfigStore = new GenericConfigStore()
    genericConfigStore.fetch()

    const rxStore = new ReceiverStore()
    rxStore.fetch()

    const antennaStore = new AntennaStore()
    antennaStore.fetch()

    setInterval(() => {
        genericConfigStore.fetch()
        rxStore.fetch()
        antennaStore.fetch()
    }, 15000)

    const pointOfInterestStore = new PointOfInterestStore()
    pointOfInterestStore.fetch()

    const gpsimuStore = new GPSIMUStatusStore()
    gpsimuStore.fetch()
    setInterval(() => {
        gpsimuStore.fetch()
    }, 1000)

    const aboutInfoStore = new AboutInfoStore()
    setTimeout(() => {
        aboutInfoStore.fetch()
    }, 500)

    const temperatureStore = new TemperatureStore()
    temperatureStore.fetch()
    setInterval(() => {
        temperatureStore.fetch()
    }, 7000)


    const acousticPositionStore = new AcousticPositionStore()
    const fusionPositionStore = new AcousticPositionStore()
    const masterPositionStore = new MasterPositionStore()
    const globalPositionStore = new GlobalPositionStore()

    const locatorOrientationStore = new LocatorOrientation()
    const networkConfigStore = new NetworkConfigStore()
    const wifiConfigStore = new WifiConfigStore()
    const connectionStatusStore = new WSStatusStore()

    const preferenceStore = newPreferenceStore(localStorage)

    var timeoutGlobalHandle
    var timeoutAcosticHandle
    var timeoutOrientation

    function globalTimeoutFunc() {
        globalPositionStore.setNoValue()
    }

    function acousticTimeoutFunc() {
        acousticPositionStore.setNoValue()
    }

    function orientationTimeoutFunc() {
        locatorOrientationStore.setNoValue()
    }


    function updateStoresFromWS() {
        const url = wsUrl()
        const socket = new ReconnectingWebSocket(url)
        socket.onopen = () => {
            connectionStatusStore.setConnected(true)
            console.log("store ws open")
        }
        socket.onclose = () => {
            connectionStatusStore.setConnected(false)
            console.log("store ws close")
            masterPositionStore.setNoValue()
            globalPositionStore.setNoValue()
            acousticPositionStore.setNoValue()
        }
        socket.onmessage = (e) => {
            const json = (function(raw) {
                try {
                    return JSON.parse(raw);
                } catch (err) {
                    return false;
                }
            })(e.data);
            if (!json) {
                return
            }
            if (json.channel === "position:relative:filtered") {
                acousticPositionStore.setFromWsDict(json.payload, json.channel)

                if (timeoutAcosticHandle) {
                    clearTimeout(timeoutAcosticHandle)
                }
                timeoutAcosticHandle = setTimeout(acousticTimeoutFunc, 5000)
            }
            if (json.channel === "position:relative:raw") {
                //console.count("ws store raw")
                acousticPositionStore.setFromWsDict(json.payload, json.channel)
            }
            // if (json.channel === "position:relative:fusion") {
            //     //console.count("ws store vel")
            //     acousticPositionStore.setFromWsDict(json.payload, json.channel)
            // }
            else if (json.channel === "position:master:active") {
                masterPositionStore.setFromWs(json.payload)
            }
            else if (json.channel === "position:global:filtered") {
                //globalPositionStore.setFromWs(json.payload)
                globalPositionStore.setFromWsDict(json.payload, json.channel)

                if (timeoutGlobalHandle) {
                    clearTimeout(timeoutGlobalHandle)
                }
                timeoutGlobalHandle = setTimeout(globalTimeoutFunc, 5000)
            }
            // else if (json.channel === "position:global:fusion") {
            //     //globalPositionStore.setFromWs(json.payload)
            //     globalPositionStore.setFromWsDict(json.payload, json.channel)
            // }
            else if (json.channel === "orientation:external:vehicle") {
                //console.log(json)
                locatorOrientationStore.setFromWs(json.payload)
                if (timeoutOrientation) {
                    clearTimeout(timeoutOrientation)
                }
                timeoutOrientation = setTimeout(orientationTimeoutFunc, 5000)
            }
        }
    }

    updateStoresFromWS()

    return {
        rxStore,
        antennaStore,
        genericConfigStore,
        masterPositionStore,
        globalPositionStore,
        acousticPositionStore,
        fusionPositionStore,
        gpsimuStore,
        preferenceStore,
        aboutInfoStore,
        locatorOrientationStore,
        pointOfInterestStore,
        networkConfigStore,
        wifiConfigStore,
        temperatureStore,
        connectionStatusStore,
    }
}