import React, { Component } from 'react';
import { Segment, List, Button } from 'semantic-ui-react'
import ReleaseNotes from '../Components/ReleaseNotes'
import { VersionInfoView } from '../Components/VersionInfo'

const Description = () => (
    <Segment>
        <h3>Getting started</h3>

        <ol>
            <li>Mount the Locator U1 on the ROV or diver.</li>
            <li>Connect Antenna to topside housing and deploy in water</li>
            <li>Deploy ROV/Diver</li>
            <li>Position shows up in the GUI.</li>
        </ol>

        <a target="new" href="https://waterlinked.com/usermanual"><Button color="green">User Manual</Button></a>
        <a target="new" href="https://waterlinked.com/youtube"><Button color="green">Instructional Videos</Button></a>

        <h4>Developers</h4>

        <a target="new" href="/swagger/"><Button color="green">API Documentation</Button></a>
        <a target="new" href="https://github.com/waterlinked/examples"><Button color="green">Example code</Button></a>

    </Segment>
)

/*
const ExpandableSection = ({heading, children}) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const icon = isExpanded ? "angle double up" : "angle double down"
    return (
        <div>
            <h2 onClick={() => setIsExpanded(!isExpanded)}>{heading} <Icon name={icon}/></h2>
            {isExpanded && children}
        </div>
    )
}

const ExpandableHardwareSection = () => {
    return (
        <ExpandableSection heading="Hardware">
            <Segment>
            <LEDButton/> Click to flash LED 1.
            </Segment>
        </ExpandableSection>
    )
}
*/


class About extends Component {
    render() {
        return (
            <Segment>
                <Segment>
                <h3>Version information</h3>
                <VersionInfoView/>
                <h3>Browser compatibility</h3>
                <p>This GUI has been tested and verified to work with the following browsers:</p>
                <List>
                    <List.Item icon="chrome" content="Chrome 93 (Recommended)" />
                    <List.Item icon="firefox" content="Firefox 92" />
                </List>
                </Segment>

                <Description/>

                <h3>Release notes</h3>
                <ReleaseNotes/>

                <p>&nbsp;</p>
            </Segment>
        )
    }
}

export default About;
