import { extendObservable } from "mobx"
import { superFetch } from '../lib/fetch'


class GPSIMUStatusStore {
    constructor() {
        extendObservable(this, {
            isLoading: false,
            loadingError: "",
            gps: 0,
            imu: 0,
            battery: -1,
        })
    }
    fetch() {
        this.isLoading = true
        superFetch('/api/v1/about/status')
        .then((data) => {
            this.imu = data.imu
            this.gps = data.gps
            this.battery = data.battery
            this.loadingError = ""
        })
        .catch((e) => { 
            this.isLoading = false
            this.loadingError = e.toString()
        })
    }
}

export { GPSIMUStatusStore }
