import React from 'react';
import { Message } from 'semantic-ui-react'
import PropTypes from 'prop-types';

const ErrorMessage = ({heading, message}) => (
    <Message warning><Message.Header>{heading}</Message.Header>
        <p>{message}</p>
    </Message>
)

ErrorMessage.propTypes = {
    heading: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
}

export default ErrorMessage
