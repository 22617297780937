import React from 'react';
import { Message, Icon } from 'semantic-ui-react'

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
      console.log("Error boundary")
      this.setState({
          error: error,
          errorInfo: errorInfo,
        });
      // TODO: Log error to some service in case the user has innernet access
    }

    render() {
      if (this.state.errorInfo) {
        // You can render any custom fallback UI
        return (
            <Message icon>
              <Icon name="frown"/>
              <Message.Content>
                <Message.Header>Woops</Message.Header>
                <p>The component stopped working. If this failure happens again,
                  please record the steps you have taken which triggered the failure
                  and notify Water Linked with the details below.</p>
                <details style={{ whiteSpace: 'pre-wrap'}}>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
              </Message.Content>
            </Message>
        )
      }
      // Rendering the children if no error occured
      return this.props.children;
    }
}

export default ErrorBoundary