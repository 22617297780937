import { PropTypes } from 'prop-types';

function cross(a, b, o) {
    return (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0])
 }

 /**
  * @param points An array of [X, Y] coordinates
  */
 function convexHull(points) {
    points.sort(function(a, b) {
       return a[0] === b[0] ? a[1] - b[1] : a[0] - b[0];
    });

    let lower = [];
    for (let i = 0; i < points.length; i++) {
       while (lower.length >= 2 && cross(lower[lower.length - 2], lower[lower.length - 1], points[i]) <= 0) {
          lower.pop();
       }
       lower.push(points[i]);
    }

    let upper = [];
    for (let i = points.length - 1; i >= 0; i--) {
       while (upper.length >= 2 && cross(upper[upper.length - 2], upper[upper.length - 1], points[i]) <= 0) {
          upper.pop();
       }
       upper.push(points[i]);
    }

    upper.pop();
    lower.pop();
    return lower.concat(upper);
 }

 convexHull.propTypes = {
    points: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired
    })
}

function polygonArea(xy) {
    var area = 0;  // Accumulates area in the loop
    let j = xy.length - 1;  // The last vertex is the 'previous' one to the first

    for (let i=0; i < xy.length; i++) {
        area = area +  (xy[j][0] + xy[i][0]) * (xy[j][1] - xy[i][1]);
        //area = area +  (X[j]+X[i]) * (Y[j]-Y[i]);
        j = i;  //j is previous vertex to i
    }
    return area / 2;
}

polygonArea.propTypes = {
    xy: PropTypes.shape({
        x: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
        y: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired})
    })
}

export  { convexHull, polygonArea }
