import React, { Component } from 'react';
import { Polygon } from 'react-leaflet'
import { convexHull } from './geometry'
import { xyToLeflet } from './geohelpers'

/*
Debugging of Area generated by the receivers
*/
class ConvexHullPoly extends Component {
    render() {
        if (this.props.points.length < 2) {
            return null
        }
        const { points } = this.props
        console.log(points)
        const cxpts = points.map(d => [d.x, d.y])
        const hull = convexHull(cxpts)
        let hullPoints = hull.map(d => xyToLeflet(d[0], d[1]))
        return (
            <Polygon positions={hullPoints} color="blue"/>
        )

    }
}

export { ConvexHullPoly }
