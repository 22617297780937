import { extendObservable, computed, action } from 'mobx'
import { GenericFormStore, remap } from './FormStore'
import { rules } from '../../lib/validator'

const channelSupported = {
  "a1": [remap("5014", "1"), remap("5015", "2"), remap("5016", "3"), remap("5017", "4"),
         remap("5018", "5"), remap("5019", "6"), remap("5020", "7")],
  "d1": [remap("5014", "1"), remap("5015", "2"), remap("5016", "3"), remap("5017", "4"),
         remap("5018", "5"), remap("5019", "6"), remap("5020", "7")],
  //"s1": [remap("16", "16"), remap("17", "17"), remap("18", "18")],
  "u1": [remap("5014", "1"), remap("5015", "2"), remap("5016", "3"), remap("5017", "4"),
  remap("5018", "5"), remap("5019", "6"), remap("5020", "7")],
  "s2": [remap("5014", "1"), remap("5015", "2"), remap("5016", "3"), remap("5017", "4"),
         remap("5018", "5"), remap("5019", "6"), remap("5020", "7")],
  "p2": [remap("5014", "1"), remap("5015", "2"), remap("5016", "3"), remap("5017", "4"),
         remap("5018", "5"), remap("5019", "6"), remap("5020", "7")],
  //"p1": [remap("4014", "4014"), remap("4015", "4015"), remap("4016", "4016"), remap("4017", "4017"),
  //       remap("4018", "4018"), remap("4019", "4019"), remap("4020", "4020"),],
}

class ConfigStore extends GenericFormStore {
    constructor() {
        super()
        extendObservable(this, {
            form: {
                fields: {
                  locator_type: {
                    value: "u1",
                    error: null,
                    //options: [remap("d1", "Locator-D1"), remap("a1", "Locator-A1"), remap("s1", "Locator-S1"), remap("u1", "Locator-U1"), remap("p1", "Locator-P1")],
                    options: [remap("d1", "Locator-D1"), remap("a1", "Locator-A1"), remap("u1", "Locator-U1"), remap("s2", "Locator-S2"), remap("p2", "Locator-P2")],
                    rule: [],
                    //rule: 'required',
                    needsExternalDepth: computed(() => {
                      return (this.form.fields.locator_type.value === "a1") || (this.form.fields.locator_type.value === "s2") || (this.form.fields.locator_type.value === "p2")
                  }),
                  },
                  channel: {
                    value: "2",
                    error: null,
                    options: [
                      //remap("13", "Bliss (13)"), remap("14", "Clarity (14)"), remap("15", "Destiny (15)")
                      //remap("16", "Fixed B (16)"), remap("17", "Fixed C (17)"), remap("18", "Fixed D (18)")
                      //remap("16", "16"), remap("17", "17"), remap("18", "18")
                      remap("5014", "1"), remap("5015", "2"), remap("5016", "3"), remap("5017", "4"),
                      remap("5018", "5"), remap("5019", "6"), remap("5020", "7")

                      // TODO: Channels added for testing, remove before release
                      //remap("4014", "4014"), remap("4015", "4015")
                      //remap("4016", "4016"), remap("4017", "4017"), remap("4018", "4018")
                      //remap("4019", "4019"), remap("4020", "4020")
                    ],
                    rule: [],
                  },
                  vehicle_imu: {
                    value: "disabled",
                    error: null,
                    options: [remap("enabled", "Enabled"), remap("disabled", "Disabled")],
                    rule: [],
                    hasFeature: false,
                  },
                  gps: {
                    value: "onboard",
                    options: [remap("onboard", "On-board"), remap("static", "Static"), remap("external", "External")],
                    error: null,
                    rule: [],
                  },
                  compass: {
                    value: "onboard",
                    options: [remap("onboard", "On-board"), remap("static", "Static"), remap("external", "External")],
                    error: null,
                    rule: [],
                  },
                  environment: {
                    value: "reflective",
                    options: [remap("reflective", "Reflective"), remap("openwater", "Open water")],
                    error: null,
                    rule: [],
                  },
                  static_lat: {
                    value: 63.71,
                    options: [],
                    error: null,
                    rule: [rules.between(-90, 90)],
                    //rule: 'required|numeric|between:-90,90'
                  },
                  static_lon: {
                    value: 10.22,
                    options: [],
                    error: null,
                    rule: [rules.between(-180, 180)],
                    //rule: 'required|numeric|between:-180,180'
                  },
                  static_orientation: {
                    value: 42,
                    options: [],
                    error: null,
                    rule: [rules.between(0, 360)],
                    //rule: 'required|numeric|between:0,360'
                  },
                  speed_of_sound: {
                    value: "1475",
                    options: [remap("1475", "1475 m/s"), remap("1500", "Custom")],
                    error: null,
                    rule: [rules.between(1000, 2000)],
                  },
                  external_pps: {
                    value: "disabled",
                    error: null,
                    options: [remap("enabled", "Enabled"), remap("disabled", "Disabled")],
                    rule: [],
                    hasFeature: false,
                  },
                  antenna: {
                    value: "enabled",
                    error: null,
                    options: [remap("enabled", "Enabled"), remap("disabled", "Disabled")],
                    rule: [],
                    hasFeature: false,
                  },
                },
                meta: {
                  isValid: true,
                  error: null,
                },
            },
            onFieldChangeWrapper: action((field, value) => {
              this.onFieldChange(field, value)
              if (field === "locator_type") {
                this.form.fields.channel.options = channelSupported[value]
                this.form.fields.channel.value = this.form.fields.channel.options[3].value
              }
            }),
        })
    }
}

export { ConfigStore }
