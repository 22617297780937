import { extendObservable, computed, observable, action } from 'mobx'
import { GenericFormStore } from './FormStore'
import { convexHull, polygonArea } from '../../Components/Map/geometry'
import { rules } from '../../lib/validator'

class ReceiverFormStore extends GenericFormStore {
    constructor(id, x, y, z, unitName, unitFactor, unitDecimals) {
        super()
        extendObservable(this, {
            id: id,
            form: {
                fields: {
                    x: {
                        value: Math.abs(x),
                        negated: x < 0,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(-100, 100)],
                    },
                    y: {
                        value: Math.abs(y),
                        negated: y < 0,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(-100, 100)],
                    },
                    z: {
                        value: z,
                        negated: false,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(0, 100)],
                    },
                },
                meta: {
                  isValid: true,
                  error: null,
                  unitName: unitName,
                  unitFactor: unitFactor,
                  unitDecimals: unitDecimals,
                },
              },
        })
    }
}


class ReceiverCollectionFormStore {
    constructor() {
        extendObservable(this, {
            receiverRegistry: observable.map(),
            antenna: true,
            count: computed(() => this.receiverRegistry.size),
            clear: action(() => {
                this.receiverRegistry.clear()
            }),
            addReceiver: action((id, x, y, z, unitName, unitFactor, unitDecimals) => {
                this.receiverRegistry.set(id, new ReceiverFormStore(id, x, y, z, unitName, unitFactor, unitDecimals))
            }),
            get: (id) => { return this.receiverRegistry.get(id) },
            all: computed(() => this.receiverRegistry.values()),
            receiverArea: computed(() => this.getReceiverArea()),
            getReceiverArea: (convFunc) => {
                if (this.count < 3) {
                    return 1000 // Accurate approximation to 5 decimals ;-)
                }
                const points = this.all.map(d => {
                    let val = d.getFlattenedAbsoluteValues(convFunc)
                    return [val.x, val.y]
                })
                let hull = convexHull(points)
                return Math.abs(polygonArea(hull))
            },
            isValid: computed(() => {
                // Check that all receiver forms are valid
                return this.all.every(d => d.form.meta.isValid)
            })
        });
    }
}

export { ReceiverFormStore, ReceiverCollectionFormStore }
