import React, { Component } from 'react';
import { Segment, Input, Grid, Button } from 'semantic-ui-react'
import '../../node_modules/react-leaflet-fullscreen/dist/styles.css'
import './Map.css'
import { observer, inject } from 'mobx-react'
import { AcousticView, MapView } from '../Components/Map/View'
import { PointOfInterest } from '../Components/PointOfInterest';
import { RoundDecimalPlaces } from '../lib/format';
import MapStateStore from './Map.store'
import ErrorMessage from '../Components/ErrorMessage'
import ErrorBoundary from '../Components/ErrorBoundary'

let mapStateStore = new MapStateStore()

const CreateNewPOI = inject("pointOfInterestStore")(inject("globalPositionStore")(inject("acousticPositionStore")(observer(class CreateNewPOI extends Component {
    handleClick = (e) => {
        const { lat, lng } = this.props.globalPositionStore
        const depth = RoundDecimalPlaces(this.props.acousticPositionStore.z, 2)
        const now = new Date()
        const name = "Location at " + now.toLocaleString()
        this.props.pointOfInterestStore.addPersistent(lat, lng, depth, name)
        mapStateStore.setPoiExpanded(true)
    }
    render() {
        return(
            <Button type="button"
            icon='marker'
            content='Create POI'
            title='Click to register current position'
            compact onClick={this.handleClick}
            />
        )
    }
}))))

const CreateEditNewPOI = inject("pointOfInterestStore")(inject("globalPositionStore")(observer(class CreateEditNewPOI extends Component {
    handleClick = (e) => {
        const { lat, lng } = this.props.globalPositionStore
        this.props.pointOfInterestStore.addPersistent(lat, lng, 0, null, null, true)
        mapStateStore.setPoiExpanded(true)
    }
    render() {
        return(
            <Button type="button"
            icon='marker'
            content='Create and edit new POI'
            title='Click to register a new Point Of Interest'
            compact onClick={this.handleClick}
            />
        )
    }
})))

const MapComponent = inject("pointOfInterestStore") (observer(class MapComponent extends Component {
    onSelectMap = () => {
        mapStateStore.setShowMap(true)
    }
    onSelectRelative = () => {
        mapStateStore.setShowMap(false)
    }
    onTrailChange = (e, data) => {
        mapStateStore.setTrailLength(data.value)
    }
    render() {
        return (
            <div>
            <Segment.Group>
            <Segment>
                <ErrorBoundary>
                {mapStateStore.showMap ? (
                        <MapView {...this.props} onMapChange={this.onMapChange} trailLength={mapStateStore.trailValue}/>
                    ) : (
                        <AcousticView {...this.props} onMapChange={this.onMapChange} trailLength={mapStateStore.trailValue}/>
                )}
                </ErrorBoundary>
            </Segment>
            <Segment>
            <Grid columns={2} stackable verticalAlign='middle'>
                <Grid.Column width={6}>
                    <Button.Group>
                        <Button positive={!mapStateStore.showMap} onClick={this.onSelectRelative}>Relative position</Button>
                        <Button positive={mapStateStore.showMap} onClick={this.onSelectMap}>Map position</Button>
                    </Button.Group>
                </Grid.Column>
                <Grid.Column width={4}>
                    <Input
                        style={{width: "5em"}}
                        size="small"
                        type="number"
                        label={{basic: true, content: "Trail length (sec)"}}
                        labelPosition= "left"
                        onChange={this.onTrailChange}
                        value={mapStateStore.trailLength}
                        className={mapStateStore.trailValueError ? "error" : ""}
                        title={"Change the length of the trail behind the marker"}
                    />
                </Grid.Column>
                <Grid.Column width={6}>
                    {mapStateStore.showMap && (
                        <CreateNewPOI/>
                    )}
                    {mapStateStore.showMap && (
                        <CreateEditNewPOI/>
                    )}
                </Grid.Column>
            </Grid>
            {this.props.pointOfInterestStore.errorAddMsg &&
                <ErrorMessage heading="Unable to add new POI" message={this.props.pointOfInterestStore.errorAddMsg}/>
            }
            </Segment>
            </Segment.Group>
            {mapStateStore.showMap && (
                <Segment>
                    <PointOfInterest mapStateStore={mapStateStore}/>
                </Segment>
            )}
            </div>
        )
    }
}))

export default MapComponent
