import React, { Component } from 'react';
import { Icon, Message, List, Label, Button, Popup } from 'semantic-ui-react'
import { observer, inject } from 'mobx-react';

const IconWithTitle = ({name, color, title}) => (
    <span style={{color: color}}>
    <Popup
            trigger={<Icon name={name}/>}
            content={title}
            on={["hover", "click"]}
        />
    </span>
)

const colorOK = "#16a04f"
//const colorWarn = "#f1c40f"
const colorWarn = "#eaae00"

const VersionOK = () => (
    <IconWithTitle name="check" color={colorOK}
        title={"Your system is up to date with the latest software."}
    />
)

const VersionNeedsUpdate = ({url, version}) => (
    <span>
        <span style={{color: colorWarn}}><Icon name="exclamation"/>
        New version available ({version}) </span> <a href={url} target="_new"><Button positive size="small">Download</Button></a>
    </span>
)

const VersionLoadError = ({error}) => (
    <IconWithTitle name="question" color={colorWarn}
        title={`Unable to contact update server. Please connect your computer to internet to check if there is a new update ready for your Underwater GPS. (${error})`}
    />
)

const UpdateAvailableIcon = inject("aboutInfoStore")(observer(class UpdateAvailableIcon extends Component  {
    render() {
        const { updateAvailable, latestRelease } = this.props.aboutInfoStore.versionStore
        if (updateAvailable) {
            return (
            <IconWithTitle name="exclamation" color={colorWarn}
            title={"New software update available (" + latestRelease + "). Go to 'About' to download latest version."}/>
            )
        }
        return (<span/>)
    }
}))

const VersionStatusIconView = inject("aboutInfoStore")(observer(class VersionStatusIconView extends Component  {
    render() {
        const { loading, error, updateAvailable, latestRelease, url } = this.props.aboutInfoStore.versionStore
        if (loading) {
            return (<span title="Checking version"><Icon loading name="spinner"/></span>)
        } else if (error) {
            return (<VersionLoadError error={error} />)
        }
        if (updateAvailable) {
            return (<VersionNeedsUpdate version={latestRelease} url={url}/>)
        }
        return (<VersionOK/>)
    }
}))

const VersionDetail = ({version, versionShort, chipid, updateURL}) => (
    <List relaxed>
        <List.Item>
            <List.Icon name="tag" size="large" verticalAlign="middle"/>
            <List.Content>
                <List.Header>Software version</List.Header>
                <List.Description><Label>{version} </Label> <VersionStatusIconView chipid={chipid} version={versionShort}/>
                <p style={{paddingTop: "0.5em"}}>
                    <a href={updateURL}>
                        <Button content='Software upgrade' color="teal" size="mini" icon='right arrow' labelPosition='left' />
                    </a>
                </p>
                </List.Description>
            </List.Content>
        </List.Item>
        <List.Item>
            <List.Icon name="microchip" size="large" verticalAlign="middle"/>
            <List.Content>
                <List.Header>Chip ID</List.Header>
                <List.Content><Label>{chipid}</Label></List.Content>
            </List.Content>
        </List.Item>
    </List>
)

const LoadingMessage = () => (
    <Message icon>
      <Icon name='circle notched' loading />
      <Message.Content>
        <Message.Header>Just a second</Message.Header>
        We are fetching the content for you.
      </Message.Content>
    </Message>
)

const ErrorMessage = (props) => (
    <Message icon>
      <Icon name='exclamation' />
      <Message.Content>
        <Message.Header>Failed to load content</Message.Header>
        {props.children}
      </Message.Content>
    </Message>
)
const VersionInfoView = inject("aboutInfoStore")(observer(class VersionInfoView extends Component  {
    render() {
        const { loading, error, version, versionShort, chipid } = this.props.aboutInfoStore
        const proto = window.location.protocol // http or https
        const host = window.location.host.split(":")[0] // Remove any port (if present)
        let updateURL = `${proto}//${host}:9000`
        if (loading) {
            return (<LoadingMessage/>)
        } else if (error) {
            return (<ErrorMessage>{error}</ErrorMessage>)
        }
        return (<VersionDetail version={version} versionShort={versionShort} chipid={chipid} updateURL={updateURL}/>)
    }
}))

export { VersionInfoView, UpdateAvailableIcon }
