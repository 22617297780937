import React, { Component } from 'react';
import { Table } from 'semantic-ui-react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types';
import { formatDecimals } from '../lib/format';
import { HelpPopUp } from '../Components/HeplText'


const no_value = -1000

function formatD(val, decimals) {
    if (val === no_value || val === "-" ) {
        return "-"
    }
    return formatDecimals(val, decimals)
}

function ShowRow({first, second, unit=""}) {
    return (
        <Table.Row>
            <Table.Cell>{first}</Table.Cell>
            <Table.Cell textAlign="right"><strong>{second}</strong> {unit}</Table.Cell>
        </Table.Row>
    )
}


class GPSDetailsTable extends Component {
    render() {
        let {lat, lng, cog, sog, numsats, hdop, orientation} = this.props
        if (lat <= no_value) { lat = '-' }
        if (lng <= no_value) { lng = '-' }
        if (cog < 0) { cog = '-' }
        if (sog < 0) { sog = '-' }
        if (numsats < 0) { numsats = '-' }
        if (hdop < 0) { hdop = '-' }
        if (orientation < 0) { orientation = '-' }
        return (
            <Table basic="very">
            <Table.Body>
                <ShowRow first="Latitude" second={formatD(lat, 8)}/>
                <ShowRow first="Longitude" second={formatD(lng, 8)}/>
                <ShowRow first="Course over ground" second={formatD(cog, 2)} unit="deg"/>
                <ShowRow first="Speed over ground" second={formatD(sog, 2)} unit="m/s"/>
                <ShowRow first="Heading" second={formatD(orientation, 1)} unit="deg"/>
            </Table.Body>
            </Table>
        )
    }
}

GPSDetailsTable.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    cog: PropTypes.number.isRequired,
    hdop: PropTypes.number.isRequired,
    numsats: PropTypes.number.isRequired,
    sog: PropTypes.number.isRequired,
    orientation: PropTypes.number.isRequired,
}

const GPSDetailsMaster = inject("masterPositionStore")(observer(class GPSDetailsM extends Component {
    render() {
        if (this.props.masterPositionStore.loadingError) {
            return <GPSDetailsTable
                lat={no_value}
                lng={no_value}
                cog={no_value}
                sog={no_value}
                numsats={no_value}
                hdop={no_value}
                orientation={no_value}
            />
        }
        return ( <GPSDetailsTable {...this.props.masterPositionStore}/> )
    }
}))


class LocatorGPSDetails extends Component {
    render() {
        let {lat, lng, orientation} = this.props
        if (lat <= no_value) { lat = '-' }
        if (lng <= no_value) { lng = '-' }
        if (orientation < 0) { orientation = '-' }
        return (
            <Table basic="very">
            <Table.Body>
                <ShowRow first="Latitude" second={formatD(lat, 8)}/>
                <ShowRow first="Longitude" second={formatD(lng, 8)}/>
                <ShowRow first="Heading" second={formatD(orientation, 1)}
                    unit={<span>deg <HelpPopUp text="Locator does not have sensor for heading, if the ROV supports sending heading it will be shown here"/></span>}/>
            </Table.Body>
            </Table>
        )
    }
}

LocatorGPSDetails.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    cog: PropTypes.number.isRequired,
    orientation: PropTypes.number.isRequired,
}


const GPSDetailsLocator = inject("globalPositionStore")(observer(class GPSDetailsG extends Component {
    render() {
        if (this.props.globalPositionStore.loadingError) {
            return <LocatorGPSDetails
                lat={no_value}
                lng={no_value}
                cog={no_value}
                sog={no_value}
                numsats={no_value}
                hdop={no_value}
                orientation={no_value}
            />
        }
        return ( <LocatorGPSDetails {...this.props.globalPositionStore}/> )
    }
}))

export { GPSDetailsMaster, GPSDetailsLocator, GPSDetailsTable };
