import React from 'react'
import { Icon } from 'semantic-ui-react'

const DescribeForwardBackward = ({val}) => (
    <span><Icon name={"arrow " + (val > 0 ? "up" : "down")}/>&nbsp;{val > 0 ? "Forward" : "Backward"}</span>
)

const DescribeLeftRight = ({val}) => (
    <span><Icon name={"arrow " + (val > 0 ? "right" : "left")}/>&nbsp;{val > 0 ? "Right" : "Left"}</span>
)

export { DescribeForwardBackward, DescribeLeftRight }