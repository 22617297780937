import React, { useState } from 'react';
import { Icon, Message, Popup, Button, ButtonGroup, Form, TextArea, Header } from 'semantic-ui-react'

function SelectDuration({duration, onSelectDuration}) {
    return (
      <Popup
        flowing
        on="click"
        trigger={<span>{duration}</span>}
        content={
          <ButtonGroup>
          <Button onClick={() => {onSelectDuration(15)}}>15 second</Button>
          <Button onClick={() => {onSelectDuration(30)}}>30 second</Button>
          <Button onClick={() => {onSelectDuration(60)}}>1 min</Button>
          <Button onClick={() => {onSelectDuration(60*5)}}>5 min</Button>
          </ButtonGroup>
        }
      />
    )
  }

const defaultCollectionTime = 15;

function DiagnosticReport() {
    const [description, setDescription] = useState("")
    const [duration, setDuration] = useState(defaultCollectionTime)

    const onChangeDescription = (e) => {
        setDescription(e.target.value)
    }

    return (
        <div>
        <Message info icon>
            <Icon name="eye"/>
            <Message.Content>
                <Message.Header>
                Generate diagnostic report for analysis by Water Linked support
                </Message.Header>
                <div>
                <p>Please make sure the Receivers and Locator is in water and showing the behaviour you want assistance with.</p>
                <div>Clicking the button below initiates a &nbsp;
                  <SelectDuration duration={duration} onSelectDuration={setDuration}/>
                  &nbsp;second data capture to share with the support team.
                </div>
                </div>
            </Message.Content>
        </Message>

        <Form action={"/api/collect"}>
        <input type="hidden" name="desc" value={description}/>
        {duration !== defaultCollectionTime && (<input type="hidden" name="t" value={duration}/>)}
        <Header as="h5">Describe your setup and what you you are experiencing</Header>
        <Form.Field>
        <TextArea
            placeholder="Type details here"
            value={description}
            onInput={onChangeDescription}
            rows={4}
        />
        </Form.Field>
        <Form.Field>
        <Button disabled={!description}>Generate and download report</Button>
        </Form.Field>

        <p>Please visit <a href="https://waterlinked.com">waterlinked.com</a> for up to date contact details.</p>

        </Form>
        </div>
    )
}

export default DiagnosticReport
