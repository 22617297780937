import { extendObservable, action, observable, computed } from 'mobx'
import { PointOfInterestItemFormStore } from './FormStores/PointOfInteres'
import { superFetch, superFetchHeaders } from '../lib/fetch'

class PointsOfInterestStore {
    constructor() {
        extendObservable(this, {
            points: observable.map(),
            add: action((id, lat, lng, depth, name=null, icon=null) => {
                this.points.set(id, new PointOfInterestItemFormStore(id, lat, lng, depth, name, icon))
            }),
            delete: action((id) => {
                this.points.delete(id)
            }),
            count: computed(() => this.points.size),
            get: (id) => { return this.points.get(id) },
            getVisible: computed(() => this.points.values().filter(d => d.form.fields.visible.value)),
            all: computed(() => this.points.values()),
            newestFirst: computed(() => this.all.reverse()),
            isFetching: false,
            errorFetchMsg: "",
            errorAddMsg: "",
            getHref: (id) => {
                return '/api/v1/poi/'+id
            },
            addPersistent: action((lat, lng, depth, name=null, icon=null, startEdit=false) => {
                const _name = name || "POI " + (this.count+1)
                const _icon = icon || "anchor"
                let data = {
                    "name": _name,
                    "lat": lat,
                    "lng": lng,
                    "depth": depth,
                    "icon": _icon,
                    "visible": true
                }
                this.errorAddMsg = ""
                return superFetchHeaders(this.getHref(""), 'POST', data)
                    .then(response => {
                        if (response.status === 201) {
                            return superFetchHeaders(response.headers.get('Location'))
                        }
                        else if (response.status === 403) {
                            throw Error(
                                "Unable to add POI. You have exceeded maximum number of POIs." +
                                "Please delete a point before adding a new."
                                )
                        }
                        else {
                            throw Error("Unable to add POI: " + response.statusText)
                        }
                    })
                    .then (response => {
                        if (response.status !== 200){
                            throw Error("Unable to fetch newly added POI: " + response.statusText)
                        }
                        return response
                    })
                    .then(response => { return response.json() })
                    .then(data => {
                        this.add(data.id, data.lat, data.lng, data.depth, data.name, data.icon)
                        if (startEdit) {
                            this.get(data.id).isEditing = true
                        }
                        this.errorAddMsg = ""
                    })
                    .catch(err => {
                        this.errorAddMsg = err.toString()
                    })
            }),
            updatePersistent: action((id) => {
                let point = this.get(id)
                point.isLoading = true
                point.errorUpdate = ""
                return superFetchHeaders(this.getHref(id), 'PATCH', point.json)
                .then(response => {
                    if (response.status !== 204) {
                        throw Error("Unable to update POI: " + response.statusText)
                    }
                    point.isEditing = false
                    point.isLoading = false
                    point.errorUpdate = ""
                })
                .catch(err => {
                    point.isEditing = true
                    point.isLoading = false
                    point.errorUpdate = err.toString()
                    setTimeout(() => {
                        point.errorUpdate = ""
                    }, 3000)
                })
            }),
            deletePersistent: action((id) => {
                let point = this.get(id)
                point.isLoading = true
                point.errorDelete = ""
                return superFetchHeaders(this.getHref(id), 'DELETE')
                .then(response => {
                    if (response.status !== 204) {
                        throw Error("Unable to delete POI: " + response.statusText)
                    }
                    this.delete(id)
                    point.isLoading = false
                    point.errorDelete = ""
                })
                .catch(err => {
                    point.isLoading = false
                    point.errorDelete = err.toString()
                    setTimeout(() => {
                        point.errorDelete = ""
                    }, 3000)
                })
            }),
            fetch: action(() => {
                this.isFetching = true
                this.errorFetchMsg = ""
                return superFetch(this.getHref(""))
                .then((data) => {
                    data.forEach(d => {
                        this.add(d.id, d.lat, d.lng, d.depth, d.name, d.icon)
                    })
                    this.errorFetchMsg = ""
                    this.isFetching = false
                })
                .catch(err => {
                    this.errorFetchMsg = err.toString()
                    this.isFetching = false
                })
        }),
        })
    }
}

export default PointsOfInterestStore

