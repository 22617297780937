import React, { useState } from 'react';
import { Button, Image } from 'semantic-ui-react'
import logo from '../waterlinked_logo.svg';
import './LEDButton.css'
import { withRouter } from "react-router-dom";
import useLongPress from './useLongpress'

const BlinkLED = () => {
    return fetch('/api/v1/about/led')
        .then(r => r.ok)
        .catch((e) => {
            console.log("Error: ", e.toString())
            return false
        })
}

function useLedBlink() {
    const [isActive, setIsActive] = useState(false)
    const [isSubmitFail, setIsSubmitFail] = useState(false)
    const onClick = () => {
        if (isActive) { return }
        setIsActive(true)
        BlinkLED().then(ok => {
            if (ok) {
                console.log("success")
                setTimeout(() => {
                    setIsActive(false)
                }, 3000)
            } else {
                console.log("fail")
                setIsActive(false)
                setIsSubmitFail(true)
                setTimeout(() => {
                    setIsSubmitFail(false)
                }, 3000)
            }
        })
    }
    return [isActive, isSubmitFail, onClick]
}

const LEDButton = () => {
    const [isActive, isSubmitFail, onClick] = useLedBlink()

    return (
        <Button
            onClick={onClick}
            icon
            color={isSubmitFail ? "red" : (isActive ? "green" : null)}>
                Flash Led
        </Button>
    )

    /*
    return (
        <Icon onClick={onClick} name={"lightbulb outline"} color={isSubmitFail ? "red" : (isActive ? "green" : "grey")} inverted circular/>
    )
    */
}


const WaterLinkedLogoWithLedBlinkNav = ({history}) => {
    const [isActive, isSubmitFail, blinkLed] = useLedBlink()
    const border = isActive ? "ledactive" : (isSubmitFail ? "lederror" : "ledborder")

    const onLongPress = () => {
        history.push("/debug")
    };

    const onClick = () => {
        blinkLed();
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

    /*
    return (
        <img
            className={border + " ui"}
            //style={{width: '10em'}}
            src={logo}
            //style={{marginRight: '1.5em'}}
            {...longPressEvent}
        />
    )
*/

    return (
        <div style={{paddingTop: "0.5em"}}>
            <Image
                wrapped
                className={border}
                size='small'
                src={logo}
                style={{marginRight: '1.5em'}}
                {...longPressEvent}
            />
        </div>
    )
/*
*/
}

const WaterLinkedLogoWithLedBlink = withRouter(WaterLinkedLogoWithLedBlinkNav)

export{ LEDButton, BlinkLED, WaterLinkedLogoWithLedBlink }
