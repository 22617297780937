import { extendObservable, computed, observable, action } from 'mobx'
import { superFetch } from '../lib/fetch'

class ReceiverModel {
    constructor(id, x, y, z) {
        extendObservable(this, {
            id: id,
            x: x,
            y: y,
            z: z,
            setX: action((x) => { this.x = x }),
            setY: action((y) => { this.y = y }),
            setZ: action((z) => { this.z = z }),
            setXY: action((x, y) => { this.x = x; this.y = y; }),
            setXYZ: action((x, y, z) => { this.x = x; this.y = y; this.z = z }),
            getX: computed(() => { return parseFloat(this.x)}),
            getY: computed(() => { return parseFloat(this.y)}),
            getZ: computed(() => { return parseFloat(this.z)}),
            isValid: computed(() => {
                let x = parseFloat(this.x);
                let y = parseFloat(this.y);
                let z = parseFloat(this.z);
                return (!isNaN(x + y + z))
            }),
        });
    }
}

class ReceiverStore {
    constructor() {
        extendObservable(this, {
            hasLoaded: false,
            isLoading: false,
            isStoring: false,
            receiverRegistry: observable.map(),
            count: computed(() => this.receiverRegistry.size),
            clear: action(() => {
                this.receiverRegistry.clear()
            }),
            addReceiver: action((id, x,y,z) => {
                this.receiverRegistry.set(id, new ReceiverModel(id, x,y,z))
            }),
            get: (id) => { return this.receiverRegistry.get(id) },
            all: computed(() => this.receiverRegistry.values()),
        });
    }
    fetch() {
        this.isLoading = true
        return superFetch('/api/v1/config/receivers/')
        .then((data) => {
            this.hasLoaded = true
            this.isLoading = false
            this.loadingError = ""
            this.receiverRegistry.clear()
            data.forEach(d => {
                let model = new ReceiverModel(d.id, d.x, d.y, d.z)
                this.receiverRegistry.set(d.id, model)
            })
        })
        .catch((e) => {
            this.isLoading = false
            this.loadingError = e.toString()
        })
    }
    store() {
        this.isStoring = true
        let all = this.receiverRegistry.values().map(rx => {
            if (!rx.isValid) {
                throw Error("Invalid", rx)
            }
            let data = { id: rx.id, x: rx.getX, y: rx.getY, z: rx.getZ }
            console.log("Storing receiver", rx.id, "data", data)
            let url = `/api/v1/config/receivers/${rx.id}`
            var headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              }
            return fetch(url, {
                method: 'PUT',
                headers: headers,
                body: JSON.stringify(data)
            }).then(response => {
                if (response.status !== 204) {
                    throw Error("Unable to store")
                }
            })
        })
        return Promise.all(all)
            .then(response => {
                this.isStoring = false;
                return response
             }).catch(e => {
                 this.isStoring = false;
                 throw e
             })
    }

}

export { ReceiverStore }
