import { extendObservable, computed, action } from "mobx"
import { superFetch } from '../lib/fetch'

const MaxRangeDistance = 100

class RangeStore {
    constructor() {
        extendObservable(this, {
            isLoading: false,
            radius: parseInt(process.env.REACT_APP_UGPS_MAX_RANGE),
            direction: 340,
            sector: 90,
            getCircularRanges: action(() => {
                return {x: {min: -this.radius, max: this.radius}, y: {min: -this.radius, max: this.radius}}
            }),
        });
    }
}


class GenericConfigStore {
    constructor(min, max) {
        extendObservable(this, {
            hasLoaded: false,
            isLoading: false,
            loadingError: "",
            isStoring: false,
            storingError: "",
            locator_type: "u1",
            channel: 5017,
            environment: "reflective",
            vehicle_imu: "disabled",
            external_pps: "disabled",
            antenna: "enabled",
            compass: "static",
            gps: "static",
            static_lat: 63.422,
            static_lon: 10.424,
            static_orientation: 42,
            speed_of_sound: "1475",
            ranges: new RangeStore(),
            isOnboardGPS: computed(() => this.gps === "onboard"),
            isOnboardIMU: computed(() => this.compass === "onboard"),
            fetch: ()  => {
                this.isLoading = true
                return superFetch('/api/v1/config/generic')
                .then((data) => {
                    this.hasLoaded = true
                    this.fromTransportArray(data)
                    this.isLoading = false
                    this.loadingError = ""
                })
                .catch((e) => {
                    this.isLoading = false
                    this.loadingError = e.toString()
                })
            },
            fromTransportArray: (data) => {
                this.locator_type = data.locator_type
                this.channel = data.channel
                this.compass = data.compass
                this.static_orientation = data.static_orientation
                this.gps = data.gps
                this.static_lat = data.static_lat
                this.static_lon = data.static_lon
                this.speed_of_sound = data.speed_of_sound.toString()
                this.vehicle_imu = data.imu_vehicle_enabled ? "enabled" : "disabled"
                this.external_pps = data.external_pps_enabled ? "enabled" : "disabled"
                this.antenna = data.antenna_enabled ? "enabled" : "disabled"
                this.environment = data.environment
                this.ranges.radius = data.search_radius
                this.ranges.direction = data.search_direction
                this.ranges.sector = data.search_sector
            },
            toTransportArray: () => {
                return {
                    locator_type: this.locator_type,
                    channel: this.channel,
                    imu_vehicle_enabled: (this.vehicle_imu === "enabled"),
                    external_pps_enabled: (this.external_pps === "enabled"),
                    antenna_enabled: (this.antenna === "enabled"),
                    environment: this.environment,
                    gps: this.gps,
                    compass: this.compass,
                    static_lat: this.static_lat,
                    static_lon: this.static_lon,
                    speed_of_sound: parseInt(this.speed_of_sound),
                    static_orientation: this.static_orientation,
                    search_radius: this.ranges.radius,
                    search_direction: this.ranges.direction,
                    search_sector: this.ranges.sector,
                    // legacy
                    range_min_x: 0,
                    range_min_y: 0,
                    range_max_x: 0,
                    range_max_y: 0,
                    range_max_z: 0,
                }
            },
            store: () => {
                this.isStoring = true
                let data = this.toTransportArray()
                var headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                  }
                return fetch('/api/v1/config/generic', {
                    method: 'PUT',
                    headers: headers,
                    body: JSON.stringify(data)
                })
                .then(response => {
                  if (!response.ok) {
                    throw Error(response.statusText)
                  }
                  return response
                })
                .then(response => response.text())
                .then(response => {
                    console.log("stored", response)
                    this.isStoring = false;
                    this.storeError = "";
                    return response
                })
                .catch((e) => {
                    console.log("store error", e.toString())
                    this.isStoring = false;
                    this.storeError = e.toString()
                    throw e;
                })
            },
        })
    }
}

export { RangeStore, GenericConfigStore, MaxRangeDistance }
