function TimeoutError() {}
TimeoutError.prototype = new Error()

function ensureStoreHasLoaded(store, timeout=2000, pollInterval=50) {
    return new Promise(function (resolve, reject) {
        (function waitForStore(_timeout){
            if (_timeout < 1) return reject(new TimeoutError())
            if (store.hasLoaded) return resolve();
            setTimeout(waitForStore.bind(null, _timeout-1), pollInterval);
        })(timeout / pollInterval);
    });
}

export { ensureStoreHasLoaded, TimeoutError }
