import { extendObservable } from 'mobx'
import { GenericFormStore } from './FormStore'
import { rules } from '../../lib/validator'

class WifiConfigFormStore extends GenericFormStore {
    constructor() {
        super()
        extendObservable(this, {
            form: {
                fields: {
                    ssid: {
                        value: "WiFi name",
                        error: null,
                        rule:[rules.string()],
                    },
                    password: {
                        value: "Password",
                        error: null,
                        rule: [rules.string()],
                    },
                    mode: {
                        value: "ap",
                        error: null,
                        rule: [rules.string()],
                    },
                },
                meta: {
                  isValid: true,
                  error: null,
                },
            },
            error: "",
        })
    }
}


export { WifiConfigFormStore }
