import React, { useState } from 'react';
import { Icon, Popup, Button } from 'semantic-ui-react'
import axios from "axios";

const FactoryResetButton = () => {
    const [isActive, setIsActive] = useState(false)
    const [isSubmitFail, setIsSubmitFail] = useState(false)

    const onClick = () => {
        if (isActive) { return }
        setIsSubmitFail(false)
        setIsActive(true)
        axios.post("/api/v1/about/factoryreset").then(() => {
            // Resetting
            setTimeout(() => {
                setIsActive(false)
            }, 1000)
        }).catch((e) => {
            console.log("Error", e.response.data.error)
            setIsActive(false)
            setIsSubmitFail(true)
        })
    }

    return (
        <Popup
            trigger={
                <Icon
                    name='cog'
                    circular
                    inverted
                    color="grey"
                    title='Factory reset to defaults'
                />
            }
            content={
                <div>
                    Warning: All settings including network settings, and points of interest will be lost. Are you sure? Actions will apply after reboot of system.
                <Button
                    color={isSubmitFail ? "red" : (isActive ? "green" : "grey")}
                    onClick={onClick}
                    name={"redo"}
                    content="Confirm factory defaults"
                />
                </div>
            }
            on='click'
            position='right center'
        />
        )
}

export{ FactoryResetButton }
