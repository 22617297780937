import { superFetch, superFetchHeaders } from '../lib/fetch'
import { extendObservable, action, computed } from 'mobx';

class VersionStore {
    constructor() {
        extendObservable(this, {
            loading: true,
            error: "",
            hasChecked: false,
            isLatest: true,
            latestRelease: "",
            url: "",
            updateAvailable: computed(() => {
                return this.hasChecked && !this.isLatest
            }),
            fetch: action((chipid, version) => {
                //const baseUrl = "http://127.0.0.1:8080"
                const baseUrl = "https://update.waterlinked.com"
                return superFetchHeaders(`${baseUrl}/api/v1/latest`, "POST", {
                    chipid: chipid,
                    current: version,
                })
                .then(response => {
                    if (!response.ok) {
                      throw Error(response.statusText)
                    }
                    return response
                })
                .then(response => { return response.json() })
                .then(data => {
                    var url = data.url
                    if (url[0] === "/") {
                        url = baseUrl + url
                    }
                    this.isLatest = data.is_latest
                    this.latestRelease = data.latest_release
                    this.url = url
                    this.loading = false
                    this.error = ""
                    this.hasChecked = true
                }).catch(err => {
                    this.loading = false
                    this.error = "Request failed " + err.toString()
                    this.hasChecked = true
                })
            })
        })
    }
}

class AboutInfoStore {
    constructor() {
        extendObservable(this, {
            loading: true,
            error: "",
            version: "unknown",
            versionShort: "unknown",
            hasVersionShort: computed(() => {
                return this.versionShort !== "unknown"
            }),
            chipid: "unknown",
            versionStore: new VersionStore(),
            fetch: action(() => {
                this.loading = true
                return superFetch('/api/v1/about')
                .then(data => {
                    this.chipid = data["chipid"]
                    this.version = data["version"]
                    this.versionShort = data["version_short"]
                    this.loading = false
                    this.error = ""
                    return this.versionStore.fetch(this.chipid, this.versionShort)
                })
                .catch(err => {
                this.loading = false
                this.error = "Request failed " + err.toString()
                })
            })
        })
    }
}

export { AboutInfoStore, VersionStore }