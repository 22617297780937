import { extendObservable } from 'mobx'
import { superFetch } from '../lib/fetch'


class TemperatureStore {
    constructor() {
        extendObservable(this, {
            isLoading: false,
            loadingError: "",
            board: -271,
            water: -271,
        })
    }
    fetch() {
        this.isLoading = true
        superFetch('/api/v1/about/temperature')
        .then((data) => {
            this.board = parseFloat(data.board)
            this.water = parseFloat(data.water)
        })
        .catch((e) => {
            this.isLoading = false
            this.loadingError = e.toString()
        })
    }
}

export default TemperatureStore
