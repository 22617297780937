import { extendObservable } from 'mobx'
import { NetworkConfigFormStore } from './FormStores/NetworkConfig'
import axios from 'axios'

class NetworkConfigStore {
    constructor() {
        extendObservable(this, {
            formStore: new NetworkConfigFormStore(),
            loadSuccess: false,
            isLoading: false,
            isStoring: false,
            storeSuccess: false,
            loadingError: "",
            storeError: "",
        });
    }
    fetch() {
        this.isLoading = true
        axios.get('/api/v1/config/ip')
        .then((response) => {
            this.loadSuccess = true
            this.isLoading = false
            this.loadingError = ""
            this.formStore.form.fields.address.value = response.data.address
            this.formStore.form.fields.prefix.value = response.data.prefix
            this.formStore.form.fields.gateway.value = response.data.gateway
            this.formStore.form.fields.dns.value = response.data.dns
            this.formStore.form.fields.dhcp.value = response.data.dhcp ? "true" : "false"
        })
        .catch((e) => {
            this.loadSuccess = false
            this.isLoading = false
            this.loadingError = e.toString()
            console.log(this.loadingError)
        })

    }
    store() {
        this.isStoring = true
        let data = {
            "address": this.formStore.form.fields.address.value,
            "prefix": parseInt(this.formStore.form.fields.prefix.value,10),
            "gateway": this.formStore.form.fields.gateway.value,
            "dns": this.formStore.form.fields.dns.value,
            "dhcp": this.formStore.form.fields.dhcp.value === "true",
        }
        axios.put('/api/v1/config/ip', data)
        .then(response => {
            if (!response.data.success) {
                // Get error message from "error" field of the JSON and pass on
                throw new Error(response)
            }
            return response
        })
        .then(response => {
            console.log("stored", response);
            this.storeSuccess = true;
            this.isStoring = false;
            this.storeError = "";
            return response
        })
        .catch((e) => {
            console.log("store error", e.response.data.detail.toString());
            this.isStoring = false;
            this.storeSuccess = false;
            this.storeError = e.response.data.detail.toString()
        })
    }
}

export default NetworkConfigStore
