import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { Icon, Table } from 'semantic-ui-react'
import { divIcon, latLng } from 'leaflet'
import { Marker, Popup, LayerGroup, Polygon } from 'react-leaflet'
import RotatedMarker from 'react-leaflet-rotatedmarker'
import { PropTypes } from 'prop-types'
import { FormInput } from '../../Stores/FormStores/FormStore'
import { polarToLeflet, lefletToPolar, offsetLatLonByDistanceAngle } from './geohelpers'
import { clamp, wrap360 } from '../../lib/helpers'
import { RoundDecimalPlaces } from '../../lib/format'
import { ShowUnit } from '../ShowUnit'

var dragRadius = divIcon({
    className: 'dragIcon',
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    html: '<i class="arrows alternate vertical large icon"/>',
})

var dragSector = divIcon({
    className: 'dragIcon',
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    html: '<i class="arrows alternate horizontal large icon"/>',
})

var dragDirection = divIcon({
    className: 'dragIcon',
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    html: '<i class="redo large icon"/>',
})

const origo = polarToLeflet(0,0)
const radiusDraggerOffset = 3/5

const DescribeDirection = () => (
    <span><Icon name={"compass outline"}/>&nbsp;{"Direction"}</span>
)
const DescribeRadius = () => (
    <span><Icon name={"resize horizontal"}/>&nbsp;{"Radius"}</span>
)
const DescribeSector = () => (
    <span><Icon name={"chevron down"}/>&nbsp;{"Sector"}</span>
)

function GenerateArcGlobal(center, startAngle, endAngle, radius) {
    var arc = []
    center = latLng(center)

    var i = startAngle
    if (startAngle > endAngle) {
        i -= 360
    }
    while (i < endAngle) {
        arc.push(latLng(offsetLatLonByDistanceAngle(center, i,radius)))
        // Degrees between each point on the curve
        i += 0.25
    }

    // Add end and center point as well
    arc.push(latLng(offsetLatLonByDistanceAngle(center,endAngle,radius)))
    arc.push(center)
    return arc
}
GenerateArcGlobal.propTypes = {
    center: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired
    }),
    startAngle: PropTypes.number.isRequired,
    endAngle: PropTypes.number.isRequired,
    radius: PropTypes.number.isRequired
}

function GenerateArc(startAngle, endAngle, radius) {
    var arc = []

    var i = startAngle
    if (startAngle > endAngle) {
        i -= 360
    }
    while (i < endAngle) {
        arc.push(polarToLeflet(i,radius))
        // Degrees between each point on the curve
        i += 0.25
    }

    // Add end and center point as well
    arc.push(polarToLeflet(endAngle,radius))
    arc.push(origo)
    return arc
}
GenerateArc.propTypes = {
    startAngle: PropTypes.number.isRequired,
    endAngle: PropTypes.number.isRequired,
    radius: PropTypes.number.isRequired
}

const SearchRangeArcGlobal = observer(class SearchRangeArcGlobal extends Component {
    render() {
        const { center, startAngle, endAngle, radius } = this.props
        var points = GenerateArcGlobal(center, startAngle, endAngle, radius)
        return (
            <LayerGroup>
                <Polygon positions={points} color="green"/>
            </LayerGroup>
        )
    }
})

const SearchRangeArc = observer(class SearchRangeArc extends Component {
    render() {
        const { startAngle, endAngle, radius } = this.props
        var points = GenerateArc(startAngle, endAngle, radius)
        return (
            <LayerGroup>
                <Polygon positions={points} color="green"/>
            </LayerGroup>
        )
    }
})

const CircularSearchAreaDraggable = observer(class CircularSearchAreaDraggable extends Component {
    onDragRadius = (e) => {
        let polar = lefletToPolar(e.target.getLatLng())

        // Limit radius to be within min and max range
        polar["distance"] = RoundDecimalPlaces(clamp(polar["distance"], 1, this.props.maxRadius), 0)

        // Lock direction
        const fields = this.props.store.getFlattenedAbsoluteMetricValues()
        e.target.setLatLng(polarToLeflet(fields.direction, polar["distance"]))

        // Update change
        this.props.onAbsoluteMetricChange("radius", polar["distance"], 1)
    }

    onDragDirection = (e) => {
        let polar = lefletToPolar(e.target.getLatLng())

        polar["angle"] = RoundDecimalPlaces(clamp(polar["angle"], 0, 360), 0)

        const fields = this.props.store.getFlattenedAbsoluteMetricValues()
        e.target.setLatLng(polarToLeflet(polar["angle"], fields.radius * radiusDraggerOffset))

        this.props.onFieldChange("direction", polar["angle"], 1)
    }

    onDragSector = (e) => {
        let polar = lefletToPolar(e.target.getLatLng())

        const fields = this.props.store.getFlattenedAbsoluteMetricValues()

        let sector = fields.sector

        // Check is dir is in left plane and sector in right
        if (polar["angle"] - fields.direction < 0) {
            sector = clamp(2 * (polar["angle"] - fields.direction + 360), 0.1, 359.99)
        } else {
            sector = clamp(2 * (polar["angle"] - fields.direction), 0.1, 359.99)
        }

        polar["angle"] = clamp(polar["angle"], fields.direction, fields.direction + 180)
        e.target.setLatLng(polarToLeflet(polar["angle"], fields.radius))

        sector = RoundDecimalPlaces(sector, 0)
        this.props.onFieldChange("sector", sector, 0)
    }
    render() {
        const { form,  unitName, maxRadius } = this.props.store
        const fieldsMetric = this.props.store.getFlattenedAbsoluteMetricValues()
        const fieldsRaw = this.props.store.getFlattenedAbsoluteValues()

        // Prevent bug when sector is 0
        const sector = clamp(fieldsRaw.sector, 0.1, 359.99)
        const direction = clamp(fieldsRaw.direction, 0, 360)
        const radius = clamp(fieldsMetric.radius, 0.1, maxRadius)

        const posRadius = polarToLeflet(direction, radius)
        const posDir = polarToLeflet(direction, radius * radiusDraggerOffset)
        const posSector = polarToLeflet((direction + (sector/2)), radius)
        const startAngle = wrap360(direction - (sector/2))
        const endAngle = wrap360(direction + (sector/2))

        const onFieldChange = this.props.onFieldChange

        return (
            <LayerGroup>
                <RotatedMarker icon={dragRadius} position={posRadius} rotationAngle={direction} rotationOrigin={"10px 10px"} draggable={true} onDrag={this.onDragRadius} zIndexOffset={100}>
                    <Popup>
                    <div>
                    <h5>Radius of search range <ShowUnit unit={unitName}/></h5>
                    <Table basic compact>
                        <Table.Header>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <DescribeRadius/>
                                </Table.Cell>
                                <Table.Cell>
                                    <FormInput
                                        type="number"
                                        step="1"
                                        name="radius"
                                        help="Radius of search sector"
                                        value={form.fields.radius.value}
                                        error={form.fields.radius.error}
                                        onChange={onFieldChange}/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    </div>
                    </Popup>
                </RotatedMarker>
                <Marker icon={dragDirection} position={posDir} draggable={true} onDrag={this.onDragDirection}>
                    <Popup>
                    <div>
                    <h5>Direction of search area <ShowUnit unit={"Degrees"}/></h5>
                    <Table basic compact>
                        <Table.Header>
                        </Table.Header>
                        <Table.Body>
                        <Table.Row>
                                <Table.Cell>
                                    <DescribeDirection/>
                                </Table.Cell>
                                <Table.Cell>
                                    <FormInput
                                        type="number"
                                        step="0.1"
                                        name="direction"
                                        help="Direction of search sector"
                                        value={form.fields.direction.value}
                                        error={form.fields.direction.error}
                                        onChange={onFieldChange}/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    </div>
                    </Popup>
                </Marker>
                <RotatedMarker icon={dragSector} position={posSector} rotationAngle={wrap360(direction+sector/2)} rotationOrigin={"10px 10px"} draggable={true} onDrag={this.onDragSector}>
                    <Popup>
                    <div>
                    <h5>Width of search sector <ShowUnit unit={"Degrees"}/></h5>
                    <Table basic compact>
                        <Table.Header>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <DescribeSector/>
                                </Table.Cell>
                                <Table.Cell>
                                    <FormInput
                                        type="number"
                                        step="1"
                                        name="sector"
                                        help="Sector of search area"
                                        value={form.fields.sector.value}
                                        error={form.fields.sector.error}
                                        onChange={onFieldChange}/>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    </div>
                    </Popup>
                </RotatedMarker>
                <SearchRangeArc center={origo} radius={radius} startAngle={startAngle} endAngle={endAngle}/>
            </LayerGroup>
        )
    }
})

export { CircularSearchAreaDraggable, SearchRangeArc, SearchRangeArcGlobal }
