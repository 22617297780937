import React, { Component } from 'react';
import { Button, Icon, Message } from 'semantic-ui-react'
import { PropTypes } from 'prop-types';

class ApplyFeedbackButton extends Component {
    render() {
        const { onClick, type, disabled, isStoring, storeSuccess, storeFailure } = this.props
        return (
            <div style={this.props.style}>
                <Button icon positive type={type} onClick={onClick} disabled={disabled}>
                Apply&nbsp;
                {isStoring && <Icon name="circle notched" loading/>}
                {storeSuccess && <Icon name="checkmark"/>}
                </Button>
                {storeFailure && (
                <Message warning><Message.Header>Failed to apply</Message.Header>
                    <p>{storeFailure}</p>
                </Message>
                )}
            </div>
        )
    }
}

ApplyFeedbackButton.propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    isStoring: PropTypes.bool.isRequired,
    storeSuccess: PropTypes.bool.isRequired,
    storeFailure: PropTypes.string.isRequired,
}

export default ApplyFeedbackButton;
