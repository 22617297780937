import { RoundDecimalPlaces } from '../lib/format'

var rules = {
    // Return true if valid, false if invalid
    between: (min, max) => {
        return function(value, convFactor, decimals) {
            const val = RoundDecimalPlaces(value / convFactor, decimals)
            return val <= max && val >=min
        }
    },
    numeric: () => {
        return function(val, convFactor, decimals) {
            return isNumeric(val)
        }
    },
    string: () => {
        return function(val, convFactor, decimals) {
            return (typeof val === 'string' || val instanceof String)
        }
    },
}

class ErrorList {
    constructor() {
        this.list = {}
    }
    add(key, data) {
        if (this.list[key] === undefined) {
            this.list[key] = []
        }
        this.list[key].push(data)
    }
    first = (field) => {
        if (this.list[field] === undefined) {
            return false
        }
        let err = this.list[field]
        return err ? err : null
    }
    count = () => {
        let all = Object.keys(this.list)
        let count = all.reduce((acc, cur) => acc + this.list[cur].length, 0)
        return count
    }
}

function isNumeric(n) {
    let num = parseFloat(n)
    return !isNaN(num) && isFinite(num);
}

class Validator {
    constructor(input, rules) {
        this.input = input
        this.rules = rules
        this.errors = new ErrorList()
    }
    passes = (convFactor=1) => {
        Object.keys(this.input).forEach(field => {
            let val = this.input[field]
            let rules = this.rules[field]
            if (!Array.isArray(rules)) {
                //return false
                throw new Error("Rules need to be an array")
            }
            rules.forEach(rule => {
                let isValid = rule(val, convFactor)
                if (!isValid) {
                    this.errors.add(field, "Invalid")
                }
            })
        });
        return this.errors.count() === 0
    }
}

export { Validator, rules }
