import { divIcon } from 'leaflet'

function addPOI(icon, description) {
    return {
        key: icon,
        icon: icon,
        value: description,
    }
}

var poiMapping = [
    addPOI('anchor', 'Anchor'),
    addPOI('crosshairs', 'Crosshairs'),
    addPOI('ship', 'Boat'),
    addPOI('plane', 'Plane'),
    addPOI('military', 'Plane'),
    addPOI('car', 'Car'),
    addPOI('bicycle', 'Bicycle'),
    addPOI('life ring', 'Life ring'),
    addPOI('pin', 'Pin'),
    addPOI('marker', 'Marker'),
    addPOI('map pin', 'Map pin'),
    addPOI('star', 'Star'),
    addPOI('heart', 'Heart'),
    addPOI('rocket', 'Rocket'),
]
poiMapping.sort((a, b) => {
    if (a.value < b.value) {
        return -1
    }
    if (a.value > b.value) {
        return 1
    }
    return 0
})

var mapIconMapping = {}
poiMapping.forEach(el => {
    mapIconMapping[el.key] = divIcon({
        className: 'poiIcon',
        html: `<i class="${el.icon} icon" style="font-size: 20px"/>`,
        iconAnchor: [10, 10]
    })
});

export { poiMapping, mapIconMapping };
