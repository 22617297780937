import { extendObservable } from 'mobx'
import { WifiConfigFormStore } from './FormStores/WifiConfig'
import axios from 'axios'

export const storeErrorTimeoutClientMode = "timeout_client"
export const storeErrorTimeoutAPMode = "timeout_ap"


class WifiConfigStore {
    constructor() {
        extendObservable(this, {
            formStore: new WifiConfigFormStore(),
            loadSuccess: false,
            isLoading: false,
            isStoring: false,
            storeSuccess: false,
            loadingError: "",
            storeError: "",
        });
    }
    reset() {
        this.storeSuccess = false;
        this.storeError = "";
        this.loadingError = "";
    }
    fetch() {
        this.isLoading = true
        axios.get('/api/v1/config/wifi')
        .then((response) => {
            this.loadSuccess = true
            this.isLoading = false
            this.loadingError = ""
            this.formStore.form.fields.ssid.value = response.data.ssid
            this.formStore.form.fields.password.value = response.data.password
            this.formStore.form.fields.mode.value = response.data.mode
        })
        .catch((e) => {
            this.loadSuccess = false
            this.isLoading = false
            this.loadingError = e.toString()
            console.log(this.loadingError)
        })

    }
    store() {
        this.isStoring = true
        let data = {
            "ssid": this.formStore.form.fields.ssid.value,
            "password": this.formStore.form.fields.password.value,
            "mode": this.formStore.form.fields.mode.value,
        }
        axios
        .put(
            '/api/v1/config/wifi',
            data,
            {
                timeout: 30000, // ms, should be longer than timeout on backend
            },
        )
        .then(response => {
            if (!response.data.success) {
                // Get error message from "error" field of the JSON and pass on
                throw new Error(response)
            }
            return response
        })
        .then(response => {
            console.log("stored", response);
            this.storeSuccess = true;
            this.isStoring = false;
            this.storeError = "";
            return response
        })
        .catch((e) => {
            this.isStoring = false;
            this.storeSuccess = false;
            if (e.code === "ECONNABORTED") {
                if (this.formStore.form.fields.mode.value === "client") {
                    console.log("timeout error when setting client mode", e)
                    this.storeError = storeErrorTimeoutClientMode
                } else {
                    console.log("timeout error when setting ap mode", e)
                    this.storeError = storeErrorTimeoutAPMode
                }
            } else {
                console.log("store error", e.response.data.error.toString());
                this.storeError = e.response.data.error.toString()
            }
        })
    }
}

export default WifiConfigStore
