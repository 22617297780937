import React from 'react';
import { observer } from 'mobx-react'
import { Grid, Form, Icon, Segment, SegmentGroup } from 'semantic-ui-react'
import { ExpandableSettings } from '../Components/ExpandableSettings'
import { AntennaRangeConfigurationMap } from '../Components/Map/AntennaConfigurationMap'
import { DescribeForwardBackward, DescribeLeftRight } from '../Components/Map/guihelpers'
import { CircularSummary } from './Map/SummaryViews'
import { FormInput } from '../Stores/FormStores/FormStore'
import antennaConfig from './antenna_config.svg';

const AntennaConfig = observer(class AntennaConfig extends React.Component {
    render() {
        const fields = this.props.antennaFormStore.form.fields
        const unitName = this.props.antennaFormStore.unitName
        const onFieldChange = this.props.antennaFormStore.onFieldChange
        return (
            <ExpandableSettings>
                <Form>
                    <h5> Distance to Topside housing <DescribeForwardBackward val={fields.x.value}/> </h5>
                    <FormInput
                        type="number"
                        name="x"
                        label={{ content: unitName, basic: true }}
                        labelPosition= 'right'
                        step="0.01"
                        help="X position"
                        value={fields.x.value}
                        error={fields.x.error}
                        onChange={onFieldChange}
                    />
                    <h5>Distance to Topside housing <DescribeLeftRight val={fields.y.value}/></h5>
                    <FormInput type="number"
                        name="y"
                        label={{ content: unitName, basic: true }}
                        labelPosition= 'right'
                        step="0.01"
                        help="Y position"
                        value={fields.y.value}
                        error={fields.y.error}
                        onChange={onFieldChange}
                    />
                    <h5>Antenna depth <Icon name="level down"/></h5>
                    <FormInput type="number"
                        name="depth"
                        label={{ content: unitName, basic: true }}
                        labelPosition= 'right'
                        step="0.01"
                        help="Depth"
                        value={fields.depth.value}
                        error={fields.depth.error}
                        onChange={onFieldChange}
                    />
                    <h5>Antenna rotation offset from Topside housing Forward (clockwise) <Icon name="redo"/></h5>
                    <FormInput type="number"
                        name="rotation"
                        label={{ content: 'degrees', basic: true }}
                        labelPosition= 'right'
                        step="1"
                        value={fields.rotation.value}
                        error={fields.rotation.error}
                        onChange={onFieldChange}
                    />
                </Form>
            </ExpandableSettings>
        )
    }
})

const AntennaAndRangeConfiguratorView = observer(class AntennaAndRangeConfiguratorView extends React.Component {
    render() {
        const { rangeFormStore, antennaFormStore, maxRadius } = this.props
        return (
            <div>
                <Grid columns={2}>
                        <Grid.Column>
                            <p>Place antenna as shown in the illustration. Advanced setup can be used for customization.</p>
                            <AntennaConfig
                                antennaFormStore={antennaFormStore}
                            />
                        </Grid.Column>

                        <Grid.Column>
                            <p>Antenna setup illustration</p>
                            <img src={antennaConfig} width={200} alt=""/>
                        </Grid.Column>
                </Grid>
                <SegmentGroup>
                    <Segment>
                        <AntennaRangeConfigurationMap
                            rangeFormStore={rangeFormStore}
                            maxRadius={maxRadius}
                        />
                    </Segment>
                        <CircularSummary form={rangeFormStore.form} unitName={rangeFormStore.unitName}/>
                </SegmentGroup>
            </div>
        )
    }
})


export { AntennaAndRangeConfiguratorView }
