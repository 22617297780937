import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Polyline, LayerGroup, Circle } from 'react-leaflet'
import { xyToLeflet, convertXYToPolar, offsetLatLonByDistanceAngle } from './geohelpers'

class CircleGrid extends Component {
    generateCircleGrid(range, gridSize){
        var grid = []
        for (let r = 0; r <= range; r += gridSize) {
            grid.push(r)
        }
        return grid
    }
    render() {
        const { range, gridSize } = this.props
        const grid = this.generateCircleGrid(range, gridSize)
        return (
            <LayerGroup>
                {grid.length > 0 && grid.map((d, idx) => (<Circle center={xyToLeflet(0,0)} radius={d} key={idx} color="#eee" fill={false}/>))}
            </LayerGroup>

        )
    }
}

CircleGrid.propTypes = {
    gridSize: PropTypes.number.isRequired,
    range: PropTypes.number.isRequired,
}

function defaultGrid() {
    const maxRange = 200
    return { x: { min: -maxRange, max: maxRange}, y: { min: -maxRange, max: maxRange }}
}

class AcousticGrid extends Component {
    getGrid() {
        const { range, fullGrid, gridSize } = this.props
        const gridRange = fullGrid ? defaultGrid() : range
        return generateGrid(gridSize, gridRange)
    }
    transform(grid) {
        const xygrid = grid.map(d => d.map(inner => xyToLeflet(inner[0], inner[1])))
        return xygrid
    }
    render() {
        const grid = this.getGrid()
        return ( <Grid grid={this.transform(grid)}/> )
    }
}

AcousticGrid.propTypes = {
    fullGrid: PropTypes.bool,
    gridSize: PropTypes.number.isRequired,
    range: PropTypes.shape({
        x: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
        y: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired})
    })
}

class MapGrid extends Component {
    transform(grid) {
        const { centerLatlng, heading } = this.props
        const center = {lat: centerLatlng[0], lng: centerLatlng[1]}
        let latlngGrid = grid.map(d => {
            let coord = d.map(e => {
                let polar = convertXYToPolar(e)
                return offsetLatLonByDistanceAngle(center, polar.angle + heading, polar.distance)
            })
            return coord
        })
        return latlngGrid
    }
    getGrid() {
        const { range, gridSize } = this.props
        return generateCircularGrid(gridSize, range)
    }
    render() {
        const { centerLatlng, range } = this.props
        const grid = this.getGrid()
        return ( <Grid grid={this.transform(grid)} global={true} center={centerLatlng} range={range} /> )
    }
}

MapGrid.propTypes = {
    centerLatlng: PropTypes.array.isRequired,
    heading: PropTypes.number.isRequired,
    fullGrid: PropTypes.bool,
    gridSize: PropTypes.number.isRequired,
    range: PropTypes.number.isRequired,
}

class Grid extends Component {
    render() {
        const { grid, global, center, range } = this.props
        return (
            <LayerGroup>
                {grid.length > 0 && grid.map((d, idx) => (<Polyline key={idx} positions={d} color="#eee" opacity="0.5"/>))}
                {global && (<Circle center={center} radius={range} color="#eee" opacity="0.5"/>)}
            </LayerGroup>
        )
    }
}

Grid.propTypes = {
    gridSize: PropTypes.number,
    gridRange: PropTypes.shape({
        x: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
        y: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired})
    })
}

// Generating grid based on grid settings
function generateGrid(gridSize, gridRange){
    let grid = []
    let delta = gridSize

    // Positive y
    for (let y = 0; y <= gridRange.y.max; y += delta) {
        grid.push([[gridRange.x.min, y], [gridRange.x.max, y]])
    }
    // Negative y
    for (let y = 0; y >= gridRange.y.min; y -= delta) {
        grid.push([[gridRange.x.min, y], [gridRange.x.max, y]])
    }

    // Positive x
    for (let x = 0; x <= gridRange.x.max; x += delta) {
        grid.push([[x, gridRange.y.min], [x, gridRange.y.max]])
    }
    // Negative x
    for (let x = 0; x >= gridRange.x.min; x -= delta) {
        grid.push([[x, gridRange.y.min], [x, gridRange.y.max]])
    }
    return grid
}

generateGrid.propTypes = {
    gridSize: PropTypes.number,
    gridRange: PropTypes.shape({
        x: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired}),
        y: PropTypes.shape({min: PropTypes.number.isRequired, max: PropTypes.number.isRequired})
    })
}

function findXcorr(range, y) {
    return range* Math.sin(Math.acos(y*1.0/range))
}

function findYcorr(range, x) {
    return range* Math.sin(Math.acos(x*1.0/range))
}

// Generating circular grid based on grid settings
function generateCircularGrid(gridSize, range){
    let grid = []
    let delta = gridSize

    // Y lines
    for (let y = 0; y <= range; y += delta) {
        // Positive dir
        grid.push([[findXcorr(range, y), y], [-findXcorr(range, y), y]])
        // Negative dir
        grid.push([[findXcorr(range, y), -y], [-findXcorr(range, y), -y]])
    }

    //X lines
    for (let x = 0; x <= range; x += delta) {
        // Positive dir
        grid.push([[x, findYcorr(range, x)], [x, -findYcorr(range, x)]])
        // Negative dir
        grid.push([[-x ,-findYcorr(range, x)], [-x, findYcorr(range, x)]])
    }

    return grid
}

generateCircularGrid.propTypes = {
    gridSize: PropTypes.number.isRequired,
    range: PropTypes.number.isRequired,
}

export { AcousticGrid, MapGrid, CircleGrid, generateGrid, findXcorr, findYcorr }
