import React, { useState, useEffect } from 'react';
import { Button, Segment, Message, Icon, Table, Form, Input } from 'semantic-ui-react'
import { fetcher, superFetchHeaders } from '../lib/fetch'
import useSWR from 'swr'
import { formatDecimals } from '../lib/format'

const ResetGyros = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        setIsSubmitError(false)
        setIsSuccess(false)
        superFetchHeaders('/api/v1/imu/resetgyros', 'POST', null)
        .then(res => {
            setIsSubmitting(false)
            if (!res.ok) {
                setIsSubmitError(true)
                return
            }
            setIsSuccess(true)
            setTimeout(() => { setIsSuccess(false)}, 2000)
        })
    }

    return (
        <Segment>
            <Message icon>
                <Icon name="balance scale"/>
                <Message.Content>
                <Message.Header>Step 1: Reset Gyros</Message.Header>
                <div>
                    <p>Place the topside housing on the ground and make sure there is no movement.</p>
                    <p>Ready?</p>
                </div>
                </Message.Content>
            </Message>
            <Button
                color='green'
                onClick={onSubmit}
                disabled={isSubmitting}>
                    Reset gyros&nbsp;
                    {isSubmitting && (<Icon name="circle notched" loading/>)}
                    {isSuccess && (<Icon name="check"/>)}
            </Button>
            {isSubmitError && <div><Icon name="close"/>&nbsp;Error resetting gyros</div>}
        </Segment>
    )
}
/*
const CalibrateMag = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const { data, error } = useSWR('/api/v1/imu/calibrate', fetcher, { refreshInterval: 300, dedupingInterval: 10 })

    const onCalibrationStart = (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        setIsSubmitError(false)
        const data = {action: "start"}
        superFetchHeaders('/api/v1/imu/calibrate', 'POST', data)
        .then(res => {
            if (!res.ok) {
                setIsSubmitError(true)
                setIsSubmitting(false)
                return
            }
            mutate('/api/v1/imu/calibrate')
            // It takes a little bit before calbration in progress is detected
            // a small timeout is added to avoid fickering in the gui
            setTimeout(() => { setIsSubmitting(false) }, 200)
            
        })
        mutate('/api/v1/imu/calibrate')
    }

    const onCalibrationAbort = (e) => {
        e.preventDefault()
        setIsSubmitting(true)
        setIsSubmitError(false)
        const data = {action: "abort"}
        superFetchHeaders('/api/v1/imu/calibrate', 'POST', data)
        .then(res => {
            if (!res.ok) {
                setIsSubmitError(true)
                setIsSubmitting(false)
                return
            }
            mutate('/api/v1/imu/calibrate')
            // It takes a little bit before calbration in progress is detected
            // a small timeout is added to avoid fickering in the gui
            setTimeout(() => { setIsSubmitting(false) }, 200)
            
        })
        mutate('/api/v1/imu/calibrate')
    }


    function isCalib(data, wantedState) {
        if (!data) {
            return false
        }
        if (data.state === undefined) {
            return false
        }
        return data.state === wantedState
    }

    const calibrateInProgress = isCalib(data, 1)
    const calibrationSuccess = isCalib(data, 2)
    return (
        <Segment>
            <Message icon>
                <Icon name="magnet"/>
                <Message.Content>
                <Message.Header>Step 1: Magnetometer calibration</Message.Header>
                <div>
                    <p>Place the topside housing on a flat surface and rotate the sensor slowly around only the Z axis (3 turns)</p>
                    <p>Ready?</p>
                </div>
                </Message.Content>
            </Message>
            <Button
                color='green'
                onClick={onCalibrationStart}
                disabled={isSubmitting || calibrateInProgress}>
                    Start&nbsp;{isSubmitting && (<Icon name="circle notched" loading/>)}
            </Button>
            {isSubmitError && <div><Icon name="close"/>&nbsp;Error performing action</div>}

            {error && <p>Error fetching calibration status</p>}
            {calibrateInProgress && (
                <Segment>
                <Header as="h5">Calibration in progress</Header>
                <Progress
                    percent={data.progress}
                >
                    {data.progress} %
                </Progress>
                <Button onClick={onCalibrationAbort}><Icon name="close"/>Abort calibration</Button>
                </Segment>
            )}
            {calibrationSuccess && (
                <Segment>
                <Header as="h5">Calibration completed<Icon color="green" name="check circle"/></Header>
                </Segment>
            )}

        </Segment>
    )
}
*/

const ResetNorth = () => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSubmitError, setIsSubmitError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [heading, setHeading] = useState("0")

    const onSubmit = (e, action) => {
        e.preventDefault()
        setIsSubmitting(true)
        setIsSubmitError(false)
        setIsSuccess(false)
        const data = {action: action, heading: parseFloat(heading)}
        superFetchHeaders('/api/v1/imu/setnorth', 'POST', data)
        .then(res => {
            setIsSubmitting(false)
            if (!res.ok) {
                setIsSubmitError(true)
                return
            }
            setIsSuccess(true)
            setTimeout(() => { setIsSuccess(false)}, 2000)
        })
    }

    //const error = false;
    // Form validation
    const [formValid, setformValid] = useState(true)

    useEffect(() => {
        const formValidation = () => {
            if (heading.length < 1) {
                return false
            }
            const floatVal = parseFloat(heading)
            if (isNaN(floatVal)) {
                return false
            }
            if (floatVal < 0) {
                return false
            }
            if (floatVal > 360) {
                return false
            }
            return true
        }
        setformValid(formValidation())
    }, [heading])

    const onChange = (e) => {
        setHeading(e.target.value)
    }

    return (
        <Segment>
            <Message icon>
                <Icon name="compass"/>
                <Message.Content>
                <Message.Header>Step 2: Set Heading</Message.Header>
                <div>
                    <p>Using a magnetic compass (eg. boat or mobile phone) take a note of the heading of the G2 topside.
                        This is the heading of the forward arrow that can be seen on the G2 topside.
                        Enter this value and click "Set current heading".
                    </p>
                    <p>
                        The on-board compass can drift 5-20 degrees per hour. To improve the compass heading accuracy:
                        set compass heading regularly during operation or alternatively use an external compass.
                    </p>
                </div>
                </Message.Content>
            </Message>

            <Form>
            <Form.Group>
            <Form.Field className={"form-input " + (!formValid ? "error" : "")}>
                <Input type="number"
                    disabled={isSubmitting}
                    //name="static_lat"
                    label={{ content: 'Degrees', basic: true }}
                    labelPosition= 'right'
                    step="0.1"
                    help="Current heading"
                    value={heading}
                    error={false}
                    onChange={onChange}/>
                </Form.Field>

            </Form.Group>
            <Form.Group>
            <Button
                color='green'
                onClick={(e) => onSubmit(e, "set")}
                disabled={isSubmitting || !formValid}>
                    Set current heading&nbsp;
                    {isSubmitting && (<Icon name="circle notched" loading/>)}
                    {isSuccess && (<Icon name="check"/>)}
            </Button>
            </Form.Group>
            </Form>

        {/*
            <Button
                color='grey'
                onClick={(e) => onSubmit(e, "clear")}
                disabled={isSubmitting}>
                    Clear north point&nbsp;
                    {isSubmitting && (<Icon name="circle notched" loading/>)}
                    {isSuccess && (<Icon name="check"/>)}
            </Button>
        */}
            {isSubmitError && <div><Icon name="close"/>&nbsp;Error performing action</div>}
        </Segment>
    )
}


const CompassArrow = ({direction}) => {
    const width = 50;
    const height = 50;
    const path = "M 0,0 L 0,-10 L 50,0 L 0,10 Z";
    const a1 = (direction + 90) % 360
    const a2 = (a1 + 180) % 360
    return (
        <svg width={width} height={height} viewBox={'-50 -50 100 100'} style={{verticalAlign: "middle"}}>
            <path d={path} style={{fill: "black"}} transform={`rotate(${a1})`}></path>
            <path d={path} style={{fill: "red"}} transform={`rotate(${a2})`}></path>
        </svg>
    )
}
/*
const Arrow = ({direction}) => {
    const width = 50;
    const height = 50;
    const path = "M 0,0 L 0,-10 L 50,0 L 0,10 Z";
    const a1 = (direction) % 360
    //const a2 = (a1 + 180) % 360
    return (
        <svg width={width} height={height} viewBox={'-50 -50 100 100'} style={{verticalAlign: "middle"}}>
            <path d={path} style={{fill: "black"}} transform={`rotate(${a1})`}></path>
        </svg>
    )
}


const IMUOrientation = () => {
    const { data, error } = useSWR('/api/v1/imu/calibrate', fetcher, { refreshInterval: 100, dedupingInterval: 10 })
    const {yaw, pitch, roll} = data || {yaw: -1, pitch: -1, roll: -1}
    const y = error ? "--" : formatDecimals(yaw, 1)
    //const p = error ? "--" : formatDecimals(pitch, 1)
    //const r = error ? "--" : formatDecimals(roll, 1)

    const arrowOn = false;
    return (
        <Table basic="very">
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Axis</Table.HeaderCell>
            <Table.HeaderCell>Angle</Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>
        <Table.Row>
            <Table.Cell>Roll</Table.Cell>
            <Table.Cell><strong>{r}</strong>
                {arrowOn && (<Arrow direction={roll}/>)}
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>Pitch</Table.Cell>
            <Table.Cell><strong>{p}</strong>
                {arrowOn && (<Arrow direction={pitch}/>)}
            </Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>Yaw</Table.Cell>
            <Table.Cell><strong>{y}</strong> <CompassArrow direction={yaw}/></Table.Cell>
        </Table.Row>

          </Table.Body>
        </Table>
    )
}
*/

const IMUOrientation = () => {
    const { data, error } = useSWR('/api/v1/imu/calibrate', fetcher, { refreshInterval: 100, dedupingInterval: 10 })
    const { yaw } = data || {yaw: -1}
    const y = error ? "--" : formatDecimals(yaw, 1)

    return (
        <Table basic="very">
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell>Axis</Table.HeaderCell>
            <Table.HeaderCell>Angle</Table.HeaderCell>
        </Table.Row>
        </Table.Header>
        <Table.Body>
        <Table.Row>
            <Table.Cell>Heading</Table.Cell>
            <Table.Cell><strong>{y}</strong> <CompassArrow direction={yaw}/></Table.Cell>
        </Table.Row>

          </Table.Body>
        </Table>
    )
}


export { IMUOrientation, ResetGyros, /*CalibrateMag,*/ ResetNorth }
