import React, { Component } from 'react';
import { Table } from 'semantic-ui-react'
import { Marker, Popup, FeatureGroup } from 'react-leaflet'
import { divIcon } from 'leaflet'
import { observer, inject } from 'mobx-react'
import { PropTypes } from 'prop-types';
import { mapIconMapping } from '../poimapping'

var defaultIcon = divIcon({
    className: 'poiIcon',
    html: '<i class="anchor icon" style="font-size: 20px;"></i>',
    iconAnchor: [10, 10],
})

var moveIcon = divIcon({
        className: 'poiIcon',
        html: '<i class="move icon" style="color: green; font-size: 20px;"></i>',
        iconAnchor: [10, 10],
})


const TableLatLngDepth = ({ name, lat, lng, depth }) =>(
    <div>
    <p> {name} </p>
    <Table basic compact>
        <Table.Header>
        </Table.Header>
        <Table.Body>
            <Table.Row>
                <Table.Cell>
                    Latitude:
                </Table.Cell>
                <Table.Cell>
                {lat}
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    Longitude:
                </Table.Cell>
                <Table.Cell>
                    {lng}
                </Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell>
                    Depth:
                </Table.Cell>
                <Table.Cell>
                    {depth}
                </Table.Cell>
            </Table.Row>
        </Table.Body>
    </Table>
    </div>
)

TableLatLngDepth.propTypes = {
    name: PropTypes.string.isRequired,
    lat: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,]).isRequired,
    lng: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,]).isRequired,
    depth: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,]).isRequired,
}

const POIMarkerMoveable = (observer(class POIMarkers extends Component {
    onDrag = (e) => {
        const pos = e.target.getLatLng()
        this.props.onChange('lat', pos['lat'])
        this.props.onChange('lng', pos['lng'])
        //this.props.point.setlatlng(e.latlng)
    }
    render() {
        const { point, latlng } = this.props
        return(
            <Marker
                draggable={true}
                onDrag={this.onDrag}
                key={point.id}
                position={latlng}
                icon={moveIcon}>
                <Popup>
                    <TableLatLngDepth
                        name={point.name.value}
                        lat={point.lat.value}
                        lng={point.lng.value}
                        depth={point.depth.value}
                    />
                </Popup>
            </Marker>
        )
    }
}))

POIMarkerMoveable.propTypes = {
    latlng: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    point: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

const POIMarker = ({ id, point, latlng, icon }) =>(
    <Marker key={id} position={latlng} icon={icon}>
        <Popup>
            <TableLatLngDepth
                name={point.name.value}
                lat={point.lat.value}
                lng={point.lng.value}
                depth={point.depth.value}
            />
        </Popup>
    </Marker>
)

POIMarker.propTypes = {
    latlng: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
    point: PropTypes.object.isRequired,
    icon: PropTypes.object.isRequired,
}

const POIMarkers = inject("pointOfInterestStore")(observer(class POIMarkers extends Component {
    render() {
        return (
            <FeatureGroup>
            {this.props.pointOfInterestStore.getVisible.map(d => {
                const point = d.form.fields
                let icon = mapIconMapping[point.icon.value] || defaultIcon
                return(
                    d.isEditing ?
                    <POIMarkerMoveable
                        key={d.id}
                        latlng={d.latlng}
                        point={point}
                        onChange={d.onFieldChange}
                    />
                    :
                    <POIMarker
                        key={d.id}
                        latlng={d.latlng}
                        point={point}
                        icon={icon}/>
                    )
            })
            }
            </FeatureGroup>
        )
    }
}))

export { POIMarkers, POIMarkerMoveable, POIMarker, TableLatLngDepth }
