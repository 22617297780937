import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import ReactResizeDetector from 'react-resize-detector';
import { CRS, icon } from 'leaflet'
import { Map, ZoomControl, Marker, Popup } from 'react-leaflet'
import { WaterlinkedLogoControl, GridSizeView } from './MapControls'
import { xyToLeflet } from './geohelpers'
import { CircleGrid } from './Grid'
import { CircularSearchAreaDraggable } from './CircularSearchArea'
import { formatDecimals } from '../../lib/format'


var origoIcon = icon({
    iconUrl: '/origin.svg',
    iconSize: [70, 70],
    iconAnchor: [35, 68],
})

const AntennaRangeConfigurationMap = inject("preferenceStore")(observer(class AntennaRangeConfigurationMap extends Component {
    state = {
        x: 0,
        y: 0,
        zoom: 1,
    }
    invalidateSize = () => {
        if (this.refs.map) {
            this.refs.map.leafletElement.invalidateSize()
        }
    }
    render() {
        const {rangeFormStore, maxRadius } = this.props
        const position = xyToLeflet(this.state.x, this.state.y)
        const converter = this.props.preferenceStore.converter
        const gridSize = this.props.preferenceStore.getGridSize(maxRadius)

        return (
            <Map ref="map" center={position} zoom={this.state.zoom} maxZoom={20} crs={CRS.Simple} zoomControl={false}>
            <WaterlinkedLogoControl position="topright"/>
            <ZoomControl position="topleft"/>
            <GridSizeView
                position="bottomleft"
                value={formatDecimals(converter.toUPLongFromMetric(gridSize))}
                unit={converter.nameLong()}
            />
            <Marker position={xyToLeflet(0,0)} icon={origoIcon} zIndexOffset={-1000} ><Popup><span>Origin location</span></Popup></Marker>
            <CircleGrid range={maxRadius} gridSize={gridSize}/>
            <CircularSearchAreaDraggable
                store={rangeFormStore}
                onFieldChange={rangeFormStore.onFieldChange}
                onAbsoluteMetricChange={rangeFormStore.onAbsoluteMetricFieldChange}
                maxRadius={maxRadius}
            />
            <ReactResizeDetector handleWidth skipOnMount onResize={this.invalidateSize}/>
            </Map>
        )
    }
}))

export { AntennaRangeConfigurationMap }
