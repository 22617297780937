import React from 'react';
import { Segment, Responsive } from 'semantic-ui-react'
import { observer } from 'mobx-react'
import { ReceiverConfigurationMap } from './Map/ReceiverConfigurationMap'
import { BaselineTooSmallWarning, ReceiversOnALineWarning } from './BaselineWarnings'
import { CircularShowFullSummary, CircularShowSmallSummary } from './Map/SummaryViews'
import '../Routes/Receivers.css'


const ReceiverAndRangeConfiguratorView = observer(class ReceiverAndRangeConfiguratorView extends React.Component {
    render() {
        const { rangeFormStore, rxCollectionFormStore, maxRadius } = this.props

        const rangeArea = rangeFormStore.getArea()
        const rxArea = rxCollectionFormStore.getReceiverArea()

        let baselineWarning = false
        if ((rxArea < 2*2) && (rangeArea > 40*40)) { baselineWarning = true }
        if ((rxArea < 1*1) && (rangeArea > 10*10)) { baselineWarning = true }
        if ((rxArea < 0.5*0.5) && (rangeArea > 2*2)) { baselineWarning = true }
        let receiversOnALineWarning = rxArea < 0.5

        return (
            <Segment.Group>
            <Segment>
            <ReceiverConfigurationMap
                rangeFormStore={rangeFormStore}
                rxFormStore={rxCollectionFormStore}
                maxRadius={maxRadius}
            />
            </Segment>
            <Segment>
            {baselineWarning && (<BaselineTooSmallWarning/>)}
            {receiversOnALineWarning && ( <ReceiversOnALineWarning/> ) }


            {/* Show summary better suited for small screens */}
            <Responsive maxWidth={790}>
                <CircularShowSmallSummary rangeFormStore={rangeFormStore} rxCollectionFormStore={rxCollectionFormStore}/>
            </Responsive>

            <Responsive minWidth={790}>
                <CircularShowFullSummary rangeFormStore={rangeFormStore} rxCollectionFormStore={rxCollectionFormStore}/>
            </Responsive>

            </Segment>
            </Segment.Group>
        )
    }
})

export { ReceiverAndRangeConfiguratorView }
