import { extendObservable } from 'mobx'
import { GenericFormStore } from './FormStore'
import { rules } from '../../lib/validator'

class AntennaFormStore extends GenericFormStore {
    constructor() {
        super()
        extendObservable(this, {
            form: {
                fields: {
                    x: {
                        value: 0,
                        negated: false,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(-100, 100)],
                    },
                    y: {
                        value: 1,
                        negated: false,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(-100, 100)],
                    },
                    depth: {
                        value: 0.75,
                        negated: false,
                        convertUnit: true,
                        error: null,
                        rule: [rules.between(0, 10)],
                    },
                    rotation: {
                        value: 0,
                        negated: false,
                        convertUnit: false,
                        error: null,
                        rule: [rules.between(0, 360)],
                    },
                },
                meta: {
                  isValid: true,
                  error: null,
                  unitName: "meter",
                  unitFactor: 1.0,
                  unitDecimals: 2,
                },
              },
        })
    }
}

export { AntennaFormStore }
