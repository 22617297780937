import React from 'react';
import { Message, Icon } from 'semantic-ui-react'

const ReceiversOnALineWarning = () => (
    <Message icon info>
    <Icon name="info circle"/>
    <Message.Content>
    <Message.Header>Receivers seem to be located on a line</Message.Header>
    When Receivers are on a line it will be impossible to determine what side of the line the Locator is positioned.
    You should move atleast 1 Receiver further away from the line or limit the search range
    to search only on one side of the line to improve positioning.
    </Message.Content>
    </Message>
)

const BaselineTooSmallWarning = () => (
    <Message icon info>
    <Icon name="info circle"/>
    <Message.Content>
    <Message.Header>Receivers should be further apart or search range smaller</Message.Header>
    Time of flight is measured and when the Receivers are too close together accuracy will be degraded.
    To improve accuracy, move the Receivers further apart or limit the search range.
    </Message.Content>
    </Message>
)

export { ReceiversOnALineWarning, BaselineTooSmallWarning }
