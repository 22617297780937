import React from 'react';
import { observer, inject } from 'mobx-react'
import { Segment,  Button} from 'semantic-ui-react'
import { FormSetupError, FormIsLoading } from '../Stores/FormStores/FormStore'
import { ReceiverCollectionFormStore} from '../Stores/FormStores/Receivers'
import { AntennaFormStore } from '../Stores/FormStores/Antenna'
import { RangeFormStore } from '../Stores/FormStores/Range'
import { ensureStoreHasLoaded, TimeoutError } from '../Stores/ensure'
import { RoundDecimalPlaces } from '../lib/format'
import { AntennaAndRangeConfiguratorView } from '../Components/Antenna'
import { ReceiverAndRangeConfiguratorView } from '../Components/Receivers'
import ApplyFeedbackButton from '../Components/ApplyFeedbackButton'
import { HeaderWithHelp } from '../Components/HeplText'
import './Receivers.css'

const MaxRadius = parseInt(process.env.REACT_APP_UGPS_MAX_RANGE)

const BaseLineChooser = observer(class BaseLineChooser extends React.Component {
    render() {
        const antennaEnabled = this.props.antennaEnabled
        const antennaColor = antennaEnabled ? "green" : null
        const receiverColor = !antennaEnabled ? "green" : null

        return (
            <div>
                <HeaderWithHelp
                    as="h3"
                    heading="Baseline in use"
                    help="Select and show baseline configuration"
                />
                <Button.Group>
                    <Button onClick={() => this.props.onChangeBaseline("antenna")} color={antennaColor}>Antenna</Button>
                    <Button onClick={() => this.props.onChangeBaseline("recivers")} color={receiverColor}>Receivers</Button>
                </Button.Group>
            </div>
        )
    }
})

let rangeFormStore = new RangeFormStore()
let antennaFormStore = new AntennaFormStore()
let rxCollectionFormStore = new ReceiverCollectionFormStore()

const BaselineSetup = inject("preferenceStore")(inject("rxStore")(inject("antennaStore")(inject("genericConfigStore")(observer(class BaselineSetup extends React.Component {
    state = {
        isStoring: false,
        storeFailure: "",
        storeSuccess: false,
        isSettingUp: true,
        setupError: false,
    }
    reset() {
        let gcs = this.props.genericConfigStore
        let acs = this.props.antennaStore
        let converter = this.props.preferenceStore.converter
        const unitName = converter.nameShort()
        const unitFactor = converter.toUPShortFromMetric(1)
        const decimals = 2

        // Range form store
        rangeFormStore.form.meta.unitName = unitName
        rangeFormStore.form.meta.unitFactor = unitFactor
        rangeFormStore.form.meta.unitDecimals = decimals
        //rangeFormStore.onAbsoluteMetricFieldChange('xmin', gcs.ranges.x.min, decimals)
        //rangeFormStore.onAbsoluteMetricFieldChange('xmax', gcs.ranges.x.max, decimals)
        //rangeFormStore.onAbsoluteMetricFieldChange('ymin', gcs.ranges.y.min, decimals)
        //rangeFormStore.onAbsoluteMetricFieldChange('ymax', gcs.ranges.y.max, decimals)
        //rangeFormStore.onAbsoluteMetricFieldChange('zmax', gcs.ranges.z.max, decimals)
        rangeFormStore.onAbsoluteMetricFieldChange('radius', gcs.ranges.radius, 0)
        rangeFormStore.onFieldChange('direction', gcs.ranges.direction)
        rangeFormStore.onFieldChange('sector', gcs.ranges.sector)

        // Antenna form store
        antennaFormStore.form.meta.unitName = unitName
        antennaFormStore.form.meta.unitFactor = unitFactor
        antennaFormStore.form.meta.unitDecimals = decimals

        antennaFormStore.form.fields.x.value = RoundDecimalPlaces(converter.toUPShortFromMetric(acs.x), decimals)
        antennaFormStore.form.fields.y.value = RoundDecimalPlaces(converter.toUPShortFromMetric(acs.y), decimals)
        antennaFormStore.onAbsoluteMetricFieldChange('depth', acs.depth, decimals)
        antennaFormStore.onFieldChange('rotation', acs.rotation)

        rxCollectionFormStore.antenna = (gcs.antenna === "enabled")
        rxCollectionFormStore.clear()
        this.props.rxStore.all.forEach(d => {
            rxCollectionFormStore.addReceiver(d.id,
                RoundDecimalPlaces(converter.toUPShortFromMetric(d.x), decimals),
                RoundDecimalPlaces(converter.toUPShortFromMetric(d.y), decimals),
                RoundDecimalPlaces(converter.toUPShortFromMetric(d.z), decimals),
                unitName,
                unitFactor,
                decimals,
            )
        })
    }
    componentWillReceiveProps() {
        console.log("Reset config form: Props")
        this.reset()
    }
    componentDidMount() {
        console.log("Reset config form: Mount")
        Promise.all([
            ensureStoreHasLoaded(this.props.genericConfigStore),
            ensureStoreHasLoaded(this.props.rxStore),
            ensureStoreHasLoaded(this.props.antennaStore)]
        ).then(() => {
            console.log("Store loaded, resetting")
            this.reset()
            this.setState({isSettingUp: false})
        }).catch((err) => {
            this.setState({isSettingUp: false, setupError: "Error getting values"})
            if (err instanceof TimeoutError) {
                console.log("Timeout while waiting for store", err)
            } else {
                throw err
            }
        })
    }
    store = () => {
        this.setState({isStoring: true, storeFailure: "", storeSuccess: false})

        const ranges = this.props.genericConfigStore.ranges
        const decimals = 2
        const rangeFormData = rangeFormStore.getFlattenedAbsoluteMetricValues(decimals)
        //ranges.x.setMinMax(rangeFormData.xmin, rangeFormData.xmax)
        //ranges.y.setMinMax(rangeFormData.ymin, rangeFormData.ymax)
        //ranges.z.setMax(rangeFormData.zmax)
        ranges.radius = rangeFormData.radius
        // Should not be flattened as the are not distances
        ranges.direction = parseFloat(rangeFormStore.form.fields.direction.value)
        ranges.sector = parseFloat(rangeFormStore.form.fields.sector.value)

        // Propagate values to store
        const { rxStore } = this.props;
        rxCollectionFormStore.all.forEach(d => {
            let r = rxStore.get(d.id)
            let receiverFormData = d.getFlattenedAbsoluteMetricValues(decimals)
            r.setXYZ(receiverFormData.x, receiverFormData.y, receiverFormData.z)
        })


        this.props.genericConfigStore.antenna = rxCollectionFormStore.antenna ? "enabled" : "disabled"

        // Store antenna values
        const { antennaStore } = this.props
        const antennaFormData = antennaFormStore.getFlattenedAbsoluteMetricValues(decimals)

        antennaStore.x = antennaFormData.x
        antennaStore.y = antennaFormData.y
        antennaStore.depth = antennaFormData.depth
        antennaStore.rotation = parseInt(antennaFormStore.form.fields.rotation.value)

        // Store the receivers first, then store generic configuration to propagate the configuration
        this.props.rxStore.store()
        .then(reponse => {
            return this.props.antennaStore.store()
        })
        .then(reponse => {
            console.log("Storing config", this.props.genericConfigStore.toTransportArray())
            return this.props.genericConfigStore.store()
        })
        .then(response => {
            this.setState({isStoring: false, storeFailure: "", storeSuccess: true})
            setTimeout(() => {
                this.setState({storeSuccess: false})
            }, 2000)
        }).catch(e => {
            console.log("Baseline storing error", e)
            this.setState({isStoring: false, storeFailure: e.toString()})
        })
    }

    onChangeBaseline = ( baseline ) =>{
            if (baseline === "antenna") {
                rxCollectionFormStore.antenna = true
            } else {
                rxCollectionFormStore.antenna = false
            }
    }

    render() {

        if (this.state.isSettingUp) {
            return (<FormIsLoading/>)
        }
        if (this.state.setupError) {
            return (<FormSetupError header="Woops!">Error getting form values</FormSetupError>)
        }

        const formsAreValid = rangeFormStore.form.meta.isValid && rxCollectionFormStore.isValid && antennaFormStore.form.meta.isValid
        const antennaEnabled = rxCollectionFormStore.antenna
        return (
            <Segment.Group>
            <Segment>
                <BaseLineChooser antennaEnabled={antennaEnabled} onChangeBaseline={this.onChangeBaseline}/>
            </Segment>
            <Segment>
                <ApplyFeedbackButton
                    style={{marginTop: "1rem", marginBottom: "1rem"}}
                    type="button"
                    onClick={this.store}
                    disabled={!formsAreValid || this.state.isStoring || this.state.storeSuccess}
                    isStoring={this.state.isStoring}
                    storeSuccess={this.state.storeSuccess}
                    storeFailure={this.state.storeFailure}
                />

                {antennaEnabled ? (
                    <div>
                        <HeaderWithHelp as="h3" heading="Antenna configuration" help="Antenna position, orientation and range is measured from the topside housing"/>
                        <AntennaAndRangeConfiguratorView
                            rangeFormStore={rangeFormStore}
                            antennaFormStore={antennaFormStore}
                            maxRadius={MaxRadius}
                        />
                    </div>
                ) : (
                    <div>
                        <HeaderWithHelp as="h3" heading="Receiver and range configuration" help="Receiver position and range is measured from the topside housing"/>
                        <p>Move the receivers and range to their approximate position by dragging the icons in the map view, click on the icons to enter exact values, then click on Apply to store stettings. Scroll down for details.</p>
                        <ReceiverAndRangeConfiguratorView
                            rangeFormStore={rangeFormStore}
                            rxCollectionFormStore={rxCollectionFormStore}
                            maxRadius={MaxRadius}
                        />
                    </div>
                )}
            </Segment>
            </Segment.Group>
        )
    }
    onSubmitForm = () => {
        console.log('submitted', rangeFormStore.getFlattenedValues())
    }
})))))

export default BaselineSetup
