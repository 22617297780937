import React, { Component } from 'react';
import { Menu, Icon } from 'semantic-ui-react'
import { Sidebar, Segment, Responsive } from 'semantic-ui-react'
import { HashRouter as Router, Route, Link, Switch } from 'react-router-dom'
import './App.css';
import ErrorBoundary from './Components/ErrorBoundary'
import About from './Routes/About'
import Diagnostic from './Routes/Diagnostic'
import Settings from './Routes/Settings'
import Map from './Routes/Map'
import Baseline from './Routes/Baseline'
import Network from './Routes/Network'
import Wifi from './Routes/Wifi'
import IMU from './Routes/IMU'
import Debug from './Routes/Debug'
import { ShowProductNameAndVersion } from './Components/StatusBar'
import { StatusReport } from './Components/StatusReport'
import { UpdateAvailableIcon } from './Components/VersionInfo'
import { WaterLinkedLogoWithLedBlink } from './Components/LEDButton';
import { Provider } from 'mobx-react'
import ReactResizeDetector from 'react-resize-detector';
import { isInternetExplorer } from './lib/detect'
import initStores from './Stores/initStores'

const { rxStore,
        antennaStore,
        genericConfigStore,
        masterPositionStore,
        globalPositionStore,
        acousticPositionStore,
        fusionPositionStore,
        gpsimuStore,
        preferenceStore,
        aboutInfoStore,
        locatorOrientationStore,
        pointOfInterestStore,
        networkConfigStore,
        wifiConfigStore,
        temperatureStore,
        connectionStatusStore } = initStores()


const isIE = isInternetExplorer()

class App extends Component {
  constructor() {
    super()
    let largeScreen  = true
    try {
      largeScreen = window.innerWidth > 640
    } catch (error) {
      console.log("Error getting window size", error)
    }
    this.state = {
      menuVisible: largeScreen,
    }
    this.autoCollapse = !largeScreen
  }
  toggleVisibility = () => this.setState({menuVisible: !this.state.menuVisible})
  navigate = (e) => {
    if (this.autoCollapse) {
      this.setState({menuVisible: false})
    }
    return true
  }

  onResize = (width) => {
    if (width < 640) {
      this.autoCollapse = true
    } else {
      this.autoCollapse = false
    }
  }

  render() {
    return (
      <Router>
        <Provider
          rxStore={rxStore}
          antennaStore={antennaStore}
          genericConfigStore={genericConfigStore}
          masterPositionStore={masterPositionStore}
          globalPositionStore={globalPositionStore}
          acousticPositionStore={acousticPositionStore}
          fusionPositionStore={fusionPositionStore}
          gpsimuStore={gpsimuStore}
          preferenceStore={preferenceStore}
          aboutInfoStore={aboutInfoStore}
          locatorOrientationStore={locatorOrientationStore}
          pointOfInterestStore={pointOfInterestStore}
          networkConfigStore={networkConfigStore}
          wifiConfigStore={wifiConfigStore}
          temperatureStore={temperatureStore}
          connectionStatusStore={connectionStatusStore}
        >
        <div className="App">
        {isIE && (
            <Menu secondary>
            <Menu.Item header> You are using the Internet Explorer browser which is not supported. Feel free to look around, but beware that everything might not work as expected
            </Menu.Item>
            </Menu>
        )}

        <Menu secondary attached="top" style={{backgroundColor: "#272727"}} inverted>
        <Menu.Item className="rootlink" as='a' header>
        </Menu.Item>

        <Menu.Item onClick={this.toggleVisibility}>
        <Icon name={this.state.menuVisible ? "angle double left": "sidebar"} />
          <Responsive minWidth={650}>Menu &nbsp; </Responsive>
        </Menu.Item>
        <Menu.Item>
          <StatusReport/>
        </Menu.Item>
        <Menu.Item style={{textAlign: "center", margin: "auto"}}>
          <ShowProductNameAndVersion minWidth={600}/>
        </Menu.Item>
        <Responsive minWidth={760}>
        <Menu.Menu position="right">
        <Menu.Item>
        <UpdateAvailableIcon/>
        <WaterLinkedLogoWithLedBlink/>
        </Menu.Item>
        </Menu.Menu>
        </Responsive>
      </Menu>

    <Sidebar.Pushable as={Segment} id="AppMain" style={{minHeight: '100vh', border: 'none'}}>
      <Sidebar as={Menu} animation="push" width="thin" visible={this.state.menuVisible} icon="labeled" vertical inverted style={{backgroundColor: "#121212"}}>
        <Menu.Item><Link onClick={this.navigate} to="/"><Icon name="map"/>Position</Link></Menu.Item>
        <Menu.Item><Link onClick={this.navigate} to="/baseline"><Icon name="pin"/>Baseline</Link></Menu.Item>
        <Menu.Item><Link onClick={this.navigate} to="/settings"><Icon name="cogs"/>Settings</Link></Menu.Item>
        <Menu.Item><Link onClick={this.navigate} to="/diagnostic"><Icon name="bar chart"/>Diagnostic</Link></Menu.Item>
        <Menu.Item><Link onClick={this.navigate} to="/about"><Icon name="heartbeat"/>About</Link></Menu.Item>
        <Menu.Item><Link to="#"><Icon name="angle double left" onClick={this.toggleVisibility}/></Link></Menu.Item>
      </Sidebar>
       <Sidebar.Pusher style={{width: this.state.menuVisible ? "calc(100% - 150px)" : "100%"}}>
            <Segment basic>
            <ErrorBoundary>
            <Switch>
              <Route exact path="/" component={Map}/>
              <Route path="/baseline" component={Baseline}/>
              <Route path="/settings" component={Settings}/>
              <Route path="/diagnostic" component={Diagnostic}/>
              <Route path="/about" component={About}/>
              <Route path="/network" component={Network}/>
              <Route path="/wifi" component={Wifi}/>
              <Route path="/imu" component={IMU}/>
              <Route path="/debug" component={Debug}/>
            </Switch>
            </ErrorBoundary>
            </Segment>
       </Sidebar.Pusher>
    </Sidebar.Pushable>
    <ReactResizeDetector handleWidth skipOnMount onResize={this.onResize}/>
    </div>
    </Provider>
    </Router>
    );
  }
}

export default App;
