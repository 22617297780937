
import fetch from 'unfetch'

const fetcher = url => fetch(url).then(r => r.json())

function superFetch(endpoint) {
    return fetch(endpoint, {
      method: "GET",
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return response
    })
    .then(response => { return response.json() })
}


function superFetchHeaders(endpoint, method, data) {
  var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  return fetch(endpoint, {
      method: method,
      headers: headers,
      body: JSON.stringify(data)
  })
}



export { superFetch, superFetchHeaders, fetcher };
