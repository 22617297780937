import React, { Component } from 'react';
import { Button, Checkbox, Icon } from 'semantic-ui-react'
import { observer, inject } from 'mobx-react'
import Control from 'react-leaflet-control'
import { PropTypes } from 'prop-types';
import logo from '../../waterlinked_logo.svg';
import { xyToLeflet } from './geohelpers'
import compass from './compass.svg'
import { ColorStatusIcon, BatteryIcon } from '../StatusBar'
import { RoundDecimalPlaces, formatDecimals } from '../../lib/format'
import './MapControls.css'

// Viewers and controllers used in both maps

const WaterlinkedLogoControl = ({position}) => (
    <Control position={position || "bottomleft"}>
    <div
        style={{
            backgroundColor: 'black',
            padding: '10px',
        }}
    >
        <img src={logo} alt="Water Linked Logo" width={150}/>
    </div>
    </Control>
)

WaterlinkedLogoControl.propTypes = {
    position: PropTypes.string,
}

const GridSizeView = ({position, value, unit}) => (
    <Control position={position || "bottomleft"}>
        <div className='wcontrolbackground'>
            <p>
                Grid size: <strong>{value}</strong>&nbsp;({unit})
            </p>
        </div>
    </Control>
)

GridSizeView.propTypes = {
    position: PropTypes.string,
    unit: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired, // Why is this a string??
}

const GridSizeControl = inject("preferenceStore")(inject("genericConfigStore")(observer(class GridSizeControl extends Component{
    render() {
        const gridSize = this.props.preferenceStore.getGridSize(this.props.genericConfigStore.ranges.radius)
        let converter = this.props.preferenceStore.converter
        return (
            <GridSizeView
                position={this.props.position || "bottomleft"}
                value={formatDecimals(converter.toUPLongFromMetric(gridSize))}
                unit={converter.nameLong()}
            />
        )
    }
})))

GridSizeControl.propTypes = {
    position: PropTypes.string,
    gridSize: PropTypes.number,
    converter: PropTypes.objectOf()
}

const AcousticPositionControl = inject("preferenceStore")(inject("acousticPositionStore")(observer(class AcousticPositionControl extends Component {
    render() {
        const { x, y, z } = this.props.acousticPositionStore
        let converter = this.props.preferenceStore.converter

        const NoValue = -1000
        let _x = x <= NoValue ? "--" : formatDecimals(converter.toUPLongFromMetric(x), 2)
        let _y = y <= NoValue ? "--" : formatDecimals(converter.toUPLongFromMetric(y), 2)
        let _z = z <= NoValue ? "--" : formatDecimals(converter.toUPLongFromMetric(z), 2)

        return (
            <Control position={this.props.position || "bottomleft"}>
            <div className='wcontrolbackground'>
                <p>X: <strong>{_x}</strong>&nbsp;
                   Y: <strong>{_y}</strong>&nbsp;
                   Depth <strong>{_z}</strong>&nbsp;({converter.nameLong()})
                </p>
            </div>
            </Control>
        )
    }
})))

AcousticPositionControl.propTypes = {
    position: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
    converter: PropTypes.objectOf()
}

class CenterOnLocatorControl extends Component {
    handleClick = (e) => {
        const {lat, lng} = this.props
        this.props.onClick({lat: lat, lng: lng})
    }
    render() {
        const position = this.props.position || "bottomleft"
        const {lat, lng} = this.props
        if (lat === 0 && lng === 0) {
            return null
        }
        return (
            <Control position={position}>
            <div className='wcontrolbackground'>
                <Button style={{margin: "0"}} type="button" icon compact onClick={this.handleClick}>
                    <Icon name="crosshairs" title="Center on Locator position"/>
                </Button>
            </div>
            </Control>
        )
    }
}

CenterOnLocatorControl.propTypes = {
    position: PropTypes.string,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
}


const WaterTemperatureView = ({waterTemp, waterTempSymbol}) => {
    let temp = RoundDecimalPlaces(waterTemp, 1)
    return (
        <span title="Water temperature">&nbsp;{temp}&deg;{waterTempSymbol}</span>
    )
}


const AvailablePositions = ({position, available }) => (
    <Control position={position || "bottomright"}>
        <div className='wcontrolbackground'>
            {available}
        </div>
    </Control>
)


const GPSIMUBatteryTemperatureView = ({position, gps, imu, battery, waterTemp, waterTempSymbol}) => (
    <Control position={position || "bottomright"}>
        <div className='wcontrolbackground'>
            <ColorStatusIcon key={"gps"+gps} title={"GPS reception"} icon="signal" status={gps} content="GPS"/>
            <ColorStatusIcon key={"imu"+imu} title={"IMU calibration"} icon="compass outline" status={imu} content="IMU"/>
            {battery >= 0 && (<BatteryIcon key={battery} percent={battery}/>)}
            {waterTemp > -271 && (<WaterTemperatureView waterTemp={waterTemp} waterTempSymbol={waterTempSymbol}/>)}
        </div>
    </Control>
)

GPSIMUBatteryTemperatureView.propTypes = {
    position: PropTypes.string,
    gps: PropTypes.number.isRequired,
    imu: PropTypes.number.isRequired,
    battery: PropTypes.number.isRequired,
    waterTemp: PropTypes.number.isRequired,
    waterTempSymbol: PropTypes.string.isRequired,
}

const GPSIMUBatteryTemperatureControl = inject("preferenceStore")(inject("temperatureStore")(inject("gpsimuStore")(observer(class GPSIMUBatteryTemperatureControl extends Component {
    render() {
        const {position} = this.props
        const {gps, imu, battery} = this.props.gpsimuStore
        const waterTemp = this.props.preferenceStore.converter.metricToUPTemperature(this.props.temperatureStore.water)
        const waterTempSymbol = this.props.preferenceStore.converter.temperatureSymbol;
        return(
            <GPSIMUBatteryTemperatureView
                position={position}
                gps={gps}
                imu={imu}
                battery={battery}
                waterTemp={waterTemp}
                waterTempSymbol={waterTempSymbol}
            />
        )
    }
}))))

GPSIMUBatteryTemperatureControl.propTypes = {
    position: PropTypes.string,
}

// Global map specific viewers with controllers


const GlobalAvailablePositionsControl = inject("globalPositionStore")(observer(class GlobalAvailablePositionsControl extends Component {
    onChange= (e, data) => {
        const {globalPositionStore} = this.props
        //debugger
        globalPositionStore.setShow(data.label, data.checked)
   }

    render() {
        const {position, globalPositionStore} = this.props

        var update = globalPositionStore.update

        const available = []

        for (var key in globalPositionStore.positions_dict) {
            const positionStore = globalPositionStore.positions_dict[key]
            const checked = globalPositionStore.getShow(key)
            available.push(
                <div key={positionStore.name}>
                <Checkbox
                    className="wlcheckbox"
                    checked={checked}
                    label={positionStore.name}
                    onChange={this.onChange}
                />
                </div>
                )
        }

        if (available.length < 2) {
            // Only 1 thing to select, nothing reason to show this
            return null
        }

        return(
            <AvailablePositions
                position={position}
                available={available}
                update={update}
            />
        )
    }
}))

const LocatorCenterOnLocationControl = inject("globalPositionStore")(observer(class LocatorCenterOnLocationControl extends Component {
    render() {
        const { lat, lng } = this.props.globalPositionStore
        return <CenterOnLocatorControl lat={lat} lng={lng} {...this.props}/>
    }
}))

LocatorCenterOnLocationControl.propTypes = {
    onClick: PropTypes.func.isRequired,
    lat: PropTypes.number,
    lng: PropTypes.number,
}

// Acoustic map specific viewers with controllers

const AcousticAvailablePositionsControl = inject("acousticPositionStore")(observer(class AcousticAvailablePositionsControl extends Component {
    onChange= (e, data) => {
        const {acousticPositionStore} = this.props
        //debugger
        acousticPositionStore.setShow(data.label, data.checked)
    }

    render() {
        const {position, acousticPositionStore} = this.props

        var update = acousticPositionStore.update

        const available = []

        for (var key in acousticPositionStore.positions_dict) {
            const positionStore = acousticPositionStore.positions_dict[key]
            const checked = acousticPositionStore.getShow(key)
            available.push(<div key={positionStore.name}>
                    <Checkbox
                    className="wlcheckbox"
                    checked={checked}
                    label={positionStore.name}
                    onChange={this.onChange}
                    /></div>)
        }

        if (available.length < 2) {
            // Only 1 thing to select, nothing reason to show this
            return null
        }

        return(
            <AvailablePositions
                position={position}
                available={available}
                update={update}
            />
        )
    }
}))

const CompassView = ({position, orientation}) => (
    <Control position={position || "topright"}>
    <div
        style={{
            padding: '25px',
        }}
    >
        <img src={compass} alt="Compass" width={30} style={{"transform": "rotate(" + (orientation) + "deg)"}}/>
    </div>
    </Control>
)

CompassView.propTypes = {
    position: PropTypes.string,
    orientation: PropTypes.number.isRequired,
}

const AcousticCompassControl = (inject("masterPositionStore")(observer(class AcousticCompassControl extends Component {
    render(){
        // Acoustic view is oriented with Y up, so compass must be compensated to point the right way
        const compensatedOrientation = 360 - this.props.masterPositionStore.orientation
        return(
            <CompassView position={this.props.position} orientation={compensatedOrientation}/>
        )
    }
})))

AcousticCompassControl.propTypes = {
    position: PropTypes.string,
    orientation: PropTypes.number,
}

const AcousticCenterOnLocationControl = inject("acousticPositionStore")(observer(class AcousticCenterOnLocationControl extends Component {
    render() {
        const { x, y } = this.props.acousticPositionStore
        const position = xyToLeflet(x, y)
        return <CenterOnLocatorControl lat={position.lat} lng={position.lng} {...this.props}/>
    }
}))

AcousticCenterOnLocationControl.propTypes = {
    onClick: PropTypes.func.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
}


// Lat/lon with 6 decimal give ~10cm accuracy
const LatitudeLongitudeView = ({position, lat, lng}) => (
    <Control position={position || "bottomright"}>
    <div className='wcontrolbackground' title='Latitude/longitude of Locator'>
        <span>{RoundDecimalPlaces(lat, 6)}</span>&nbsp;
        <span>{RoundDecimalPlaces(lng, 6)}</span>
    </div>
    </Control>
)

const LatitudeLongitudeControl = inject("globalPositionStore")(inject("preferenceStore")(observer(class LatitudeLongitudeControl extends Component {
    render() {
        if (!this.props.preferenceStore.latLngVisible) {
            return null
        }
        const { lat, lng } = this.props.globalPositionStore
        return <LatitudeLongitudeView lat={lat} lng={lng} {...this.props}/>
    }
})))


LatitudeLongitudeControl.propTypes = {
    position: PropTypes.string,
}


export { WaterlinkedLogoControl, AcousticPositionControl, AcousticCenterOnLocationControl,
    LocatorCenterOnLocationControl, AcousticCompassControl, GPSIMUBatteryTemperatureControl,
    GPSIMUBatteryTemperatureView, GridSizeControl, GridSizeView, CompassView, CenterOnLocatorControl,
    LatitudeLongitudeControl, AcousticAvailablePositionsControl, GlobalAvailablePositionsControl,
}
