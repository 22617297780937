import { extendObservable, action  } from "mobx"
import { superFetch } from '../lib/fetch'

class LocatorOrientation {
    constructor() {
        extendObservable(this, {
            loadingError: "",
            orientation: -1,

            setFromWs: action((data) => {
                this.orientation = data.orientation
            }),
            setNoValue: action(() => {
                this.orientation = -1
            })
        })
    }
    fetch() {
        return superFetch('/api/v1/external/orientation')
        .then((data) => {
            this.orientation = data.orientation
            this.loadingError = ""
        })
        .catch((e) => {
            this.loadingError = e.toString()
        })
    }
}


export { LocatorOrientation }