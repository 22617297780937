
function isInternetExplorer() {
    // Detect MSIE up to version 10
    // https://stackoverflow.com/questions/24861073/detect-if-any-kind-of-ie-msie
    if(navigator.appVersion.indexOf("MSIE") !== -1){
        return true;
    }
    // Detect MSIE 11
    // https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (isIE11) {
        return true;
    }
    return false;
}

export { isInternetExplorer };
