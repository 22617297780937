import React, { Component } from 'react';
import { LayerGroup, Circle } from 'react-leaflet'
import { observer, inject } from 'mobx-react'
import { xyToLeflet } from './geohelpers';
import { AcousticGrid, MapGrid } from './Grid'
import { SearchRangeArc, SearchRangeArcGlobal } from './CircularSearchArea'
import { wrap360 } from '../../lib/helpers'


const CircularRangeVisualization = inject("preferenceStore")(inject("genericConfigStore")(inject("masterPositionStore")(observer(class RangeVisualization extends Component {
    render() {
        const { masterPositionStore } = this.props
        const ranges = this.props.genericConfigStore.ranges
        const gridSize = this.props.preferenceStore.getGridSize(ranges.radius)

        const startAngle = wrap360(ranges.direction - (ranges.sector/2) + masterPositionStore.orientation)
        const endAngle = wrap360(ranges.direction + (ranges.sector/2) + masterPositionStore.orientation)

        var fullCircle = false
        if (ranges.sector > 359) {
            fullCircle = true
        }

        return (
            <LayerGroup>
                <MapGrid
                    range={ranges.radius}
                    centerLatlng={masterPositionStore.latlng}
                    heading={masterPositionStore.orientation}
                    gridSize={gridSize}
                />
                {fullCircle ? (
                    <Circle radius={ranges.radius} center={masterPositionStore.latlng} color="green" />
                    ) : (
                    <SearchRangeArcGlobal center={masterPositionStore.latlng} radius={ranges.radius} startAngle={startAngle} endAngle={endAngle} />
                )}

            </LayerGroup>
        )
    }
}))))


const AcousticCircularRangeVisualization = inject("preferenceStore")(inject("genericConfigStore")(observer(class AcousticRangeVisualization extends Component {
    render() {
        const ranges = this.props.genericConfigStore.ranges
        const gridSize = this.props.preferenceStore.getGridSize(ranges.radius)
        var circularRanges = ranges.getCircularRanges()
        const startAngle = wrap360(ranges.direction - (ranges.sector/2))
        const endAngle = wrap360(ranges.direction + (ranges.sector/2))

        var fullCircle = false
        if (ranges.sector > 359) {
            fullCircle = true
        }


        return (
            <LayerGroup>
                <AcousticGrid
                    range={circularRanges}
                    gridSize={gridSize}
                />

                {fullCircle ? (
                    <Circle radius={ranges.radius} center={xyToLeflet(0,0)} color="green" />
                    ) : (
                    <SearchRangeArc radius={ranges.radius} startAngle={startAngle} endAngle={endAngle} />
                )}
            </LayerGroup>
        )
    }
})))

export { CircularRangeVisualization, AcousticCircularRangeVisualization }
