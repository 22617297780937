import React from 'react';

function clamp(val, min, max) {
    return Math.min(Math.max(val, min), max)
}

const SignalStrengthImage = ({bars}) => {
    const _bars = clamp(bars, 0, 4)
    const width = 35;
    const height = 20;

    const innerHeight = 100
    const heightPadding = 5
    const unitHeight = 20;

    const barWidth = 20;
    const barSpacing = 8;
    return (
        <svg width={width} height={height} viewBox={'0 0 100 '+innerHeight} style={{verticalAlign: "middle", stroke: "black", strokeWidth:"1"}}>
            <rect
                x={0*(barWidth+barSpacing)}
                y={innerHeight-heightPadding-unitHeight}
                height={unitHeight}
                width={barWidth}
                rx="3"
                style={{fill: _bars > 0 ? "#21ba45" : "none"}}
            />
            <rect
                x={1*(barWidth+barSpacing)}
                y={innerHeight-heightPadding-unitHeight*2}
                height={unitHeight*2}
                width={barWidth}
                rx="3"
                style={{fill: _bars > 1 ? "#21ba45" : "none"}}
            />
            <rect
                x={2*(barWidth+barSpacing)}
                y={innerHeight-heightPadding-unitHeight*3}
                height={unitHeight*3}
                width={barWidth}
                rx="3"
                style={{fill: _bars > 2 ? "#21ba45" : "none"}}
            />
            <rect
                x={3*(barWidth+barSpacing)}
                y={innerHeight-heightPadding-unitHeight*4}
                height={unitHeight*4}
                width={barWidth}
                rx="3"
                style={{fill: _bars > 3 ? "#21ba45" : "none"}}
            />
        </svg>
    )
}

const SignalStrengthIcon = ({rssi}) => {
    // Signal strength | bars
    // <-100 dBm       | 0 
    // -100 - -85 dBm  | 1
    //  -85 - -70 dBm  | 2
    //  -70 - -55 dBm  | 3
    //  >-55 dBm       | 4
    let bars = 0;
    let description = "Weak"
    if (rssi <= -100) {
        bars = 0;
        description = "Weak"
    } else if (rssi <= -85) {
        bars = 1
        description = "Weak"
    } else if (rssi <= -70) {
        description = "Medium"
        bars = 2
    } else if (rssi <= -55) {
        description = "Strong"
        bars = 3
    } else {
        bars = 4
        description = "Strong"
    }
    return (
        <span title={description + " signal"}>
            <SignalStrengthImage bars={bars}/>
        </span>
    )
}

const NoiseStrengthImage = ({percent, color}) => {
    const width = 40
    const height = 15

    const split = width * clamp(percent, 1, 100) / 100;

    return (
        <svg
            width={width}
            height={height}
            viewBox={'0 0 ' + width + " " +height}
            style={{verticalAlign: "middle", stroke: "black", strokeWidth:"1", padding: "0.2em"}}
        >
            <rect
                x={0}
                y={0}
                height={height}
                width={width}
                rx="10"
                style={{fill: "none", stroke: "grey", strokeWidth:"2"}}
            />
            <rect
                x={0}
                y={0}
                height={height}
                width={split}
                rx="10"
                style={{fill: color, stroke: "grey", strokeWidth:"2"}}
            />
        </svg>
    )
}

const NoiseStrengthIllustration = ({nsd}) => {
    // 100% is lots of noise
    //  25% is not much noise
    const percent = nsd > -50 ? 100 :
                    nsd > -75 ? 75 :
                    nsd > -90 ? 50 :
                    25;
    const color =  nsd > -75 ? "#db2828" :
                   nsd > -90 ? "#f2711c" :
                   "#21ba45";
    const desc = percent > 75 ? "high" :
                  percent > 50 ? "moderate" :
                  "low";

    return (
        <span title={"Background noise level" + desc}>
        <NoiseStrengthImage percent={percent} color={color}/>
        </span>
    )
}

export { SignalStrengthIcon, NoiseStrengthIllustration }
