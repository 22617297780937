import React, {  useRef, useEffect } from 'react';
import { extent, min, max } from 'd3-array'
import { scaleLinear } from 'd3-scale'
import { axisLeft, axisBottom } from 'd3-axis'
import { select } from 'd3-selection'
import './DiagnosticGraph.css'
import { createMedia } from "@artsy/fresnel"


const transducer_colors = ["#0000ff", "#00ff00", "#ff0000", "#000000"]

const Axis = props => {
    // https://stackoverflow.com/a/56029853
    const axisRef = axis => {
        axis && props.axisCreator(select(axis));
    };

    return <g className={props.className} ref={axisRef} />;
};

const GraphInner = props => {

    const margin = {top: 20, right: 15, bottom: 30, left: 50};
    const outerWidth = props.width;
    const outerHeight = props.height;
    const width = outerWidth - margin.left - margin.right;
    const height = outerHeight - margin.top - margin.bottom;

    const canvasRef = useRef(null)
    const yMin = useRef(new Array(10))
    const yMax = useRef(new Array(10))

    const y_extents = props.graph.map(item => {
        return extent(item, d => d.py)
    })
    const y_min = min(y_extents, d => d[0])
    const y_max = max(y_extents, d => d[1])

    const x_max = props.graph[0][props.graph[0].length-1].px

    yMax.current.shift(0)
    yMax.current.push(y_max)
    yMin.current.shift(0)
    yMin.current.push(y_min)

    const _yMx = max(yMax.current)
    const _yMn = min(yMin.current)

    const xScale = scaleLinear()
        .domain([0, x_max])
        .range([0, width])
        .nice();

    const yScale = scaleLinear()
        .domain([_yMn-10, _yMx+10])
        //.domain([10, 100])
        .range([height, 0])
        .nice();

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        var nWidth = context.canvas.clientWidth;
        var nHeight = context.canvas.clientHeight;
        context.canvas.width = nWidth;
        context.canvas.height = nHeight;

        const drawPoint = (ctx, x, y) => {
            const px = xScale(x);
            const py = yScale(y);
            ctx.lineTo(px, py)
        }

        const draw = (ctx) => {
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

            if (!props.graph) {
                return
            }
            props.graph.forEach((tr, idx) => {
                ctx.beginPath()
                ctx.moveTo(xScale(0),yScale(0))
                ctx.strokeStyle = transducer_colors[idx]
                ctx.lineWidth = 1
                tr.forEach(d => {
                    drawPoint(ctx, d.px, d.py)
                })
                ctx.stroke()
            })
        }

        draw(context)

    }, [props.graph, xScale, yScale])

    const xAxis = axisBottom(xScale)
    const yAxis = axisLeft(yScale)
    const xTransform = `translate(${margin.left}, ${outerHeight - margin.bottom})`;
    const yTransform = `translate(${margin.left}, ${margin.top})`;

    const labelXTransform = `translate(${outerWidth/2 - 40}, ${outerHeight + 10}) `;
    const labelYTransform = `translate(10, ${outerHeight/2 + 70}) rotate(-90)`;


    return (
        <div className="scatter-container" style={{width: outerWidth, height: outerHeight}}>
        <canvas
            className="canvas-plot"
            ref={canvasRef}
            style={{
                width: outerWidth-margin.left,
                height: outerHeight-margin.top,
                marginLeft: margin.left + "px",
                marginTop: margin.top + "px",
    }}
        />
        <svg className="svg-plot"
            width={outerWidth}
            height={outerHeight}
            style={{overflow:"visible"}}
        >
            <g transform={xTransform}>
                <Axis axisCreator={xAxis}/>
            </g>
            <g transform={yTransform}>
                <Axis axisCreator={yAxis}/>
            </g>
            {props.labelX &&
                <g transform={labelXTransform}>
                    <text >{props.labelX}</text>
                </g>
            }
            {props.labelY &&
                <g transform={labelYTransform}>
                    <text >{props.labelY}</text>
                </g>
            }
        </svg>
        </div>
    )
}

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        sm: 0,
        md: 600,
        lg: 1024,
        xl: 1920,
    },
})

const Graph = props => {
    return (
        <MediaContextProvider>
            <Media at="sm">
                <GraphInner width={320} height={128} mod={0} {...props}/>
            </Media>
            <Media at="md">
                <GraphInner width={600} height={280} mod={0.5} {...props}/>
            </Media>
            <Media at="lg">
                <GraphInner width={1000} height={400} mod={0.8} {...props}/>
            </Media>
            <Media greaterThanOrEqual="xl">
                <GraphInner width={1667} height={600} mod={1} {...props}/>
            </Media>
        </MediaContextProvider>
    )
}

export { Graph, transducer_colors };
