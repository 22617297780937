import { extendObservable } from 'mobx'
import { superFetch } from '../lib/fetch'

class AntennaStore {
    constructor() {
        extendObservable(this, {
            hasLoaded: false,
            isLoading: false,
            isStoring: false,
            x: 1,
            y: 2,
            depth: 1.5,
            rotation: 90,

            /*receiverRegistry: observable.map(),
            count: computed(() => this.receiverRegistry.size),
            clear: action(() => {
                this.receiverRegistry.clear()
            }),
            addReceiver: action((id, x,y,z) => {
                this.receiverRegistry.set(id, new ReceiverModel(id, x,y,z))
            }),
            get: (id) => { return this.receiverRegistry.get(id) },
            all: computed(() => this.receiverRegistry.values()),*/

        });
    }
    fetch() {
        this.isLoading = true
        return superFetch('/api/v1/config/antenna')
        .then((data) => {
            this.hasLoaded = true
            this.isLoading = false
            this.loadingError = ""
            this.x = data.x
            this.y = data.y
            this.depth = data.depth
            this.rotation = data.antenna_rotation
        })
        .catch((e) => {
            this.isLoading = false
            this.loadingError = e.toString()
            console.log("Error loading antenna config: ", e.toString())
        })
    }
    store() {
        this.isStoring = true
        let data = {
            x: this.x,
            y: this.y,
            depth: this.depth,
            antenna_rotation: this.rotation,
        }
        console.log("Storing antenna config: ", data)
        var headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        return fetch('/api/v1/config/antenna', {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(data)
        })
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText)
          }
          return response
        })
        .then(response => response.text())
        .then(response => {
            console.log("stored", response)
            this.isStoring = false;
            this.storeError = "";
            return response
        })
        .catch((e) => {
            console.log("store error", e.toString())
            this.isStoring = false;
            this.storeError = e.toString()
            throw e;
        })
    }

}

export { AntennaStore }
