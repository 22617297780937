import React, { Component } from 'react';
import { Popup, Checkbox, Segment, Icon, Button, Grid, Form } from 'semantic-ui-react'
import { inject, observer } from 'mobx-react';
import { SimpleFormInput, FormIsLoading } from '../Stores/FormStores/FormStore';
import { poiMapping } from './poimapping';
import ErrorMessage from './ErrorMessage'
import { clamp } from '../lib/helpers'


let dropdownOptions = poiMapping.map(d => {
    return { key: d.key, value: d.key, text: <span><Icon name={d.icon}/> {d.value}</span> }
})

const generateCSVData = (poiStore) => {
    const separator = ';'
    const newline = '\n'
    let points = poiStore.all.map(d => d.getFlattenedValues())
    let data = points.map(d => {
        return ['"'+d.name+'"', d.lat, d.lng, d.depth].join(separator)
    })
    // Add header
    data.unshift(["Name", "Latitude", "Longitude", "Depth"].join(separator))
    return data.join(newline) + newline
}

const ExportPOIButton = inject("pointOfInterestStore")(observer(class ExportPOIButton extends Component {

    downloadFile = () => {
        let element = document.createElement("a")
        let data = generateCSVData(this.props.pointOfInterestStore)
        let file = new Blob([data], {type: 'text/csv'})
        element.href = URL.createObjectURL(file);
        element.download = "poi.csv";
        // Need to add to document for this to work on Firefox
        document.body.appendChild(element)
        element.setAttribute('type', 'hidden')
        element.click();
    }

    render() {
        return (
            <div style={{paddingTop: '1em'}}>
                <Button type='button' basic icon='download' compact title="Download POI list" onClick={this.downloadFile}/>
            </div>
        )
    }
}))

const VisibilitySelector = observer(({onFieldChange, point}) => (
    <Checkbox
        toggle
        title='Toggle POI visiblity'
        onChange={(e,data) => { onFieldChange('visible', data.checked) }}
        checked={point.form.fields.visible.value}
    />
))

const VisibilitySelectorWithLabel = observer(({onFieldChange, point}) => (
    <Form>
        <Form.Group>
            <div className="field form-input">
                <label>Visiblity </label>
                <VisibilitySelector onFieldChange={onFieldChange} point={point}/>
            </div>
        </Form.Group>
    </Form>
))

class DeleteButton extends Component {
    state = { isOpen: false }
    onOpenPopup = () => {
        this.setState({isOpen: true})
    }
    onDeleteConfirm = () => {
        this.props.handleDelete(this.props.point.id)
        this.setState({isOpen: false})
    }
    render() {
        return (
            <Popup
                trigger={
                    <Button
                        color={this.props.point.errorDelete ? "red" : null}
                        type='button'
                        basic
                        icon='trash alternate outline'
                        compact
                        title='Delete this point?'
                        disabled={this.props.disabled}
                    />
                }
                content={
                    <Button
                        type='button'
                        color='red'
                        onClick={this.onDeleteConfirm}
                        content='Confirm delete'
                        disabled={this.props.disabled}
                    />
                }
                on='click'
                open={this.state.isOpen}
                onOpen={this.onOpenPopup}
                position='right center'
            />
        )
    }
}

const EditButton = ({handleEndEdit, point, disabled}) => (
    <Button
        type="button"
        basic
        compact
        icon="checkmark"
        onClick={handleEndEdit}
        color={point.errorUpdate ? "red" : null}
        disabled={disabled}
    />
)

const POIView = ({point, handleStartEdit, onFieldChange}) => (
    <Grid.Row>
        <Grid.Column width={3}>
            <Icon name={point.form.fields.icon.value} size={'large'} />
        </Grid.Column>

        <Grid.Column width={11}>
            {point.form.fields.name.value}
            &nbsp;
                <Button type="button"
                    icon='pencil'
                    title='Edit point of interest'
                    basic compact onClick={handleStartEdit}
                />
        </Grid.Column>

        <Grid.Column width={2}>
            <VisibilitySelector point={point} onFieldChange={onFieldChange}/>
        </Grid.Column>
    </Grid.Row>
)

const POIIconSelector = observer(({point, onFieldChange}) => (
    <Form>
    <Form.Group>
    <Form.Dropdown
        fluid
        label="Icon"
        selection
        value={point.form.fields.icon.value}
        options={dropdownOptions}
        onChange={(e, data) => {onFieldChange("icon", data.value)}}
    />
    </Form.Group>
    </Form>
))


const POIEditView = observer(({point, handleEndEdit, handleDelete, onFieldChange}) => (
    <Grid.Row>
        <Grid.Column width={3}>
            <POIIconSelector point={point} onFieldChange={onFieldChange}/>
        </Grid.Column>

        <Grid.Column width={11}>
            <Form>
                <Form.Group widths='equal'>
                <SimpleFormInput
                    fluid
                    label='Name'
                    value={point.form.fields.name.value}
                    name='name'
                    onChange={onFieldChange}
                />

                <div className="field form-input">
                    <label>Actions</label>
                    <EditButton point={point} handleEndEdit={handleEndEdit} disabled={point.isLoading}/>
                    <DeleteButton point={point} handleDelete={handleDelete} disabled={point.isLoading}/>
                </div>

                <SimpleFormInput
                    fluid
                    label='Latitude'
                    name='lat'
                    onChange={onFieldChange}
                    value={clamp(point.form.fields.lat.value, -90, 90)}
                />
                <SimpleFormInput
                    fluid
                    label='Longitude'
                    name='lng'
                    onChange={onFieldChange}
                    value={clamp(point.form.fields.lng.value, -180, 180)}
                />
                <SimpleFormInput
                    fluid
                    label='Depth'
                    name='depth'
                    onChange={onFieldChange}
                    value={point.form.fields.depth.value}
                />
                </Form.Group>
            </Form>

            {point.errorUpdate && (
                <ErrorMessage heading="Updating POI failed" message={point.errorUpdate}/>
            )}
            {point.errorDelete && (
                <ErrorMessage heading="Deleting POI failed" message={point.errorDelete}/>
            )}

        </Grid.Column>

        <Grid.Column width={2}>
            <VisibilitySelectorWithLabel point={point} onFieldChange={onFieldChange}/>
        </Grid.Column>

    </Grid.Row>
))

const Point = observer(class Point extends Component{
    handleStartEdit = () => {
        this.props.point.isEditing = true
    }
    handleEndEdit = () => {
        this.props.handleUpdate(this.props.point.id)
    }
    render (){
        const { point, handleDelete } = this.props
        return (
            point.isEditing ?
            <POIEditView
                point={point}
                handleDelete={handleDelete}
                handleEndEdit={this.handleEndEdit}
                onFieldChange={point.onFieldChange}
            />
            :
            <POIView
                point={point}
                handleStartEdit={this.handleStartEdit}
                onFieldChange={point.onFieldChange}
            />
        )
    }
})

const TryAgainButton = ({onClick}) => (
    <Button
        type="button"
        basic
        compact
        onClick={onClick}
    >
        Try Again
    </Button>
)

const PointOfInterestExpanded = inject("pointOfInterestStore")(observer(class PointOfInterest extends Component {
    handleDelete = (id) => {
        this.props.pointOfInterestStore.deletePersistent(id)
    }
    handleUpdate = (id) => {
        this.props.pointOfInterestStore.updatePersistent(id)
    }
    handleTryAgain = () => {
        this.props.pointOfInterestStore.fetch()
    }
    render() {
        const points = this.props.pointOfInterestStore.newestFirst
        const poiStore = this.props.pointOfInterestStore
        return (
            <div>
                {poiStore.isFetching && <FormIsLoading/>}
                {poiStore.errorFetchMsg && (
                    <div>
                        <ErrorMessage
                            heading="Unable to fetch POIs"
                            message={poiStore.errorFetchMsg}
                        />
                        <TryAgainButton onClick={this.handleTryAgain}/>
                    </div>
                )}

                {points.length > 0 && (
                    <Grid stackable>
                    {points.map(d =>
                        <Point
                            key={d.id}
                            handleDelete={this.handleDelete}
                            handleUpdate={this.handleUpdate}
                            point={d}
                            />
                    )}
                    </Grid>
                )}
                {points.length > 0 && (<ExportPOIButton/>)}
            </div>
        )
    }
}))

const PointOfInterest = observer(class PointOfInterest extends Component {
    render() {
        const mapStateStore = this.props.mapStateStore
        const icon = (mapStateStore.poiExpanded ? "angle double up" : "angle double down")
        return (
            <Segment>
                <h3 onClick={() => mapStateStore.setPoiExpanded(!mapStateStore.poiExpanded)}> Points Of Interest (POI) <Icon name={icon}/></h3>
                {mapStateStore.poiExpanded &&
                    <PointOfInterestExpanded/>
                }
            </Segment>
        )
    }
})

export { PointOfInterest, ExportPOIButton, generateCSVData, Point, POIEditView }
