import React, { Component } from 'react';
import { observer, inject } from 'mobx-react'
import { Icon, Table } from 'semantic-ui-react'
import { icon, CRS, divIcon } from 'leaflet'
import { Map, Marker, Popup, ZoomControl } from 'react-leaflet'
import { xyToLeflet, leafletToXY } from './geohelpers'
import { WaterlinkedLogoControl, GridSizeView } from './MapControls'
import { CircleGrid } from './Grid'
import { ConvexHullPoly } from './ConvexHullPoly'
import { FormInput } from '../../Stores/FormStores/FormStore'
import ReactResizeDetector from 'react-resize-detector';
import { formatDecimals } from '../../lib/format'
import { ShowUnit } from '../ShowUnit'
import { clamp } from '../../lib/helpers'
import { CircularSearchAreaDraggable } from './CircularSearchArea'
import { DescribeForwardBackward, DescribeLeftRight } from './guihelpers'


var origoIcon = icon({
    iconUrl: '/origin.svg',
    iconSize: [70, 70],
    iconAnchor: [35, 68],
})
var dragIcon = divIcon({
    className: 'receiverIcon',
    iconSize: [20, 20],
    iconAnchor: [10, 10],
    html: '<i class="move large icon"/>',
})

const rxOpts = {
    iconSize: [25, 40],
    iconAnchor: [12, 39],
    popupAnchor: [0, -32],
    shadowUrl: '/marker-shadow.png',
    shadowSize: [41, 41],
    shadowAnchor: [12, 39]
}

var rxIcons = {
    1: icon(Object.assign({iconUrl: '/marker-rx-1.png'}, rxOpts)),
    2: icon(Object.assign({iconUrl: '/marker-rx-2.png'}, rxOpts)),
    3: icon(Object.assign({iconUrl: '/marker-rx-3.png'}, rxOpts)),
    4: icon(Object.assign({iconUrl: '/marker-rx-4.png'}, rxOpts)),
}

const ReceiverDraggable = observer(class ReceiverDraggable extends Component {
    onDrag = (e) => {
        const pos = leafletToXY(e.target.getLatLng())
        pos[0] = clamp(pos[0], -100, 100)
        pos[1] = clamp(pos[1], -100, 100)
        e.target.setLatLng(xyToLeflet(pos[0], pos[1]))

        if (this.props.onMove) {
            this.props.onMove(this.props.id, ...pos)
        }
    }
    render() {
        const { id, x, y, form, onFieldChange, unitName } = this.props
        return (
            <Marker icon={rxIcons[id+1] || dragIcon} draggable={true} onDrag={this.onDrag} position={xyToLeflet(x, y)}>
                <Popup>
                    <div>
                    <h5>Receiver {id+1} placement <ShowUnit unit={unitName}/></h5>

                    <Table basic compact>
                        <Table.Header>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <DescribeForwardBackward val={x}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <FormInput
                                        type="number"
                                        step="0.1"
                                        name="x"
                                        help="X position"
                                        value={form.fields.x.value}
                                        error={form.fields.x.error}
                                        onChange={onFieldChange}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <DescribeLeftRight val={y}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <FormInput
                                        type="number"
                                        step="0.1"
                                        name="y"
                                        help="Y position"
                                        value={form.fields.y.value}
                                        error={form.fields.y.error}
                                        onChange={onFieldChange}/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <Icon name="level down"/> Depth
                                </Table.Cell>
                                <Table.Cell>
                                    <FormInput
                                        type="number"
                                        step="0.1"
                                        name="z"
                                        help="Depth"
                                        value={form.fields.z.value}
                                        error={form.fields.z.error}
                                        onChange={onFieldChange}/>
                                </Table.Cell>

                            </Table.Row>
                        </Table.Body>
                    </Table>
                    </div>
                </Popup>
            </Marker>
        )
    }
})


const ReceiverConfigurationMap = inject("preferenceStore")(observer(class ReceiverConfigurationMap extends Component {
    state = {
        x: 0,
        y: 0,
        zoom: 1,
    }
    onReceiverMove = (id, x, y) => {
        let receiver = this.props.rxFormStore.get(id)
        receiver.onAbsoluteMetricFieldChange('x', x, 2)
        receiver.onAbsoluteMetricFieldChange('y', y, 2)
    }
    invalidateSize = () => {
        if (this.refs.map) {
            this.refs.map.leafletElement.invalidateSize()
        }
    }
    render() {
        const position = xyToLeflet(this.state.x, this.state.y)
        const { rxFormStore, rangeFormStore, maxRadius } = this.props
        const debug = false
        const converter = this.props.preferenceStore.converter
        const gridSize = this.props.preferenceStore.getGridSize(maxRadius)
        return (
            <Map ref="map" center={position} zoom={this.state.zoom} maxZoom={20} crs={CRS.Simple} zoomControl={false}>
            <WaterlinkedLogoControl position="topright"/>
            <ZoomControl position="topleft"/>
            <GridSizeView
                position="bottomleft"
                value={formatDecimals(converter.toUPLongFromMetric(gridSize))}
                unit={converter.nameLong()}
            />
            <Marker position={xyToLeflet(0, 0)} icon={origoIcon} zIndexOffset={-1000} ><Popup><span>Origin location</span></Popup></Marker>

            {rxFormStore.count > 0 && (
                rxFormStore.all.map((d, idx) => (
                    <ReceiverDraggable
                        form={d.form}
                        onFieldChange={d.onFieldChange}
                        onMove={this.onReceiverMove}
                        key={d.id}
                        id={d.id}
                        x={clamp(d.getAbsoluteMetric("x"), -100, 100)}
                        y={clamp(d.getAbsoluteMetric("y"), -100, 100)}
                        unitName={d.unitName}
                    />))
            )}
            <CircleGrid range={maxRadius} gridSize={gridSize}/>
            <CircularSearchAreaDraggable
                store={rangeFormStore}
                onFieldChange={rangeFormStore.onFieldChange}
                onAbsoluteMetricChange={rangeFormStore.onAbsoluteMetricFieldChange}
                maxRadius={maxRadius}
            />
            {(debug && rxFormStore.count > 0) && <ConvexHullPoly points={this.props.rxFormStore.all.map(d => d.getFlattenedAbsoluteMetricValues())}/>}
            <ReactResizeDetector handleWidth skipOnMount onResize={this.invalidateSize}/>
            </Map>
        )
    }
}))

export { ReceiverConfigurationMap };
