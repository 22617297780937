import React, { Component  } from 'react';
import { Segment, Header, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { FormInput, Configurator, FormSetupError, FormIsLoading, remap } from '../Stores/FormStores/FormStore'
import { ConfigStore } from '../Stores/FormStores/Settings'
import { MapPositionSelector } from '../Components/Map/MapPositionSelector'
import ApplyFeedbackButton from '../Components/ApplyFeedbackButton'
import { ExpandableSettings } from '../Components/ExpandableSettings'
import { FactoryResetButton } from '../Components/FactoryResetButton';
import { ensureStoreHasLoaded, TimeoutError } from '../Stores/ensure'
import './Settings.css'


const SetCurrentStaticPositionButton = inject("masterPositionStore")(observer(class SetCurrentStaticPositionButton extends Component {
    setPos = (lat, lng) => {
       console.log("Storing position", lat, lng)
       this.props.form.fields.static_lat.value = lat
       this.props.form.fields.static_lon.value = lng
   }
    render() {
        const lat = this.props.form.fields.static_lat.value
        const lng = this.props.form.fields.static_lon.value
        return  (
            <MapPositionSelector onStorePosition={this.setPos} lat={lat} lng={lng}/>
        )
    }
}))


const ConfigurationForm = observer(class ConfigurationForm extends Component {
    render() {
        const {form, onChange} = this.props;
        return (
            <form onSubmit={this.submit}>
            <Segment>
            <p>Adjust settings below, then click on the button to store</p>
            <ApplyFeedbackButton
                type="submit"
                onClick={() => {}} // Not used
                disabled={!form.meta.isValid || this.props.isStoring || this.props.storeSuccess}
                isStoring={this.props.isStoring}
                storeSuccess={this.props.storeSuccess}
                storeFailure={form.meta.error ? form.meta.error : ""}
                />
            </Segment>

            <Segment>
            <Header as="h2" content="Locator setup"/>

            <Configurator heading="Locator type"
                     name="locator_type"
                     help="Select the Locator type that is connected to the kit"
                     value={form.fields.locator_type.value}
                     error={form.fields.locator_type.error}
                     options={form.fields.locator_type.options.slice()}
                     onChange={onChange}/>

            {form.fields.locator_type.needsExternalDepth && (
                <Message>
                <Message.Header>External input needed</Message.Header>
                    <p>
                    The Locator you have selected does not provide depth measurements, so you will need to provide depth data to the system in order for the positioning to work correctly.
                    </p>
                    <p>
                    External depth data will need to be provided via the HTTP interface described in <a href="/swagger" target="new">API Specification</a>
                    </p>
                </Message>
            )}

            <Configurator heading="Channel"
                name="channel"
                help="Select channel to use. When multiple Underwater GPS kits are used in close proximity select different channels to avoid interference.
                When using Locator-S1, Locator-U1 or Locator-P1 the Locator must also be configured to use the selected channel."
                value={form.fields.channel.value}
                error={form.fields.channel.error}
                options={form.fields.channel.options.slice()}
                onChange={onChange}
            />
            {/*
            Removed until we need it!
            <Configurator heading="Environment"
                    name="environment"
                    help="Select 'reflective' for pools or tanks where walls give a lot of acoustic reflections, otherwise select 'open water'"
                    value={form.fields.environment.value}
                    error={form.fields.environment.error}
                    options={form.fields.environment.options}
                    onChange={onChange}
            />
            */}
            {process.env.REACT_APP_UGPS_FEATURES_ENABLED ? (
                <div>
                    <Configurator heading="Speed of sound"
                    name="speed_of_sound"
                    help="Configure the speed of sound in meter pr second matching the the environment you are operating in. If you are unsure what to use specify the default of 1475"
                    value={form.fields.speed_of_sound.value}
                    error={form.fields.speed_of_sound.error}
                    options={form.fields.speed_of_sound.options.slice()}
                    onChange={onChange}/>

                {form.fields.speed_of_sound.value !== "1475" && (
                    <FormInput type="number"
                        label={{ content: 'm/s', basic: true }}
                        labelPosition= 'right'
                        step="1"
                        className="spacing"
                        name="speed_of_sound"
                        help="Speed of sound in water(m/s)"
                        value={form.fields.speed_of_sound.value}
                        error={form.fields.speed_of_sound.error}
                        onChange={onChange}/>
                )}
                </div>
            ):(
                <Configurator heading="Speed of sound"
                    name="speed_of_sound"
                    help="Speed of sound used by the system in meter pr second. UGPS G2 R300 supports configurable speed of sound."
                    value={form.fields.speed_of_sound.value}
                    error={form.fields.speed_of_sound.error}
                    options={[remap("1475", "1475 m/s")]}
                    onChange={onChange}/>
            )}


            <AdvancedLocatorSettings form={form} onChange={onChange}/>
            </Segment>

            <Segment>
            <Header as="h2" content="Topside setup"/>
            <Configurator heading="Topside position"
                     name="gps"
                     help="Configure the position source. Global position of locator is calculated from the relative acoustic position, the topside housing position and compass heading. If the topside housing remains globally stationary, static configuration will give the best accuracy."
                     value={form.fields.gps.value}
                     error={form.fields.gps.error}
                     options={form.fields.gps.options.slice()}
                     onChange={onChange}/>

            {form.fields.gps.value === "static" && (
            <div>
                <FormInput type="number"
                    name="static_lat"
                    label={{ content: 'Latitude', basic: true }}
                    labelPosition= 'right'
                    step="any"
                    help="Static Latitude"
                    value={form.fields.static_lat.value}
                    error={form.fields.static_lat.error}
                    onChange={onChange}/>

                <FormInput type="number"
                    className="spacing"
                    name="static_lon"
                    label={{ content: 'Longitude', basic: true }}
                    labelPosition= 'right'
                    step="any"
                    help="Static longitude"
                    value={form.fields.static_lon.value}
                    error={form.fields.static_lon.error}
                    onChange={onChange}/>

                <SetCurrentStaticPositionButton form={form} onChange={onChange}/>
            </div>
            )}

            {form.fields.gps.value === "external" && (
                <Message>
                <Message.Header>External input needed</Message.Header>
                <p>
                External compass data will need to be provided via the HTTP interface described in <a href="/swagger" target="new">API Specification</a>
                </p>
                </Message>
            )}

            <Configurator heading="Topside heading"
                    name="compass"
                    help="Select compass source. If the topside housing remains globally stationary, using a static orientation gives best accuracy."
                    value={form.fields.compass.value}
                    error={form.fields.compass.error}
                    options={form.fields.compass.options.slice()}
                    onChange={onChange}/>

            {form.fields.compass.value === "static" && (
                <FormInput type="number"
                    label={{ content: 'Degrees', basic: true }}
                    labelPosition= 'right'
                    step="0.1"
                    className="spacing"
                    name="static_orientation"
                    help="Static compass orientation (degrees)"
                    value={form.fields.static_orientation.value}
                    error={form.fields.static_orientation.error}
                    onChange={onChange}/>
            )}

            {form.fields.compass.value === "external" && (
                <Message>
                <Message.Header>External input needed</Message.Header>
                <p>
                External compass data will need to be provided via the HTTP interface described in <a href="/swagger" target="new">API Specification</a>
                </p>
                </Message>
            )}

            {form.fields.compass.value === "onboard" && (
                <div style={{paddingBottom: "0.5em"}}>
                    <Link to="/imu">Onboard IMU calibration</Link>
                </div>
            )}
            <AdvancedTopSideSettings form={form} onChange={onChange}/>
            </Segment>
        </form>
      )
    }
    submit = (event) => {
      event.preventDefault();
      this.props.onSubmit()
    }
  })


let configStore = new ConfigStore()

const GenericConfigurationForm = inject("genericConfigStore")(observer(class GenericConfigurationForm extends Component {
    state = {
        isStoring: false,
        storeSuccess: false,
        isSettingUp: true,
        setupError: false,
    }
    reset() {
        let gcs = this.props.genericConfigStore
        let form = configStore.form

        // Set locator in a way that changes the channels available
        configStore.onFieldChangeWrapper('locator_type', gcs.locator_type)
        // Then set channel
        form.fields.channel.value = String(gcs.channel)
        form.fields.gps.value = gcs.gps
        form.fields.static_lat.value = gcs.static_lat
        form.fields.static_lon.value = gcs.static_lon
        form.fields.speed_of_sound.value = gcs.speed_of_sound
        form.fields.compass.value = gcs.compass
        form.fields.static_orientation.value = gcs.static_orientation
        form.fields.vehicle_imu.value = gcs.vehicle_imu
        form.fields.external_pps.value = gcs.external_pps
        form.fields.environment.value = gcs.environment
        form.fields.antenna.value = gcs.antenna
    }
    componentWillReceiveProps() {
        console.log("Reset config form: Props")
        this.reset()
    }
    componentDidMount() {
        console.log("Reset config form: Mount")
        ensureStoreHasLoaded(this.props.genericConfigStore)
        .then(() => {
            console.log("Store loaded, resetting")
            this.reset()
            this.setState({isSettingUp: false})
        }).catch((err) => {
            this.setState({isSettingup: false, setupError: "Error getting values"})
            if (err instanceof TimeoutError) {
                console.log("Timeout while waiting for store", err)
            } else {
                throw err
            }
        })
    }
    render() {
        if (this.state.isSettingUp) {
            return (<FormIsLoading/>)
        }
        if (this.state.setupError) {
            return (<FormSetupError header="Woops!">Error getting form values</FormSetupError>)
        }
        return (
            <ConfigurationForm form={configStore.form} onChange={configStore.onFieldChangeWrapper} onSubmit={this.onSubmitForm} storeSuccess={this.state.storeSuccess} isStoring={this.state.isStoring}/>
        )
    }
    onSubmitForm = () => {
        this.setState({storeSuccess: false, isStoring: true})
        let values = configStore.getFlattenedValues()
        console.log('submitted', configStore.getFlattenedValues())
        let gcs = this.props.genericConfigStore

        gcs.channel = parseInt(values.channel, 10)
        gcs.gps = values.gps
        gcs.static_lat = parseFloat(values.static_lat)
        gcs.static_lon = parseFloat(values.static_lon)
        gcs.speed_of_sound = values.speed_of_sound
        gcs.compass = values.compass
        gcs.static_orientation = parseFloat(values.static_orientation)
        gcs.locator_type = values.locator_type
        gcs.vehicle_imu = values.vehicle_imu
        gcs.external_pps = values.external_pps
        gcs.environment = values.environment
        gcs.antenna = values.antenna

        configStore.setError(null)
        gcs.store().then(() => {
            this.setState({storeSuccess: true, isStoring: false})
            setTimeout(() => {
                this.setState({storeSuccess: false})
            }, 1000)
        }).catch((e) => {
            this.setState({storeSuccess: false, isStoring: false})
            configStore.setError(e.toString())
        })
    }
}))


const UserPreferenceForm = inject("preferenceStore")(observer(class UserPreferenceForm extends Component {
    onChange = (key, val) => {
        const preference = this.props.preferenceStore
        console.log("Change user preference", key, val)
        if (key === "metric") {
            preference.setUnit(val)
        }
        if (key === "showBoat") {
            preference.setBoatVisibility(val === "show")
        }
        if (key === "showLatLng") {
            preference.setLatLngVisible(val === "show")
        }

    }
    render() {
        const preference = this.props.preferenceStore
        return (
            <Segment style={{margin: "1rem 0"}}>
            <Header as="h2" content="User preferences"/>

            <p>User preferences are stored in your web browser and are automatically applied.</p>
            <Configurator heading="Units"
                    name="metric"
                    help="Select metric or imperial units"
                    value={preference.unit}
                    error={null}
                    options={[remap("metric", "Metric"), remap("imperial", "Imperial")]}
                    onChange={this.onChange}/>

            <Configurator heading="Reference boat outline in map"
                    name="showBoat"
                    help="Show reference boat outline. Reference boat is 23 feet / 7.2 meter"
                    value={preference.boatVisible ? "show" : "hide"}
                    error={null}
                    options={[remap("show", "Visible"), remap("hide", "Hidden")]}
                    onChange={this.onChange}/>

            <Configurator heading="Latitude/longitude of Locator in map view"
                    name="showLatLng"
                    help="Show latitude/longitude in map view"
                    value={preference.latLngVisible ? "show" : "hide"}
                    error={null}
                    options={[remap("show", "Visible"), remap("hide", "Hidden")]}
                    onChange={this.onChange}/>

            </Segment>
        )
    }
}))

const AdvancedLocatorSettings = observer(class AdvancedLocatorSettings extends Component {
    render() {
        const { form, onChange } = this.props
        /* Hide advanced settings if one of the feature are enable */
        if (!form.fields.vehicle_imu.hasFeature) {
            return null;
        }
        return (
            <ExpandableSettings>
                {form.fields.vehicle_imu.hasFeature &&
                    <Configurator heading="Vehicle IMU assist"
                        name="vehicle_imu"
                        help="Enable/disable input of vehicle forces from intertial measurement unit (IMU) to improve positioning of Locator using an on-board sensor fusion algorithm"
                        value={form.fields.vehicle_imu.value}
                        error={form.fields.vehicle_imu.error}
                        options={form.fields.vehicle_imu.options}
                        onChange={onChange}/>
                }
                {form.fields.vehicle_imu.hasFeature && form.fields.vehicle_imu.value === "enabled" && (
                    <Message>
                    <Message.Header>External input needed</Message.Header>
                    <p>
                    Vehicle IMU data will need to be provided via the HTTP interface described in <a href="/swagger" target="new">API Specification</a>
                    </p>
                    </Message>
                )}
            </ExpandableSettings>
        )
    }
})

const AdvancedTopSideSettings = observer(class AdvancedTopSideSettings extends Component {
    render() {
        const { form, onChange } = this.props
        return (
            <ExpandableSettings>
                {form.fields.external_pps.hasFeature &&
                    <Configurator heading="External PPS"
                        name="external_pps"
                        help="Enable/disable external PPS input to master on GPIO pin 1"
                        value={form.fields.external_pps.value}
                        error={form.fields.external_pps.error}
                        options={form.fields.external_pps.options}
                        onChange={onChange}/>
                }
                <div>
                    <Link to="/network">Ethernet configuration</Link>
                </div>

                <div>
                    <Link to="/wifi">WiFi configuration</Link>
                </div>

                <div>
                <FactoryResetButton/> Click icon to reset all settings to factory defaults.
                </div>

            </ExpandableSettings>
        )
    }
})

class Settings extends Component {
    render() {
        return (
            <Segment.Group>
            <GenericConfigurationForm/>
            <UserPreferenceForm/>
            </Segment.Group>
        )
    }
}

export default Settings;
