import { extendObservable } from 'mobx'
import { GenericFormStore } from './FormStore'
import { rules } from '../../lib/validator'

class NetworkConfigFormStore extends GenericFormStore {
    constructor() {
        super()
        extendObservable(this, {
            form: {
                fields: {
                    address: {
                        value: "10.11.12.95",
                        error: null,
                        rule:[rules.string()],
                    },
                    prefix: {
                        value: 24,
                        error: null,
                        rule: [rules.between(1, 31)],
                    },
                    gateway: {
                        value: "10.11.12.1",
                        error: null,
                        rule: [rules.string()],
                    },
                    dns: {
                        value: "10.11.12.1",
                        error: null,
                        rule: [rules.string()],
                    },
                    dhcp: {
                        value: "true",
                        error: null,
                        rule: [rules.string()],
                    },
                },
                meta: {
                  isValid: true,
                  error: null,
                },
            },
            error: "",
        })
    }
}

export { NetworkConfigFormStore }
