import { extendObservable, action, computed } from 'mobx'
import { persist, create } from 'mobx-persist'

class UnitConverter {
    nameShort = () => {
        return this.shortName
    }
    // to User Preferred Short distance unit
    toUPShortFromMetric = (value) => {
        return value * this.shortFactor
    }
    // to Metric from User Preferred Short
    toMetricFromUPShort = (value) => {
        return value / this.shortFactor
    }
    nameLong = () => {
        return this.longName
    }
    // to User Preferred Long distance unit
    toUPLongFromMetric = (value) => {
        return value * this.longFactor
    }
    // to Metric from User Preferred Long
    toMetricFromUPLong = (value) => {
        return value / this.longFactor
    }
    isMetric = true
    // Default grid increments
    gridIncrements = [20, 10, 5, 2, 1, 0.5]
    gridSize = (longestSide) => {
        let selected = this.gridIncrements[0]
        this.gridIncrements.forEach(increment => {
            if ((longestSide / increment) <= 20) {
                selected = increment
                return true
            }
        });
        return selected
    }
    // to User preferred temperature unit
    metricToUPTemperature = (value) => {
        return value;
    }
}

class MetricUnitConverter extends UnitConverter {
    constructor() {
        super()
        this.name = "metric"
        this.shortFactor = 1
        this.shortName = "meters"
        this.longFactor = 1
        this.longName = "meters"
        this.temperatureSymbol = "C"
        this.isMetric = true
    }
}

class ImperialUnitConverter extends UnitConverter {
    constructor() {
        super()
        this.name = "imperial"
        this.shortFactor = 39.3700787
        this.shortName = "inches"
        this.longFactor = 3.2808399
        this.longName = "feet"
        this.temperatureSymbol = "F"
        this.isMetric = false
        // Grid increments in feet
        this.gridIncrements = [100, 40, 20, 10, 5, 3].map(d => this.toMetricFromUPLong(d))
    }

    metricToUPTemperature = (value) => {
        return value * 9/5 + 32
    }
}


class UserPreferenceStore {
    constructor() {
        extendObservable(this, {
            unit: "metric",
            boatVisible: true,
            latLngVisible: false,
            setUnit: action((unit) => {
                if (unit === 'imperial') {
                    this.unit = 'imperial'
                    return
                }
                this.unit = 'metric'
            }),
            setBoatVisibility: action((visible) => {
                this.boatVisible = visible
            }),
            converter: computed(() => {
                if (this.unit === "imperial") {
                    return new ImperialUnitConverter()
                }
                return new MetricUnitConverter()
            }),
            getGridSize: (longestSize) => {
                return this.converter.gridSize(longestSize)
            },
            setLatLngVisible: action((visible) => {
                this.latLngVisible = visible
            })
        })
    }
}

const schema = {
    unit: true,
    boatVisible: true,
    latLngVisible: true,
}

function newPreferenceStore(storage) {
    const store = persist(schema)(new UserPreferenceStore())

    const hydrate = create({storage: storage})
    hydrate('preferences', store)
    /*
        .then(() => {
            console.log('Loaded preferences')
        })
    */
    return store
}


export { newPreferenceStore };
