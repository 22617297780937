import React from 'react';
import { Segment, Divider } from 'semantic-ui-react'
import { IMUOrientation, ResetGyros, ResetNorth } from '../Components/IMUConfig'


export default function IMU() {
    return (
        <Segment.Group>
            <Segment>
                <Divider horizontal className="divider-spacing">Inertial Measurement Unit</Divider>
            </Segment>

            <Segment>
            <ResetGyros/>
            </Segment>

            <Segment style={{margin: "1rem 0"}}>
            <ResetNorth/>
            </Segment>


            <Segment style={{margin: "1rem 0"}}>
            <IMUOrientation/>
            </Segment>

        </Segment.Group>
    )
}