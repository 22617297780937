import { icon, divIcon } from 'leaflet'

var markerIconBlue = divIcon({
    iconAnchor: [10, 20],
    className: 'markerIcone',
    html: '<i class="marker large icon" style="color: #005a9e;"/>',
})
var markerIconPurple = divIcon({
    iconAnchor: [10, 20],
    className: 'markerIcone',
    html: '<i class="marker large icon" style="color: purple;"/>',
})
var markerIconRed = divIcon({
    iconAnchor: [10, 20],
    className: 'markerIcone',
    html: '<i class="marker large icon" style="color: red;"/>',
})
var markerIconBlack = divIcon({
    iconAnchor: [10, 20],
    className: 'markerIcone',
    html: '<i class="marker large icon" style="color: black;"/>',
})

var origoIcon = icon({
    iconUrl: '/origin.svg',
    iconSize: [50, 50],
    iconAnchor: [25, 48],
})
var rovIcon = icon({
    iconUrl: '/location-arrow-solid-32x32.png',
    iconSize: [32,32],
    iconAnchor: [16, 16],
})
var receiverIcon = divIcon({
    className: 'receiverIcon',
    html: '<i class="map pin icon"/>',
//    html: '<i class="marker icon"/>',
})
var unknownPositionIcon = divIcon({
    className: 'unknownPositionIcon',
    html: '<i class="question large red icon"/>',
    iconSize: [22,18],
    iconAnchor: [22/2, 15],
})

function GetIcon(chName) {
    if (chName === "filtered") {
        return markerIconBlue
    }
    else if (chName === "fusion") {
        return markerIconPurple
    }
    else if (chName === "raw") {
        return markerIconRed
    }
    else {
        console.log("Warning! Not known channel name!")
        return markerIconBlack
    }
}

const colors = {
    "filtered" : "#005a9e",
    "fusion" : "purple",
    "raw" : "red",
}

function GetColor(chName) {
    return colors[chName] || "black"
}

export { GetIcon, GetColor, origoIcon, rovIcon, receiverIcon, unknownPositionIcon }
