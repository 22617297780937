
function clamp(value, min, max) {
    // Clamp value to be within min/max
    if (isNaN(value)) { return 0 }
    if (value > max) { return max }
    if (value < min) { return min }
    return value
}

function wrap360(angle) {
    if (angle < 0) {
        angle += 360
    }
    angle = angle % 360
    return angle
}

export { clamp, wrap360 }
