import React, { Component } from 'react';
import '../../../node_modules/leaflet/dist/leaflet.css'
import '../../../node_modules/react-leaflet-fullscreen/dist/styles.css'
import { Icon, CRS } from 'leaflet'
import { Map, TileLayer, Marker, Popup, LayersControl, ScaleControl, LayerGroup } from 'react-leaflet'
import FullscreenControl from 'react-leaflet-fullscreen';
import { observer, inject } from 'mobx-react'
import { PropTypes } from 'prop-types';
import { extendObservable, action, computed } from 'mobx'
import ReactResizeDetector from 'react-resize-detector';
import { xyToLeflet } from './geohelpers';
import { AcousticPositionControl, AcousticCenterOnLocationControl,
    LocatorCenterOnLocationControl, AcousticCompassControl, GPSIMUBatteryTemperatureControl, GridSizeControl,
    LatitudeLongitudeControl, AcousticAvailablePositionsControl, GlobalAvailablePositionsControl } from './MapControls'
import { AcousticBoatView, MapBoatView } from './BoatView'
import { CircularRangeVisualization, AcousticCircularRangeVisualization } from './MapRange'
import { LocatorMarkerAndTrail, MasterMarkerAndTrail, AcousticLocatorMarkerAndTrail, OrigoMarker, receiverIcon } from './Markers'
import { POIMarkers } from './POIMarkers'

const { BaseLayer, Overlay } = LayersControl

Icon.Default.prototype.options["imagePath"] = "/"

const BoatVisibleWrapper = inject("preferenceStore")(observer(class BoatVisibleWrapper extends Component {
    render() {
        if (this.props.preferenceStore.boatVisible) {
            return (this.props.children)
        }
        return null
    }
}))

BoatVisibleWrapper.propTypes = {
    //boatVisible: PropTypes.bool.isRequired,
}

const MasterBoatView = inject("masterPositionStore")(observer(({ masterPositionStore, color, ...props} ) => (
    <MapBoatView
        position={[masterPositionStore.lat, masterPositionStore.lng]}
        heading={masterPositionStore.orientation}
        color={color}
        {...props}
    />
)))

MasterBoatView.propTypes = {
    position: PropTypes.arrayOf(PropTypes.number),
    //orientation: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
}

class CurrentMapPositionStore {
    constructor(defaultZoom=2) {
        extendObservable(this, {
            zoom: defaultZoom,
            lat: 0,
            lng: 0,
            setLatLngZoom: action((lat, lng, zoom) => {
                if (lat === undefined || lng === undefined || zoom === undefined) {
                    return // don't crash leaflet
                }
                this.lat = lat
                this.lng = lng
                this.zoom = zoom
            }),
            setLatLng: action((lat, lng) => {
                if (lat === undefined || lng === undefined) {
                    return // don't crash leaflet
                }
                this.lat = lat
                this.lng = lng
            }),
            position: computed(() => {
                return [this.lat, this.lng]
            })
        })
    }
}


let globalMapPositionStore = new CurrentMapPositionStore(16)

function latLngIsZero(ll) {
    return (ll[0] < 0.0001) && (ll[1] < 0.0001)
}

latLngIsZero.propTypes = {
    ll: PropTypes.arrayOf(PropTypes.number),
}

function latLngIsNotZero(ll) {
    return (ll[0] > 0.0001) && (ll[1] > 0.0001)
}

latLngIsNotZero.propTypes = {
    ll: PropTypes.arrayOf(PropTypes.number),
}


const MapView = inject("globalPositionStore")(observer(class MapView extends Component {
    onViewportChanged = (vp) => {
        globalMapPositionStore.setLatLngZoom(vp.center[0], vp.center[1], vp.zoom)
    }
    setPosition = (({lat, lng}) => {
        globalMapPositionStore.setLatLng(lat, lng)
    })
    invalidateSize = () => {
        if (this.refs.map) {
            this.refs.map.leafletElement.invalidateSize()
        }
    }
    render() {
        const center = globalMapPositionStore.position
        const zoom = globalMapPositionStore.zoom
        const locatorPosition = [this.props.globalPositionStore.lat, this.props.globalPositionStore.lng]

        if (latLngIsZero(center) && latLngIsNotZero(locatorPosition)) {
            // Set initial center once it's been loaded
            globalMapPositionStore.setLatLngZoom(locatorPosition[0], locatorPosition[1], 16)
        }

        return (
            <Map ref="map" center={center} zoom={zoom} maxZoom={22} onViewportChanged={this.onViewportChanged}>
                <FullscreenControl position="topleft"
                // forcePseudoFullscreen="true"
                />
                <AcousticPositionControl position="bottomleft"/>
                <GridSizeControl position="bottomleft"/>
                <ScaleControl/>
                <LayersControl position="topright">
                <BaseLayer checked name="OpenStreetMap">
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxZoom={24}
                        maxNativeZoom={18}
                    />
                </BaseLayer>

                <BaseLayer name="Carto Light">
                    <TileLayer
                        attribution="&amp;copy <a href=&quot;http://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a>, &copy;<a href=&quot;https://carto.com/attribution&quot;>CARTO</a>"
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png"
                        maxZoom={24}
                        maxNativeZoom={18}
                    />
                </BaseLayer>

                <BaseLayer name="No map">
                    <TileLayer url=""
                    />
                </BaseLayer>


                <Overlay checked name="OpenSeaMap">
                    <TileLayer
                        attribution="OpenSeamap"
                        url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
                    />
                </Overlay>
                </LayersControl>
                <LatitudeLongitudeControl position="bottomright"/>
                <GPSIMUBatteryTemperatureControl position="bottomright"/>
                <LocatorCenterOnLocationControl position="bottomright" onClick={this.setPosition}/>
                <GlobalAvailablePositionsControl position="bottomright" />
                <CircularRangeVisualization color="green"/>
                <BoatVisibleWrapper>
                    <MasterBoatView color="gray"/>
                </BoatVisibleWrapper>
                <MasterMarkerAndTrail color="#0A915B" trailLength={this.props.trailLength}>Master electronic position</MasterMarkerAndTrail>
                <LocatorMarkerAndTrail color="#005a9e" trailLength={this.props.trailLength}>Locator position</LocatorMarkerAndTrail>
                <ReactResizeDetector handleWidth skipOnMount onResize={this.invalidateSize}/>
                <POIMarkers/>
            </Map>
        )
    }
}))

const RecieverVisualization = inject("rxStore")(observer(class RecieverVisualization extends Component {
    render() {
        return (
            <LayerGroup>
                {this.props.rxStore.all.map(d => (
                    <Marker key={d.id} icon={receiverIcon} position={xyToLeflet(d.x, d.y)}><Popup><span>Receiver {d.id+1}</span></Popup></Marker>
                ))}
            </LayerGroup>
        )
    }
}))

let acousticMapPositionStore = new CurrentMapPositionStore(2)

const AcousticView = observer(class AcousticView extends Component {
    onViewportChanged = (vp) => {
        acousticMapPositionStore.setLatLngZoom(vp.center[0], vp.center[1], vp.zoom)
    }
    setPosition = (({lat, lng}) => {
        acousticMapPositionStore.setLatLng(lat, lng)
    })
    invalidateSize = () => {
        if (this.refs.map) {
            this.refs.map.leafletElement.invalidateSize()
        }
    }
    render() {
        const { position, zoom } = acousticMapPositionStore
        return (
            <Map ref="map" center={position} zoom={zoom} maxZoom={8} crs={CRS.Simple} onViewportChanged={this.onViewportChanged}>
            <FullscreenControl position="topleft"/>
            <AcousticPositionControl position="bottomleft"/>
            <GridSizeControl position="bottomleft"/>
            <OrigoMarker position={xyToLeflet(0, 0)} description="Master position location"/>
            <RecieverVisualization/>
            <AcousticCompassControl position="topright"/>
            <AcousticCircularRangeVisualization color="green"/>
            <AcousticLocatorMarkerAndTrail color="#005a9e" trailLength={this.props.trailLength}>Locator position</AcousticLocatorMarkerAndTrail>
            <BoatVisibleWrapper>
                <AcousticBoatView color="gray"/>
            </BoatVisibleWrapper>
            <AcousticAvailablePositionsControl position="bottomright" />
            <AcousticCenterOnLocationControl position="bottomright" onClick={this.setPosition}/>
            <ReactResizeDetector handleWidth skipOnMount onResize={this.invalidateSize}/>
            </Map>
        )
    }
})


export { MapView, AcousticView, MasterBoatView, BoatVisibleWrapper, CurrentMapPositionStore, RecieverVisualization }
