import React, { Component } from 'react';
import { Segment, Form, Table, Message } from 'semantic-ui-react'
import { observer, inject } from 'mobx-react'
import { FormSetupError, FormIsLoading, SimpleFormInput, Configurator, remap } from '../Stores/FormStores/FormStore'
import ApplyFeedbackButton from '../Components/ApplyFeedbackButton'
import { storeErrorTimeoutAPMode, storeErrorTimeoutClientMode } from '../Stores/WifiConfig';


const WifiConfigFormView = observer(({form, onChange}) => (
    <Form>
        <Form.Group widths='equal'>
        <SimpleFormInput
            fluid
            label='Access point name (SSID)'
            value={form.fields.ssid.value}
            error={form.fields.ssid.error}
            name='ssid'
            onChange={onChange}
        />
        <SimpleFormInput
            fluid
            label='Password'
            value={form.fields.password.value}
            error={form.fields.password.error}
            type='password'
            name='password'
            onChange={onChange}
        />
        </Form.Group>
    </Form>
))

const AccessPointDetails = () => (
    <Table basic="very" collapsing>
    <Table.Body>
        <Table.Row>
            <Table.Cell>Access point name</Table.Cell>
            <Table.Cell><strong>{"UnderwaterGPS"}</strong></Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>Access point password</Table.Cell>
            <Table.Cell><strong>{"waterlinked"}</strong></Table.Cell>
        </Table.Row>
        <Table.Row>
            <Table.Cell>URL for web GUI</Table.Cell>
            <Table.Cell><strong>{"http://192.168.7.1"}</strong></Table.Cell>
        </Table.Row>
    </Table.Body>
    </Table>
)

function TimeoutErrorClientMode() {
    return (
        <>
        Request timed out.
        Connection to the backend is lost.
        <br/>
        <br/>
        If you were connected to the device as a client of the 'UnderwaterGPS'
        WiFi network, this error likely indicates that the device correctly
        disabled the 'UnderwaterGPS' network in favor of attempting to connect
        to the provided network:
        <ul>
            <li>
                <b>If connection to the provided network fails</b>, the device
                should revert back to Access Point mode, and the 'UnderwaterGPS'
                network should reappear.
            </li>
            <li>
                <b>If connection to the provided network is successful</b>, the
                device will remain connected as a client to the configured
                network, disabling 'UnderwaterGPS' until it is reactivated
                through the ethernet interface.
            </li>
        </ul>
        <br/>
        The error could also be due to an unexpected problem. Ensure
        you are connected to the device. Consider rebooting. As a
        last resort, reconfigure WiFi through the ethernet
        interface.
        </>
    )
}

function TimeoutErrorAPMode() {
    return (
        <>
        Request timed out.
        Connection to the backend is lost.
        <br/>
        <br/>
        Ensure you are connected to the device. Consider
        rebooting. As a last resort, reconfigure WiFi through
        the ethernet interface.
        </>
    )
}


const WifiConfig = inject("wifiConfigStore")(observer(class WifiForm extends Component {
    componentDidMount() {
        this.props.wifiConfigStore.fetch()
    }
    onChange = (key, val) => {
        this.props.wifiConfigStore.reset(); // Reset check mark on apply button
        this.props.wifiConfigStore.formStore.form.fields.mode.value = val
    }

    render() {
        const wifiConfigStore = this.props.wifiConfigStore;
        if (wifiConfigStore.isLoading) {
            return (
                <FormIsLoading/>
            )
        }
        if (!wifiConfigStore.loadSuccess) {
            return (
                <FormSetupError header="Woops!">Error getting WiFi configuration.</FormSetupError>
            )
        }
        let form = wifiConfigStore.formStore.form;
        const onChangeStore = wifiConfigStore.formStore.onFieldChange;
        return (
            <div>
                <Configurator heading="WiFi configuration"
                    name="mode"
                    help="Click to select if you want the WiFi to be in Access point mode or Client mode."
                    value={form.fields.mode.value}
                    error={form.fields.mode.error}
                    options={[remap("ap", "WiFi Access point"), remap("client", "WiFi Client")]}
                    onChange={this.onChange}/>

                <div style={{paddingTop: "0.9em", paddingBottom: "0.9em"}}>
                {form.fields.mode.value === "client" ? (
                    <div>
                        <WifiConfigFormView form={form} onChange={onChangeStore}/>
                        <Message info>
                            <p>
                                Note:
                                The device can act either as an Access Point
                                or a Client, not both at the same time.
                                Therefore, if you are connected to the
                                'UnderwaterGPS' WiFi, you will be
                                disconnected when pressing 'Apply'.
                                Without a connection to the device, the
                                success or failure of the action cannot be
                                determined.
                            </p>
                        </Message>
                    </div>
                ) : (
                    <AccessPointDetails/>
                )}
                </div>
                <ApplyFeedbackButton
                    type="submit"
                    onClick={() => {wifiConfigStore.store()}}
                    disabled={!form.meta.isValid || wifiConfigStore.isStoring}
                    isStoring={wifiConfigStore.isStoring}
                    storeSuccess={wifiConfigStore.storeSuccess}
                    storeFailure={
                        wifiConfigStore.storeSuccess ? "" : (
                            // for some storeErrors we want to show a special
                            // component. Otherwise, just show the storeError as-is
                            wifiConfigStore.storeError === storeErrorTimeoutClientMode ? (
                                // timeout error while trying to set client mode. this will
                                // always happen when connected to AP mode and switching to
                                // client mode successfully. It may also happen if we fail
                                // to connect to the provided network, and the customer's
                                // computer doesn't connect to the AP when it is brought up
                                // again, quickly enough. Show helpful message for these
                                // (expected) cases, that also mentions that something else
                                // could have gone wrong..
                                <TimeoutErrorClientMode/>
                            ) : wifiConfigStore.storeError === storeErrorTimeoutAPMode ? (
                                // timeout error while trying to set AP mode. This is unexpected
                                <TimeoutErrorAPMode/>
                            ) : (
                                // storeFailure is not one of the two special ones. Show as string
                                wifiConfigStore.storeError
                            )
                        )
                    }
                />
            </div>
        )
    }
}))

class Wifi extends Component {
    render() {
        return (
            <Segment>
                <WifiConfig/>
            </Segment>
        )
    }
}

export default Wifi;
