import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Polygon } from 'react-leaflet'
import { xyToLeflet, convertXYToPolar, offsetLatLonByDistanceAngle } from './geohelpers'

function generateBoat(length, width) {
    // Generate boat shape
    //    4
    //
    // 5     3
    //
    // 1     2
    let points = [
        [0, 0],
        [0, width],
        [3*length/4, width],
        [length, width/2],
        [3*length/4, 0],
    ]
    // Center points before returning
    return points.map(d => [d[0] - length / 2, width / 2 - d[1]])
}

generateBoat.propTypes = {
    length: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
}

function referenceBoat() {
    // Buster Magnum is the only boat you will ever need!
    return generateBoat(7.20, 2.57)
}

class AcousticBoatView extends Component {
    transform(poly) {
        const xypoly = poly.map(d => xyToLeflet(d[0], d[1]))
        return xypoly
    }
    render() {
        const color = this.props.color || "gray"
        const polys = referenceBoat()
        return ( <Polygon positions={this.transform(polys)} color={color}/> )
    }
}

AcousticBoatView.propTypes = {
    color: PropTypes.string,
}

class MapBoatView extends Component {
    transform(poly) {
        const { position, heading } = this.props
        const center = {lat: position[0], lng: position[1]}
        let latlngpoly = poly.map(d => {
            let polar = convertXYToPolar(d)
            return offsetLatLonByDistanceAngle(center, polar.angle + heading, polar.distance)
        })
        return latlngpoly
    }
    render() {
        const color = this.props.color || "gray"
        const polys = referenceBoat()
        return ( <Polygon positions={this.transform(polys)} color={color}/> )
    }
}

MapBoatView.propTypes = {
    heading: PropTypes.number.isRequired,
    position: PropTypes.array.isRequired,
    color: PropTypes.string,
}


export { AcousticBoatView, MapBoatView }
